import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import { ButtonHTMLAttributes, ChangeEvent, ChangeEventHandler, HTMLAttributes, ReactElement } from 'react';


import './LY_ButtonPinkHover.css';


interface LY_ButtonPinkHoverProps   {
  isActive?: boolean;
  children?: any; // This line allows the component to accept children

  className?: string;
};

/* export const LY_ButtonPinkHover = React.forwardRef<HTMLDivElement, LY_ButtonPinkHoverProps & React.HTMLAttributes<HTMLDivElement>>(
  ({
    className,
    ...props 
  },
   ref) => { */

    export const LY_ButtonPinkHover = React.forwardRef<HTMLDivElement, LY_ButtonPinkHoverProps & React.HTMLAttributes<HTMLDivElement>>((
      {
        className,
        ...props 
      },
      ref 
    ) => {
    

    function render(){

      var activeClass = '';
      if (props.isActive)
        activeClass = 'LY_ButtonPinkHover_active';


        const { isActive: _, ...restProps } = props;


      return <div
      ref={ref}
      className={'LY_ButtonPinkHover '+activeClass+(className || '')}
      {...restProps}
    >{props.children}</div>
    }

    return render();
  
 

  });
