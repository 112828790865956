import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { Modal, Loader } from '@mantine/core';

interface LoadingProps {
    show?: boolean;
    isModal?: boolean;
    style?: React.CSSProperties;
    disablePortal?: boolean;
}

export class Loading extends React.Component<LoadingProps, {}> {

    public getModalLoading() {
        return (
            <LY.LY_Modal
                opened={true}
                onClose={() => {}}
                withCloseButton={false}
                centered
                withinPortal={true}
                style={{
         
                    zIndex: 9999, 
                    width:50
                }}
                classNames={{
                    content: 'LY_LoadingContent',
                    root: 'LY_LoadingRoot',
                    overlay: 'LY_LoadingOverlay',
                    body: 'LY_LoadingBody',
                }}
                styles={{
                    content: {
                        width: 50,
                        backgroundColor: 'transparent',
                        overflow: 'hidden',
                        boxShadow:'none'
                    },
                    body: {
                        padding:0,
                    },
                }}
            >

                <Loader size="lg"
             
                color="var(--ly-global-primary-color2)"  
                 />
            </LY.LY_Modal>
        );
    }

    public render() {
        if (this.props.show === undefined || this.props.show === true) {
            if (this.props.isModal)
                return this.getModalLoading();
            else
                return (
                    <div>
                        <Loader style={this.props.style} />
                    </div>
                );
        }

        return null;
    }
}