import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import MenuIcon from '@mui/icons-material/Menu';
//import { withRouter } from "react-router-dom";
import { IconButton, Dialog , Button, DialogActions, Divider,DialogContent ,DialogContentText ,DialogTitle  } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Alert from '@mui/material/Alert';
import { CSSProperties } from "react";


import { createStyles,  Theme } from '@mui/material/styles';

 

 
interface CreateListModalProps {
  vm: App.WorkspaceVm;
 }

export class CreateListModal extends React.Component<CreateListModalProps, {}> {

  public adminDataService: App.AdminDataService;
  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.adminDataService = new App.AdminDataService();
    this.validationManager = new App.ValidationManager();
 
  }

  

    // This method is called when the route parameters change
    public componentDidUpdate() {

      //console.log('CreateListModal componentDidUpdate');
    
    }
  

  // This method is called when the route parameters change
  public componentDidMount() {
    console.log('CreateListModal componentDidMount');
    //var vm = this.props.globalUIState;
 
    //var adminUserState = this.props;
    //var globalUIState = this.props.globalUIState;

   // var currentUser = App.AppBase.currentUser;
    
  }

    
  public onCreateClick(e: React.ChangeEvent<{}>) {
    console.log('onCreateClick');
    
    var vm = this.props.vm;
    var item = vm.newItem;

    if(!item.parentId)
      item.parentId = vm.selectedItem?.workspaceItemId;
 
    var isValid = this.validationManager.validateWorkspaceNewItem(null, item);

    if (!isValid) {
      console.log('onCreateClick NOT valid');
      this.forceUpdate();
      return;
    } 
  
    App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_NEW_REQUEST, vm);
 
  
  }
  


  public onCloseClick() {
 
   var vm =  this.props.vm;
   vm.isNewListModalOpen=false;
   this.forceUpdate();
       
}
 
  
  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    const item = this.props.vm.newItem;
    var target: any = e.target;
 
    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    //console.log('onInputChange key', key);
    //console.log('onInputChange value2', target.value);

    if (LIB.Common.isNullOrEmpty(key))
      return;

   this.validationManager.validateWorkspaceNewItem(key, item);

    console.log('onInputChange item', item);

    this.forceUpdate();
  }


  public getForm(){
   // console.log('CreateListModal getForm');
    var state = this.props;

     var vm = this.props.vm;
 
    var item = vm.newItem;

    //console.log('CreateListModal getForm newItem', item);

    var textFieldStyle = {paddingTop: 20,marginBottom:10};

    
  var fullWidth: CSSProperties = {
    // paddingRight: 20,
    paddingLeft: 0,
    //  marginBottom: 10,
    //  padding: 10,
    width: '100%'
  };
  var formInput: CSSProperties = {
    height: 36,
    //  marginTop: 10,
    //paddingTop: 20,
    padding: 10,
  };

    return <App.MainContainer
    //todo state={vm}
    hasDataLoaded={vm.hasWorkspacesDataLoaded}
    loading={(vm.isActionInProgress)}
    errorMessage={vm.apiErrorMessage}
    showContentWhenInProgress={true}
    showModalForLoading={true}
    className='LY_MainContainerAutoHeight'

    >
      <form  style={{ maxWidth: 700, display:'flex', gap:'1rem', flexDirection:'column', minWidth:300 }}   >

      <div>
        Folder: {vm.newItem?.parentId || 'Root'}
      </div> 

      <div style={{marginTop:10}}>
      <UI.TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  value={item.name || ''}
                  {...App.AppBase.getInputErrorProps('name')}
                  onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

       />
      </div>

     
      
        <div >
{/* 
          <App.FormAutocomplete label="Filter Users By A Role" name="userRoleType" data={state.userRoleTypes}
             onChange={this.onAutocompleteChange.bind(this)}
             labelProperty="name"
             valueProperty="userRoleTypeId"
             textFieldStyle={textFieldStyle}
          /> */}

        </div>

        

        </form>


 
</App.MainContainer>;


  }
 
  public render() {
     console.log('NewItem CreateListModal render');

    var vm = this.props.vm;
    var isOpen = vm.isNewListModalOpen;
    var state = this.props;

    if(!isOpen)
        return null;

   
      return (
        
          <Dialog
            open={isOpen}
            onClose={()=>this.onCloseClick()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Create a New List</DialogTitle>
            <DialogContent>

             
            {this.getForm()}

            </DialogContent>
            <DialogActions>
           
            <MT.Button 
            variant="contained"
            //color="default"
            style={{ margin: 10 }}
            onClick={this.onCloseClick.bind(this)}
          >Cancel</MT.Button>
  
        <MT.Button 
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            onClick={this.onCreateClick.bind(this)}
            disabled={vm.isActionInProgress}
          >Create</MT.Button>

 
            </DialogActions>
          </Dialog>
 

      );
     


  }
}

