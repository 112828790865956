import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

export class LY_RecordUpdateModuleState extends LIB.BaseVmModel {

  public rowUpdates:Map<string, any> = new Map<string, any>();
 // public row:Map<string, any> = new Map<string, any>();
  public row:any;

  public view?: Api.ListView = new Api.ListView();
  public newViewSm: Api.ListViewSm = new Api.ListViewSm();
 
  public workspaceId?:number | undefined;
  public linkedWorkspaceId?:number | undefined;

  public isContainerModalOpen:boolean = false;


  constructor() {
    super();
  }
}
