import React, { ChangeEvent, ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';

import './LY_TextArea.css';
import { Textarea } from '@mantine/core';

interface LY_TextAreaProps extends LY.LY_InputBaseProps {
  name: string;
  //type: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  //  onChange?: (model: LY.DataItemModel | undefined, event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | undefined) => void;

  style?: React.CSSProperties;
  inputProps?: React.InputHTMLAttributes<HTMLTextAreaElement>;
  maxRows?: number;
  onChange?: (model: LY.DataItemModel | undefined, event?: ChangeEvent<any> | undefined) => void;
}

//export const LY_TextAreaBase: React.FC<LY.LY_TextAreaBaseProps> = ({
//export const LY_TextInput = forwardRef<LY_TextInputHandle, LY_TextInputProps>((

type combinedProps = LY_TextAreaProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element - LY_TextArea
export const LY_TextArea = React.forwardRef<HTMLInputElement, LY_TextAreaProps>((
  {
    name,
    // type,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    style,
    onChange,
    model,
    readOnly,
    maxRows = 5,
    inputProps,
    ...inputBaseProps
  },
  ref // This is the forwarded ref
) => {

  var combinedInputClassName = ` LY_TextAreaInputContainer`;

  if (inputBaseProps.error)
    combinedInputClassName += ' LY_TextAreaInput_error'
  else
    combinedInputClassName += ' LY_TextAreaInput_focus'

  if (inputClassName)
    combinedInputClassName += ' ' + inputClassName;


  var readOnlyValue = model?.value;
  if (!readOnlyValue && value)
    readOnlyValue = value;


  function render() {
 
    return (
      <LY.LY_InputBase  {...inputBaseProps}  >

        {readOnly && (
          <div title={readOnlyValue} className='LY_TextArea_ReadOnly LY_OverflowDots'>{readOnlyValue}</div>
        )}

        {!readOnly && (
          <Textarea
            name={name}
            id={inputProps?.id}
            className={combinedInputClassName}
            placeholder={placeholder}
            classNames={{ input: 'LY_TextAreaInput' }}
            //  {...inputProps}
            value={value || ''}
            onChange={(e) => {
              if (model)
                model.value = e.target.value;

              //  console.log('LY_TextAreaInput onChange currentTarget.value:',e.currentTarget.value);
              //  console.log('LY_TextAreaInput onChange target.value:',e.target.value);

              if (onChange)
                onChange(model, e)
            }
            }
            // onChange={(e) => onChange && onChange(model, e)}
            autosize={true}
            maxRows={maxRows}
          >
            {/* {value} */}
          </Textarea>
        )}


      </LY.LY_InputBase >
    );

  }

  return render();
});


