import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_DLA_LabelMakerModule_Container.css";
import { Tabs } from '@mantine/core';


import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
import { useFocusTrap } from '@mantine/hooks';
import { LY_DLA_LabelMakerModule_Form } from '../LY_DLA_LabelMakerModule_Form/LY_DLA_LabelMakerModule_Form';
import { LY_DLA_LabelMakerModule_Label } from '../LY_DLA_LabelMakerModule_Label/LY_DLA_LabelMakerModule_Label';
import { LY_DLA_LabelMakerModule_Preview } from '../LY_DLA_LabelMakerModule_Preview/LY_DLA_LabelMakerModule_Preview';


export const LY_DLA_LabelMakerModule_Container: React.FC<LY.LY_DLA_LabelMakerModuleProps> = (props) => {


  const context = LY.useDLALabelMakerModuleContext();
  const { state, services } = context;
  var item = state.model;


  function onClose() {
    console.log('LY_DLA_LabelMakerModule_Container onClose', props.onClose);

    context.onCancelOrCloseReset();

    if (props.onClose)
      props.onClose();

  }

  function getButtonsRow(){

    return <div className='LY_DLA_LabelMakerModule_Container_Buttons'>
        <LY.LY_ButtonCancel
          onClick={() => {
            context.onCancelOrCloseReset();
          }}
        >
          Cancel
        </LY.LY_ButtonCancel>

        <LY.LY_Button
          loading={state.isActionInProgress}
          loaderProps={{ type: 'dots' }}
          onClick={() => {
            // context.updateViews();
          }}
          disabled={true}
          title='Coming Soon...'
        >
          Generate PDF
        </LY.LY_Button>

    </div>
  }

  function getTabs() {


    return <Tabs
      className='LY_DLA_LabelMakerModule_ContainerTabs'
      value={state.selectedMainContainerTab}
      onChange={(tab) => {
        console.log('LY_DLA_LabelMakerModule_Container tab changed', tab);
        state.selectedMainContainerTab = tab;
        context.forceUpdate();
      }}
    >
      <Tabs.List>
        <Tabs.Tab value="label_maker">Label Maker</Tabs.Tab>
        <Tabs.Tab value="preview">Preview</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="label_maker"
        className='LY_DLA_LabelMakerModule_ContainerTabPanel'
      >
        <div className="LY_DLA_LabelMakerModule_Container">

          <LY_DLA_LabelMakerModule_Label {...props} />
          <LY_DLA_LabelMakerModule_Form {...props} />
        </div>

      </Tabs.Panel>

      <Tabs.Panel value="preview"
        className='LY_DLA_LabelMakerModule_ContainerTabPanel'
      >

        <div className="LY_DLA_LabelMakerModule_Container">
         

          <LY_DLA_LabelMakerModule_Preview {...props} />

        </div>
      </Tabs.Panel>
    </Tabs>


  }



  function render() {
    console.log('LY_DLA_LabelMakerModule_Container render state', state);
    //console.log('LY_DLA_LabelMakerModule_Container render state.isContainerModalOpen', state.isContainerModalOpen);

    const isMobile = useIsMobile();

    var container = <div className='LY_DLA_LabelMakerModule_ContainerBase'>
      {getTabs()}
      {getButtonsRow()}
    </div>


    if (!props.isModal)
      return container;


    return (
      <LY.LY_Modal
        title='DLA Label Maker'
        size="xxl"
        padding="md"
        onClose={onClose}
        opened={state.isContainerModalOpen}
        className='LY_DLA_LabelMakerModule_ContainerModal'
        closeOnClickOutside={false}
        zIndex={8000}
      >

        {container}
      </LY.LY_Modal>
    );



  }




  return render();
};
