import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class ListViewService extends App.BaseAppService {


    private linkedListViewService: App.LinkedListViewService | undefined = undefined

    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
        this.linkedListViewService = new App.LinkedListViewService(workspaceId);
    }

    public getControllerName():string {

        return this.hasLinkedWorkspace() ? 'LinkedListView' : 'ListView';
    }

    public async getListViews(sm: Api.ListViewGetDataSm, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if(!sm || sm.listId==undefined){
            console.log('ListViewService.getRecords sm.listId is required', sm);
            return;
        }
/* 
        if (sm.linkedWorkspaceId && sm.linkedWorkspaceId > 0 && this.linkedListViewService) {
            return this.linkedListViewService.getListViews(sm, onSuccess, onError);
        } */
        if (sm.linkedWorkspaceId && sm.linkedWorkspaceId > 0  ) {
         this.linkedWorkspaceId = sm.linkedWorkspaceId;
        }

        console.log("ListViewService.getListViews sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListView/getListViews?listId=${sm.listId}`;

            await this.executeApi({
                apiUrl: apiUrl,
               // data: sm,
                onSuccess: onSuccess?.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("ListViewService.getListViews onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'GET'
            });
         


    }

   
 

    public async createView(sm: Api.ListViewSm, onSuccess: Function, onError: Function | undefined = undefined) {

 
        console.log("ListViewService.createView sm: ", sm);
 
         
        const apiUrl = `${App.AppBase.appApiBaseUrl}/${this.getControllerName()}/createView`;
 
            await this.executeApi({
                apiUrl: apiUrl,
                data: sm,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("ListViewService.createView onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'POST'
            });
        


    }

    public async updateViewSortOrders(views:Api.ListViewSm[], onSuccess: Function, onError: Function | undefined = undefined) {

 
        console.log("ListViewService.updateViewSortOrders views: ", views);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/${this.getControllerName()}/updateViewSortOrders`;

            await this.executeApi({
                apiUrl: apiUrl,
                data: views,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("ListViewService.updateViewSortOrders onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'POST'
            });
        


    }
    
    public async updateViews(views:Api.ListViewSm[], onSuccess: Function, onError: Function | undefined = undefined) {

 
        console.log("ListViewService.updateViews views: ", views);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/${this.getControllerName()}/updateViews`;

            await this.executeApi({
                apiUrl: apiUrl,
                data: views,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("ListViewService.updateViews onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'POST'
            });
        


    }
    

    public async updateView(sm: Api.ListViewSm, onSuccess: Function, onError: Function | undefined = undefined) {

 
        console.log("ListViewService.updateValues sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/${this.getControllerName()}/updateView`;

            await this.executeApi({
                apiUrl: apiUrl,
                data: sm,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("ListViewService.updateValues onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'patch'
            });
        


    }

    public async deleteView(listViewId:number, onSuccess: Function, onError: Function | undefined = undefined) {

 
        console.log("ListViewService.deleteView listViewId: ", listViewId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/${this.getControllerName()}/deleteView?listViewId=${listViewId}`;

            await this.executeApi({
                apiUrl: apiUrl,
                //data: sm,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("ListViewService.deleteItems onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'delete'
            });
        


    }


}
