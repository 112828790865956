import * as Api from "app-api";
import * as LIB from "_LIB";
import * as React from "react";

import { createContext, useContext, useState } from "react";

import { FiltersManager } from "../Manager/LY_ListFilterModuleManager";
import { LY_ListFilterModuleProps } from "../Interfaces/LY_ListFilterModuleProps";
import { LY_ListFilterModuleState } from "./LY_ListFilterModuleState";
import { ValidateManager } from "../Manager/LY_ListFilterValidateManager";

export interface LY_ListFilterModuleContextProps {
  state: LY_ListFilterModuleState;

  onAddNewSort: () => void;
  onDeleteSort: (index: number) => void;
  onChangeSort: (sort: Api.SortOrderBy, index: number) => void;
  onAddNewGroup?: () => void;
  onChangeJoinOperator: (value: Api.FilterConditionJoinOperatorEnum, conditionSetIndex?: number) => void;
  onAddNewAdvancedFilterRow: (groupIndex?: number) => void;
  onDelete: (rowIndex: number, groupIndex?: number) => void;
  onClear: () => void;
  setSearch: (value: string) => void;
  onColumnsHideAll: () => void;
  onRemoveAllColumnsSearch: () => void;
  onColumnsShowAll: () => void;
  onSelectAllColumnsSearch: () => void;
  onSelectColumns: (value: string) => void;
  onSelectSearchColumns: (value: string) => void;
  setInitialView: () => void,
  onSearch: () => void;
  forceUpdate: () => void;
  updateView: () => void;
  onSaveAsNewViewSuccess: (onClose: Function) => void;
  setDropdownOpen: (val: boolean) => void;
  validateManager: ValidateManager;

}

const useListFilterModuleState = (props: LY_ListFilterModuleProps): LY_ListFilterModuleContextProps => {



  //const [ view, setView ] = useState<Api.ListView>(new Api.ListView);


  const initialState = new LY_ListFilterModuleState();

  const [state, setState] = useState<LY_ListFilterModuleState>(initialState);
  const [propsState, setPropsState] = useState<LY_ListFilterModuleState>(new LY_ListFilterModuleState());

  var view = state.view;
 

  if (view?.filter == undefined)
    view.filter = new Api.QueryFilter();

  var filter = view?.filter;
  const filtersManager = new FiltersManager(view, props, state, forceUpdate);
  const validateManager = new ValidateManager(state, forceUpdate);

  React.useEffect(() => {


    state.view = props.view!;
    state.columns = props.columns?.filter((x: Api.ListColumn) => x.isSystemHidden == false);
    setState({ ...state });

    var stateCopy = clone(state);
    setPropsState(stateCopy);

  }, [props.view])

  function setDropdownOpen(val: boolean = false) {
    setState((prevState) => ({
      ...prevState,
      isDropdownOpen: val
    }))
  }
  function clone(stateIn: LY_ListFilterModuleState): LY_ListFilterModuleState {

    var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_ListFilterModuleState;
    stateCopy.errors = new Map<string, any>();
    return stateCopy;
  }


  function setInitialView() {

    // console.log('setInitialView orderByColumns 1:', propsState.view?.filter?.orderByColumns);

    var state = clone(propsState);
    setState(state);

  }


  function setView(view: Api.ListView | any) {

    state.view = view;

    forceUpdate();

  }

  function onSelectColumns(value: string) {

    if (!filter?.selectColumns)
      filter.selectColumns = [];

    if (!filter.selectColumns.includes(value))
      filter.selectColumns.push(value);
    else
      filter.selectColumns = filter.selectColumns.filter((x: string) => x !== value);

    forceUpdate();
    //console.log('LY_ListFilterModuleContext onSelectColumns filter.selectColumns:', filter.selectColumns);


    if (props.onColumnSelectionChange)
      props.onColumnSelectionChange(view);
  };




  function onSelectSearchColumns(
    value: string,
  ) {
    if (filter?.searchColumns?.includes(value)) {
      setView((vv: any) => {
        const col = filter?.searchColumns?.filter(
          (column) => column !== value
        );
        return {
          ...vv,
          filter: {
            ...filter,
            searchColumns: col,
          },
        };
      });
    } else {
      const col = [
        ...((view?.filter?.searchColumns
          ? view?.filter?.searchColumns
          : []) as string[]),
        value,
      ];
      setView({
        ...view,
        filter: {
          ...filter,
          searchColumns: col,
        },
      });
    }
  };

  function forceUpdate() {

    if(filter)
      state.view.filter = { ...state.view.filter  };

    setState({ ...state })
  }


  function onAddNewSort() {

    var newSort = new Api.SortOrderBy();

    if (!filter.orderByColumns)
      filter.orderByColumns = [];

    filter.orderByColumns.push(newSort);

    forceUpdate();

  }

  function onDeleteSort(index: number) {

    if (!filter?.orderByColumns)
      return;

    filter?.orderByColumns.splice(index, 1);

    console.log('onDeleteSort filter.orderByColumns:', filter?.orderByColumns);


    view.filter = { ...filter };


    forceUpdate();

  };

  function onChangeSort(sort: Api.SortOrderBy, index: number) {

    console.log('onChangeSort sort/index:', sort, index);

    if (filter == undefined || filter.orderByColumns!.length < index)
      return;

    filter.orderByColumns![index] = sort;

    console.log('onChangeSort orderByColumns:', filter.orderByColumns);

    forceUpdate();

  };



  function onColumnsHideAll() {

    if (!filter)
      return;

    filter.selectColumns = ['id', 'name'];
    forceUpdate();


    if (props.onColumnSelectionChange)
      props.onColumnSelectionChange(view);

  };



  function onRemoveAllColumnsSearch() {

    filter.searchColumns = [''];
    forceUpdate();

  };

  //console.log(filter)

  function onSelectAllColumnsSearch() {


    const cols = state?.columns?.map((x: any) => x.name);
    filter.searchColumns = cols;

    console.log('onSelectAllColumnsSearch cols:', cols);

    forceUpdate();

  };



  function onColumnsShowAll() {

    const cols = state?.columns?.map((x: any) => x.name);
    filter.selectColumns = cols;
    setView(view);

    if (props.onColumnSelectionChange)
      props.onColumnSelectionChange(view);

  };



  function onAddNewGroup() {
    var conditionSets = new Api.FilterConditionSet();
    var condition = new Api.FilterCondition();
    condition.name = "";
    condition.value = "";
    condition.op = Api.FilterConditionOperatorEnum.Equal;
    conditionSets.joinOperator = Api.FilterConditionJoinOperatorEnum.AND;
    conditionSets.conditions = [condition];
    filter.conditionSets = [...(filter?.conditionSets ? filter?.conditionSets : []), conditionSets];
    forceUpdate();
  };

  function onAddNewAdvancedFilterRow(groupIndex?: number) {
    var conditionSet = new Api.FilterConditionSet();
    conditionSet.joinOperator = Api.FilterConditionJoinOperatorEnum.AND;
    var condition = new Api.FilterCondition();
    condition.name = "";
    condition.value = "";
    condition.op = Api.FilterConditionOperatorEnum.Equal;
    conditionSet.conditions = [condition];
    var currentState = LIB.ObjectHelper.clone(view);
    if (groupIndex !== undefined) {
      currentState.filter?.conditionSets?.[groupIndex].conditions.push(
        condition
      );
    } else if (filter?.conditionSet?.conditions) {
      currentState.filter.conditionSet.conditions.push(condition);
    }
    setView(currentState);
    forceUpdate();
  };


  function onChangeJoinOperator(
    value: Api.FilterConditionJoinOperatorEnum,
    conditionSetIndex?: number
  ) {
    if (conditionSetIndex === undefined && value &&  view?.filter?.conditionSet){ // && view?.filter?.conditionSetsJoinOperator) {
    //  view.filter.conditionSetsJoinOperator = value;
      view.filter.conditionSet.joinOperator = value;
    }
    if (conditionSetIndex !== undefined && value && view?.filter?.conditionSets) {
      view.filter.conditionSets[conditionSetIndex].joinOperator = value;
    }
    forceUpdate();
  };

  function onClear() {
    const newView = LIB.ObjectHelper.clone(view);
    newView.filter.conditionSet.conditions = [];
    newView.filter.conditionSets = [];
    newView.filter.conditionSetsJoinOperator = Api.FilterConditionJoinOperatorEnum.AND;
    setView(newView);
  };

  function onDelete(rowIndex: number, groupIndex?: number) {
    var currentState = LIB.ObjectHelper.clone(view);
    if (groupIndex !== undefined && rowIndex !== undefined) {
      currentState.filter?.conditionSets?.[groupIndex].conditions.splice(rowIndex, 1);
      if (
        currentState?.filter?.conditionSets &&
        !currentState.filter?.conditionSets?.[groupIndex].conditions.length
      ) {
        currentState?.filter?.conditionSets.splice(groupIndex, 1);
      }
      setView(currentState);
    } else if (rowIndex !== undefined) {
      currentState.filter?.conditionSet?.conditions.splice(rowIndex, 1);
      setView(currentState);
    }
  };



  function setSearch(value: string) {


    filter.searchKeyword = value;
    forceUpdate();

  };

  function onSearch() {

    if (props.onApply)
      props.onApply(view)
  }


  function updateView() {


    filtersManager.updateView(view);

    if (props.onSaveViewSuccess)
      props.onSaveViewSuccess(view)

  }
  function onSaveAsNewViewSuccess(onClose: Function) {

    filtersManager.createView(state.newViewSm, setView, onClose);


  }
  return {
    state,
    forceUpdate,
    onSearch,
    updateView,
    onSaveAsNewViewSuccess,
    onAddNewAdvancedFilterRow,
    onAddNewGroup,
    onChangeJoinOperator,
    onClear,
    onDelete,
    onColumnsHideAll,
    onRemoveAllColumnsSearch,
    onColumnsShowAll,
    onSelectAllColumnsSearch,
    onSelectSearchColumns,
    onSelectColumns,
    setSearch,
    setInitialView,
    onAddNewSort,
    onDeleteSort,
    onChangeSort,
    setDropdownOpen,
    validateManager

  };
};

const LY_ListFilterModuleContext = createContext<LY_ListFilterModuleContextProps | undefined>(undefined);

export const useListFilterModuleContext = () => {


  const context = useContext(LY_ListFilterModuleContext);

  if (!context) {
    throw new Error(
      "useLY_ListFilterModuleContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};


const LY_ListFilterModuleContextProvider: React.FC<LY_ListFilterModuleProps> = (props) => {


  const contextValue = useListFilterModuleState(props);

  function render() {

    //console.log('LY_ListFilterModuleContextProvider render:', contextValue.state);
    // console.log('LY_ListFilterModuleContextProvider render view:', contextValue.state?.view);
    // console.log('LY_ListFilterModuleContextProvider render view.filter:', contextValue.state?.view?.filter);

    return (
      <LY_ListFilterModuleContext.Provider value={contextValue}>
        {props.children}
      </LY_ListFilterModuleContext.Provider>
    );
  }

  return render();
};
export { LY_ListFilterModuleContextProvider, LY_ListFilterModuleContext };
