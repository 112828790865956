import React, { ButtonHTMLAttributes, ChangeEvent, ChangeEventHandler, HTMLAttributes, ReactElement } from 'react';

import * as LY from '_LY_Components';

import './LY_Modal.css';
import * as MT from '@mantine/core';
import * as LIB from '_LIB';

interface LY_ModalProps   {
  // ... additional props
  children?: any; // This line allows the component to accept children

};


export const LY_Modal: React.FC<LY_ModalProps & MT.ModalProps > = ((
  {
 
    ...props

  }) => { 


  return <MT.Modal
 
  centered
  overlayProps={{
    backgroundOpacity: 0.1,
    blur: 0,
  }}
  transitionProps={{ duration: 0, timingFunction: 'linear' }}

    {...props}
  >{props.children}
  </MT.Modal>
 

  });
