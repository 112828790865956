import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_BulkEmailSenderModule_Form.css";

 
import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
 
export const LY_BulkEmailSenderModule_Form: React.FC<LY.LY_BulkEmailSenderModuleProps> = (props) => {

  const context = LY.useBulkEmailSenderModuleContext();
  const { state, services} = context;
  var item = state.selectedItem;

  const { isMobile } = useIsMobile(768);

  const refEmailContentDiv = React.useRef<HTMLDivElement>(null);
  

  React.useEffect(() => {
 
    console.log('LY_BulkEmailSenderModule_Form useEffect refEmailContentDiv.current:', refEmailContentDiv.current);

    state.refEmailContentDiv = refEmailContentDiv.current;


    context.forceUpdate();

  }, [refEmailContentDiv.current]);
 
 

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all

    state.errors.clear();
 
    if (vAll || key == 'name') {
      if (LIB.Common.isNullOrEmpty(item.name))
      state.errors.set('name', `View Name is required!`);
      else if (item.name.length > 50)
      state.errors.set('name', `View Name must be less than 50 characters!`);
    } 

    var isValid = (state.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }

  function onSaveClick(e: React.FormEvent<any>) {
    e.preventDefault();
    var isValid = validate('all');
    if (!isValid) {
      context.forceUpdate();
      return false;
    }

   // services.createView();
 
  }
 

 
  function render(){


    console.log('LY_BulkEmailSenderModule_Form state.selectedItem');

    var internalNameDefault = props?.record?.name || item?.name;

    if(internalNameDefault)
       internalNameDefault += ' - ' + LIB.DateHelper.getFormattedDateWithAMPM();
 
    var subject=`Quote Request For NSN: ${props?.record?.nsn}, PN: ${item?.part_number} - ( Request ID: ${(item?.record?.quote_id || '')} )`;

    var content = item.body;
    console.log('LY_BulkEmailSenderModule_Form state.selectedItemReportFirstRow',state.selectedItemReportFirstRow);

    console.log('LY_BulkEmailSenderModule_Form content',content);


    return <div
    className='LY_BulkEmailSenderModule_Form_Container'
    >
      <div><b>{state.selectedItemName}</b></div>
      <div className='LY_Flex_Row'>
      <LY.LY_CheckBox
            name="is_bidding"
            checked={item.is_bidding}
            label="Is Bidding"
            labelPlacement='left'
            tooltip={`${state.selectedItemName} is possibly bidding on this NSN.`}
            disabled={true}
          />

<LY.LY_CheckBox
            name="is_approved_source_bidding"
            checked={item.is_approved_source_bidding}
            label="Approved Source Is Bidding"
            labelPlacement='left'
            tooltip={`Checked if ANY of the approved sources are bidding on this NSN. `}
            disabled={true}
          />
        </div>
      <LY.LY_Input
            name="internalName"
            value={internalNameDefault}
            type='text'
            label="Internal Campaign Name"
            placeholder="Internal Campaign Name"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('rfq')}
           /*  onChange={(m, e) => {
              onInputChange(e!);
            }} */
          />
    <div><span className='LY_InputBaseLabel ' style={{marginRight:5}}>To Emails: </span>
     {state.selectedItemToEmails?.join('; ')}  
    </div>
    <LY.LY_Input
            name="subject"
            value={subject}
            type='text'
            label="Subject"
            placeholder="Subject"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('rfq')}
           /*  onChange={(m, e) => {
              onInputChange(e!);
            }} */
          />

     {/* <div> */}
 
{/*       <div className='LY_Scrollbar LY_BulkEmailSenderModule_Form_Content'>
        <div
         ref={refEmailContentDiv}
        className='LY_BulkEmailSenderModule_Form_Content_Inner'
       
        dangerouslySetInnerHTML={{__html: content}} />
      </div>   
       */}
 
       <LY.LY_SlashEditor 
         editorRef={refEmailContentDiv}
         classNames={{editor: 'LY_Scrollbar LY_BulkEmailSenderModule_Form_Content'}}
         htmlValue={content}
         onChange={(val) => {
         console.log('LY_BulkEmailSenderModule_Form onChange value:', val.html);

          var html = val.html?.replace(/<p><\/p>/g, '<p>&nbsp;</p>');
          state.selectedItem.body = html;

          console.log('LY_BulkEmailSenderModule_Form onChange state.selectedItem.body:',  state.selectedItem.body);

          context.forceUpdate();
        }}  
   
      /> 
 

    </div>
  }

  return render();
 
};
