import * as LIB from '_LIB';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export class StringHelper {



    public static generateUUID(): string {
        var result = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
        return result;
    }

    public static addHtmlLineBreaksToString(val: string) {
        //  console.log('addHtmlLineBreaksToString val',val);



        //val = val?.replace(/\r?\n|\r/g,'<br/>');


        val = StringHelper.replaceAll(val, '\n', '<br/>');

        //  console.log('addHtmlLineBreaksToString val',val);

        return val;

    }

    public static getQueryStringParam(key: string): any {

        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get(key);
        return myParam;
    }

    public static removeMultipleSpacesAndNewLines(str: string): string {

        if (LIB.Common.isNullOrEmpty(str))
            return str;

        str = str.replace(/\r?\n|\r/g, ' ');
        str = str.replace(/ +(?= )/g, '');

        return str?.trim();

    }

    public static maxLength(str: string, maxLen: number, endText: string = '...'): string {

        if (LIB.Common.isNullOrEmpty(str))
            return str;

        str = str?.trim();
        if (str.length > maxLen)
            str = str.substring(0, maxLen) + endText;

        return str;

    }

    public static getBetween(str: string, start: string, end: string): string {

        try {

            var startIndex = str.indexOf(start);
            // console.log('onDetailResult getBetween start',start );
            //console.log('onDetailResult getBetween startIndex',startIndex );

            var endIndex = str.indexOf(end, startIndex + 1 + start.length);
            var skip = startIndex + start.length;

            // console.log('onDetailResult getBetween end',end );
            // console.log('onDetailResult getBetween skip',skip );

            //console.log('onDetailResult getBetween endIndex',endIndex );


            if (startIndex > -1 && endIndex > -1 && skip <= endIndex) {
                var result = str.substring(skip, endIndex);
                // console.log('onDetailResult getBetween result',result );

                return result;
            }

        }
        catch {

            return "";
        }

        return "";
    }

    public static replaceIgnoreCase(str: string, strReplace: string, strWith: string) {

        var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        var reg = new RegExp(esc, 'ig');
        return str.replace(reg, strWith);
    };


    public static replaceAll(str: string, keyword: string, replaceWith: string): string {
        // console.log('replaceAll', str,keyword,replaceWith);
        if (LIB.Common.isNullOrEmpty(str))
            return str;

        // str = str.replace(`${keyword}/g`,replaceWith); 
        // str = str.toString().rep(`${keyword}/g`,replaceWith); 
        str = str.split(keyword).join(replaceWith);

        //   console.log('replaceAll after:', str);

        return str;
    }


    public static getCleanValueForInputType(value: any, type: string): string {
        // console.log('getCleanValueForInputType value:', value );
        //console.log('getCleanValueForInputType type:', type );

        var result = '';
        if (LIB.Common.isNullOrEmpty(value))
            return '';

        if (type === 'date') {
            result = moment(value).format('YYYY-MM-DD');
            //console.log('getCleanValueForInputType value:', value );
            //console.log('getCleanValueForInputType result:', result );

        }
        else if (type === 'datetime-local') {
            return moment(value).format('YYYY-MM-DDTHH:mm');
        }
        else if (type === 'time') {
            return value;//moment(value).format('HH:mm');
        }
        else if (type === 'number') {
            return value.toString();
        }
        else if (type === 'text') {
            return value.toString();
        }
        else {
            return value.toString();
        }



        return result;
    }

    public static replaceNewLinesWithBR(str: string | undefined): string {


        str = str?.replace(/(?:\r\n|\r|\n)/g, '<br>');
        str = str?.replace('+', '&#10133;');
        return str || '';

    }

    public static capitalizeFirstLetter(str: string) {
        if (!str || str.length<2) 
            return str;
     
        var result =  str.charAt(0).toUpperCase() + str.slice(1);
        return result;
    }

    
    public static addSpacesBetweenWords(value: string): string {
        return value.replace(/([A-Z])/g, ' $1').trim();
    }
}