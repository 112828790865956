
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';


export class DetailVm extends LIB.BaseVmModel {

 
    public isEditMode: boolean = false;
 
    public isDLABiddingQuotesList: boolean = false;
    public isDLASupplierQuotesList: boolean = false;

    public isDetailPopupModalOpen: boolean = false;

    public selectedDetailListId?:string;
    public selectedDetailViewId?:number;
    public selectedDetailModel : LY.DataItemModel;

    constructor() {

        super();
    }
 
       /* 
    public setSelectedItem(item: LY.DataItemModel | undefined=undefined) {

     
        if (item === undefined)
            item = new LY.DataItemModel();


        this.selectedItem = item;
        this.selectedItemOriginal = LIB.ObjectHelper.clone(this.selectedItem);

    } */

 
    
    

}