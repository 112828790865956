import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';

export class DetailState extends LIB.BaseVmModel {

  public workspace: Api.Workspace;
  public workspaceId: number=-1;
  public linkedWorkspaceId?: number;

  public vm: App.DetailVm = new App.DetailVm();
  public newItem: LY.DataItemModel = new LY.DataItemModel();
  
  public list: Api.List;
  public view: Api.ListView;

  //public columns: Api.ListColumn[]=[];
  public relationshipLists: Api.ListGetRelatedDataSm[]=[];
  public data: any; 
  public statsData: any; 

  public lookupTypes:Api.SystemLookupType[]=[];
  public dropDownData:Record<string, Array<Record<string, any>>>;


  public sm: Api.DetailGetDataSm = new Api.DetailGetDataSm();
  
  public selectedListItems:DetailState_SelectedListItems = new DetailState_SelectedListItems();
  public isBulkEmailSenderOpen:boolean = false;

  constructor() {

    super();
 
    this.newItem = new LY.DataItemModel();

  }



}
export class DetailState_SelectedListItems {

  state:DetailState;
  listState:App.ListState;
  record:any;
  selectedItems: any[];
  selectedItemsInfo: Api.ListGetRelatedDataSm;
}
