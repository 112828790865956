import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_BulkEmailSenderModule.css";


import { LY_BulkEmailSenderModuleContextProvider } from './Context/LY_BulkEmailSenderModuleContext';
import { LY_BulkEmailSenderModule_Container } from './Components/LY_BulkEmailSenderModule_Container/LY_BulkEmailSenderModule_Container';
 


export const LY_BulkEmailSenderModule: React.FC<LY.LY_BulkEmailSenderModuleProps> = (props) => {

  function render() {
  
    console.log('LY_BulkEmailSenderModule render isContainerModalOpen:', props.isContainerModalOpen);
 
    return (
      <LY_BulkEmailSenderModuleContextProvider {...props}  >
        <div className="LY_BulkEmailSenderModuleContainer">
        <LY_BulkEmailSenderModule_Container {...props} />
        </div>
      </LY_BulkEmailSenderModuleContextProvider>
    );
  }




  return render();
};
