import React, { ChangeEvent, ChangeEventHandler } from "react";
import * as LY from "_LY_Components";
import * as Icons from "@mui/icons-material/";
import * as LIB from "_LIB";
import { Tooltip, Text } from "@mantine/core";
import "./LY_TimeEstimate.css";

interface LY_TimeEstimateProps extends LY.LY_InputBaseProps {
  name: string;
  value?: number;
  //onValueChange?: (value: number) => void;
  maxDisplayLength?: number;
  width?: number;
  height?: number;
  isShowHelpPopup?: boolean;

  onValueChange?: (m?: LY.DataItemModel | undefined, e?: React.ChangeEventHandler | undefined, value?: number) => void;

}

type combinedProps = LY_TimeEstimateProps &
  React.InputHTMLAttributes<HTMLInputElement>;


export const LY_TimeEstimate: React.FC<combinedProps> = ({
  value,
  readOnly,
  maxDisplayLength,
  width = 150,
  height = 30,
  isShowHelpPopup,
  ...props
}) => {
  const [ model, setModel ] = React.useState(
    props.model || new LY.DataItemModel()
  );

  React.useEffect(() => {
    setModel(props.model || new LY.DataItemModel());
  }, [ props.model ]);

  React.useEffect(() => {

    console.log(`LY_TimeEstimate useEffect value: `, value);

    if (value !== undefined && value !== null) {

      const formattedValue = formatMinutesToTimeString(value);
      setModel((prevModel) => ({
        ...prevModel,
        value: formattedValue,
      }));
    }


  }, [ value ]);



  function convertToMinutes(input: string): number {
    const regex = /(\d+)\s*w|\s*(\d+)\s*d|\s*(\d+)\s*h|\s*(\d+)\s*m/g;
    let match;
    let weeks = 0,
      days = 0,
      hours = 0,
      minutes = 0;

    while ((match = regex.exec(input)) !== null) {
      if (match[ 1 ]) {
        weeks = parseInt(match[ 1 ], 10);
      }
      if (match[ 2 ]) {
        days = parseInt(match[ 2 ], 10);
      }
      if (match[ 3 ]) {
        hours = parseInt(match[ 3 ], 10);
      }
      if (match[ 4 ]) {
        minutes = parseInt(match[ 4 ], 10);
      }
    }

    return weeks * 5 * 8 * 60 + days * 8 * 60 + hours * 60 + minutes;
  }

  function formatMinutesToTimeString(totalMinutes: number): string {
    const totalHours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let days = Math.floor(totalHours / 8);
    let hours = totalHours % 8;

    let weeks = Math.floor(days / 5);
    days = days % 5;

    let result = "";

    if (weeks > 0) {
      result += `${weeks}w `;
    }
    if (days > 0) {
      result += `${days}d `;
    }
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m`;
    }

    return result?.trim();
  }

  function getViewMode() {
    let displayValue = model?.value || "";


    var formattedValue = formatMinutesToTimeString(Number(displayValue));

    return formattedValue;

/*     const col = model?.column;

    const columnWidth = Number(col?.columnWidth) || 300;
    maxDisplayLength = maxDisplayLength || columnWidth / 9;
    displayValue = LIB.StringHelper.maxLength(displayValue, maxDisplayLength);

    return displayValue; */
  }

  function onLocalValueChange(m: LY.DataItemModel, e: ChangeEventHandler | undefined) {


    if (model) {
      const timeInMinutes = convertToMinutes(m.value || "");
      const formattedTime = formatMinutesToTimeString(timeInMinutes);
      model.value = timeInMinutes;
      setModel({ ...model });

      //  if (props.onValueChange) 
      //     props.onValueChange(timeInMinutes);

      if (props.onValueChange)
        props.onValueChange(model, e, timeInMinutes);
    }



  }

  function render() {

    var toolTipMsg =  <div>
    Calculated for work hours:
    <div>1 week = 5 days</div>
    <div>1 day = 8 hours</div>
  </div>

    return (
      <div className="LY_TimeEstimate" style={{ width: width + 'px', height: height + 'px' }}>
        <LY._LY_InputBaseViewEdit
          {...props}
          model={model}
          viewRenderer={getViewMode()}
          onValueChange={onLocalValueChange}
          readOnly={readOnly}
          inputViewEditContainerClassName="LY_TimeEstimate_InputContainer"
          tooltip={model.value?toolTipMsg:undefined}
        />
        {isShowHelpPopup && <Tooltip
          label={toolTipMsg}
          withArrow
          position="top"
        >
          <Icons.Help className="LY_TimeEstimate_IconHelp" htmlColor="gray" />
        </Tooltip>}
      </div>
    );
  }

  return render();
};
