import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import React from "react"

interface LY_DropDown_FooterProps extends LY.LY_StatusDropDownProps {
    onSaveClick?: () => void;
}


export const LY_DropDown_Footer: React.FC<LY_DropDown_FooterProps> = (props) => {


    const context = LY.useStatusDropDownContext();
    const state = context.state;



    if (!state.isEditMode) {
        return <div className='LY_StatusDropdown_footer'>
            <div onClick={() => {
                context.changeSelectedValue(undefined);
                if (props.onChange)
                    props.onChange(state.model, undefined)
            }}> Clear
            </div>

            {!props.disableEditOptions &&
            <div
                onClick={() => {
                    context.changeEditMode(true);
                }}
            >
                Edit Options
            </div>
            }
        </div>
    }


    if (state.isEditMode) {

        return <div className='LY_StatusSelect_ButtonGroup'>
            <LY.LY_ButtonCancel
                onClick={() => {
                    context.cancelClick();
                    context.changeEditMode(false);
                }}
            >
                Cancel
            </LY.LY_ButtonCancel>

            <LY.LY_Button
                onClick={props.onSaveClick}

                loading={state.isActionInProgress}

            >
                Save
            </LY.LY_Button>

        </div>
    }

    return null
}
