import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { EditorInstance, useEditor } from "novel";

import './LY_SlashEditor_ToolBar.css';

import { LY_LinkSelector } from "../LY_LinkSelector/LY_LinkSelector";

import { LY_ColorSelector } from "../LY_ColorSelector/LY_ColorSelector";
import { useState } from "react";
import { Extensions } from '@tiptap/core';
import { defaultExtensions } from "../../lib/extensions";
import { slashCommand } from "../../lib/slash-command";
import { LY_SlashEditor_Action_Buttons } from '../LY_SlashEditor_Action_Buttons/LY_SlashEditor_Action_Buttons';

import { actionButtons, actionButtonsTextList, actionButtonsAdditional } from '../../LY_SlashEditor_Constants';
import { LY_SlashEditor_Action_List } from '../LY_SlashEditor_Action_List/LY_SlashEditor_Action_List';



export interface BubbleColorMenuItem {
  name: string;
  color: string;
}



interface LY_SlashEditor_ToolBarProps extends LY.LY_SlashEditorBaseProps {
  renderer?: (editor: EditorInstance) => JSX.Element;
  onContextMenuClick?: (val: any) => void;
  menuActiveItems?: any[];
  generateJSON: (html: string, extensions: Extensions, options?: any) => void;

}

export const LY_SlashEditor_ToolBar: React.FC<LY_SlashEditor_ToolBarProps> = (props) => {

  const { editor } = useEditor();

  const [openTextButtonsList, setOpenTextButtonsList] = useState(false);
  const [openAdditionalBtn, setOpenAdditionalBtn] = useState(false)
  const [openColor, setOpenColor] = useState(false);
  const [openLink, setOpenLink] = useState(false);

  const extensions = [...defaultExtensions, slashCommand];


  React.useEffect(() => {

   // console.log('LY_SlashEditor_ToolBar useEffect', props.htmlValue);

    if (!editor)
      return;

    var html = props.htmlValue?.replace(/  /g, '&nbsp;&nbsp;');
        html = props.htmlValue?.replace(/&nbsp; /g, '&nbsp;&nbsp;');
 
    var content: any = props.generateJSON(html || '', extensions);



    editor.commands.setContent(content);


  }, [props.htmlValue]);

  function render() {

    if (!editor) return null;

    return <>

      <div className="LY_SlashEditor_Toolbar">

        <div className="LY_SlashEditor_Toolbar_Icons">

          <LY_SlashEditor_Action_List items={actionButtonsTextList} 
          open={openTextButtonsList} onOpenChange={setOpenTextButtonsList} 
          editor={editor} icon={() => <Icons.IconBaseline size={18} 
          color={"#757B87"} />} listName={"Text"} {...props} />

          <LY_LinkSelector editor={editor} open={openLink}
            onOpenChange={(val: boolean) => {
              console.log('LY_NodeSelector setOpenLink', val);
              setOpenLink(val);
            }}  {...props} />

          <LY_SlashEditor_Action_Buttons editor={editor} items={actionButtons} {...props} />

          <LY_ColorSelector editor={editor} open={openColor}
            onOpenChange={setOpenColor} {...props} />  

          <LY_SlashEditor_Action_List 
           items={actionButtonsAdditional} 
           open={openAdditionalBtn}
           onOpenChange={setOpenAdditionalBtn} editor={editor} 
          icon={() => <Icons.IconCirclePlus size={18} color={"#757B87"} />} listName={"Other"} {...props} />
 
          {props.additionalButtonRenderer ? props.additionalButtonRenderer() : null}  

        {props.isToolBarMenuDisplayed ?
            <LY.LY_MenuButton
            buttonClassName="LY_SlashEditor_MenuButton"
            menuItems={props.menuActiveItems || []}
            onItemClick={props.onContextMenuClick}
            />
            :
            null}  

        </div>

        {props.additionalNavBtnsRenderer ? props.additionalNavBtnsRenderer() : null}

      </div >

      {props.middlePartRenderer ? props.middlePartRenderer() : null}

    </>


  }

  return render()
};
