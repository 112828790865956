import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';

import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

interface AdministrationCompaniesTableProps {
  state: App.AdministrationState;
  onChange?: Function;

}

export class AdministrationCompaniesTable extends React.PureComponent<AdministrationCompaniesTableProps> {

  public myAccountService: App.MyAccountService;

  constructor(props: any) {
    super(props);
    this.myAccountService = new App.MyAccountService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationCompaniesTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }


  public onActionMenuItemClick(e: React.MouseEvent<HTMLElement>, key: string, id: any, rowIndex: number) {

    var vm = this.props.state.vm;

    this.setSelected(id);

    //var target:any = e.target;
    //console.log(e.currentTarget); 
        if(key=="Edit"){
        vm.isCompaniesEditOpen=true;
        }
        else if(key=="Delete"){
          vm.isCompaniesDeleteOpen=true;
         
        } 

        this.forceUpdate();

        if(this.props.onChange)
          this.props.onChange();
  }

  public getActionMenuItems(id: any, tableMeta: any, updateValue: any): any[] {
    //console.log('getActionMenuItems tableMeta:', tableMeta);
    //console.log('getActionMenuItems updateValue:', updateValue);

    var state = this.props.state;
    //var vm = state.vm;
    var companies = state.companies;

    var item = companies?.find(x => x.companyId === id)
    var isReadOnly = false;

    /* 
        if(item!==undefined)
          isReadOnly = (item.workflowRecord.userPermissions.canEdit===false); */

    var result = [
      <MenuItem key="Edit"
        onClick={(e) => this.onActionMenuItemClick(e, 'Edit', id, tableMeta.rowIndex)}
      >Edit</MenuItem>,
      <MenuItem key="Delete"
          onClick={(e) => this.onActionMenuItemClick(e, 'Delete', id, tableMeta.rowIndex)}
        >Delete</MenuItem>
  
    ];

    return result;


  }

  public getTableColumns() {

    var readOnly = false;

    var pkColumn = LIB.MUITableHelper.getColumnSetup("ID", "companyId", false);
   // pkColumn.options.display = 'excluded';
    pkColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (

      <Link
        onClick={(e: any) => this.onActionMenuItemClick(e, 'Edit', value, tableMeta.rowIndex)}
        color="primary">{value}</Link>

    );

    var actionsColumn = LIB.MUITableHelper.getColumnSetup("Actions", "companyId", false);
    actionsColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      LIB.MUITableHelper.getActionsButton(value, tableMeta, updateValue,
        this.getActionMenuItems(value, tableMeta, updateValue)
      )
    );

    var nameColumn = LIB.MUITableHelper.getColumnSetup("Name", "companyName", false);
    nameColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, { minWidth: 120 });
    }

    
    
    nameColumn.options.customBodyRender = (value:any, tableMeta: any, updateValue: any) => {

      var id = tableMeta.rowData[0];
      var item = this.props.state?.companies?.find(x => x.companyId === id);

      
       return value;

    };
 

    var visaTypeCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Visa", "visaType", "name",false );

   // LIB.MUITableHelper.getColumnSetup("Alert", "name", false, "", this.getAlertColumnRenderer),
 
    var relocationCol = LIB.MUITableHelper.getObjectChildPropertyColumn("Relocation", "relocationType", "name");
    var dateUpdatedCol = LIB.MUITableHelper.getColumnSetupDateTime("Updated", "dateModified");

    var hotColumn = LIB.MUITableHelper.getColumnSetup("Hot", "isHot", true);
    hotColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => {
      if (value) {
        return <span title="HotList Company!"><Icons.Whatshot style={{ color: 'red' }} /></span>
      } 
    };
 
   
    var domainColumn =   LIB.MUITableHelper.getColumnSetup("Domain", "domain", true);
    domainColumn.options.customHeadRender = (columnMeta: any, handle: any, sortOrder: any) => {
      return LIB.MUITableHelper.getCustomColumnHeader(columnMeta, handle, sortOrder, {width: 200 });
    }
    domainColumn.options.customBodyRender = (value:string, tableMeta: any, updateValue: any) => {

      
      return <div style={{width:'10rem'}} >{value}</div>;
       
   };

   var companyCol = LIB.MUITableHelper.getColumnSetup("Company", "companyName");


    //relocationCol.options.display = 'excluded';

    var columns = [
      pkColumn,
      nameColumn,
      domainColumn,
      LIB.MUITableHelper.getColumnSetup("Action Count", "actionCount"),
      LIB.MUITableHelper.getColumnSetup("Max Action Allowed", "maxActionsAllowed"),
      LIB.MUITableHelper.getCustomRenderValueColumn("Force End Free", "forceEndFree", (val: boolean) => String(val)),
      LIB.MUITableHelper.getColumnSetup("Active Billing", "hasActiveBillingPlan"),
      LIB.MUITableHelper.getColumnSetupShortDate("Next Payment Date", "billingNextPaymentDate"),

      LIB.MUITableHelper.getColumnSetup("Accounts", "domainAccounts"),
      
      dateUpdatedCol,
      LIB.MUITableHelper.getColumnSetup("Modified By", "modifiedByUserName", true),
      //companyCol,
      
      actionsColumn,
    ];


    return columns;
  }

 

  public onRowSelectionChange(currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) {

    //console.log('onRowSelectionChange currentRowsSelected',currentRowsSelected);

    var vm = this.props.state.vm;
    var state = this.props.state;

    var dataIndex = currentRowsSelected[0].dataIndex;

     var item = state.companies[dataIndex];
     vm.setSelectedCompany(item);

 

  }
  
  public setSelected(id: any){
    var state = this.props.state;
    var vm = state.vm;
    var found = state.companies.find(x => x.companyId === id);

    vm.setSelectedCompany(found);
  }

  public onNameClick(id: any) {
    var state = this.props.state;
    var vm = state.vm;
 
   this.setSelected(id);
 
    vm.isCompaniesEditOpen = true;

    if(this.props.onChange!==undefined)
        this.props.onChange();

    this.forceUpdate();

  }

  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    //console.log('onRowClick rowData', rowData);
    //console.log('onRowClick rowMeta', rowMeta);
    
    var vm = this.props.state.vm;


    // vm.selectedRowIndex = rowMeta.rowIndex;
    //this.forceUpdate();

  }


  public onPagingChange(tableState:MUIDataTableState){

    var vm = this.props.state.vm;
 
    var searchFilter = this.props.state.companySearchFilter;

    searchFilter.pageNum = tableState.page;
    searchFilter.maxRows = tableState.rowsPerPage;
    searchFilter.sortOrder = tableState.sortOrder.name;
    searchFilter.sortDirection = tableState.sortOrder.direction;
    searchFilter.isSearch=false;
  
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_REQUEST, searchFilter);
 
    
  }


  public render() {
    console.log('AdministrationCompaniesTable render');
    var state = this.props.state;
    var vm = state.vm;

  /*   if (LIB.Common.isNullOrEmpty(state) || !state.hasCompanyDataLoaded)
      return <LIB.Loading />;
 */
    var filter = state.companySearchFilter;
    var rows = state.companies;

    console.log('AdministrationCompaniesTable render rows',rows);


    //@ts-ignore
    const oldRender = TableCell.render

    //@ts-ignore
    TableCell.render = function (...args) {
      const [ props, ...otherArgs ] = args
      if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [ propsWithoutEmpty, ...otherArgs ])
      } else {
        return oldRender.apply(this, args);
      }
    } 


    const options: MUIDataTableOptions  = {
      filterType: 'dropdown',
      download: false,
      print: false,
      filter:false,
      viewColumns:false,
      selectableRows: 'single',
      onRowClick: this.onRowClick.bind(this),

      selectableRowsHideCheckboxes: true,
      selectableRowsOnClick: true,
      // rowsSelected:[vm.selectedRowIndex],
      selectToolbarPlacement: 'none',
      onRowSelectionChange: this.onRowSelectionChange.bind(this),
      search: false,
      rowsPerPage: 10,
     // rowsPerPageOptions: [10, 50, 100, 200],      
      rowsPerPageOptions: [],   
      jumpToPage: true,
       
      serverSide:true,
      count:state.companiesCount,
      
      onChangeRowsPerPage: (numberOfRows) => {
        console.log('onChangeRowsPerPage: ', numberOfRows);
        
      },
      onTableChange: (action, tableState) => {
        console.log('onTableChange action: ', action);
        console.log('onTableChange tableState: ', tableState);
 
        if(action=='changePage' || action=='sort'){
          this.onPagingChange(tableState)
        }
        

      }
      //,viewColumns:false

    };


 
    //var maxWidth=LIB.MUITableHelper.getTableMaxWidthByWindowSize();
 
    return <div  >
      <MUIDataTable
        title={""}
        data={rows}
        /*  data={rows.map(item => {
          return [
              item.projectId,
              item.type,
          ]
      })}  */
        
       
        
        columns={this.getTableColumns()}
        options={options}
       
      />



    </div>
  }
};

