import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class LinkedListService extends App.BaseAppService {



    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
    }


    public getListViews(listId:string, workspace:Api.Workspace, onSuccess: Function, onError: Function | undefined = undefined) {

        if(!workspace || !workspace?.workspaceId || !listId)
            return;

        console.log("LinkedListService.getListViews listId: ", listId);
        console.log("LinkedListService.getListViews workspace: ", workspace);

        if(workspace?.isLinkedWorkspace)
             this.linkedWorkspaceId = workspace.workspaceId;

        const apiUrl = `${App.AppBase.appApiBaseUrl}/LinkedList/getListViews?listId=${listId}`;

        this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess.bind(this),
            onError: onError?.bind(this),
            method: 'GET'
        }); 

    }




}
