import React, { ChangeEvent, ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';

import './LY_CheckBox.css';
 import { Checkbox, CheckboxProps } from '@mantine/core';

interface LY_CheckBoxProps extends LY.LY_InputBaseProps {
  name: string;
  defaultChecked?: boolean;
  checked?: boolean;
  inputClassName?: string;
  style?: React.CSSProperties;
  checkboxProps?: CheckboxProps;
  inputContainerBodyClassName?: string;
  inputContainerRootClassName?: string;
    onChange?: ( model: LY.DataItemModel | undefined, event?: ChangeEvent<any> | undefined) => void;
}


type combinedProps = LY_CheckBoxProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element - LY_CheckBox
export const LY_CheckBox = React.forwardRef<HTMLInputElement, LY_CheckBoxProps>((
  {
    name,
    defaultChecked,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    disabled,
    style,
    onChange,
    model,
    readOnly,
    checkboxProps,
    inputContainerBodyClassName='LY_CheckBoxInputContainerBodyForm',
    ...props
  },
  ref // This is the forwarded ref
) => {

  const [checked, setChecked] = React.useState(props.checked || false);


  React.useEffect(() => {
    //console.log('LY_CheckBox useEffect props.checked : ', props.checked );

    setChecked(props.checked || false);

  }, [ props.checked ]); 


  React.useEffect(() => {
    //console.log('LY_CheckBox useEffect model?.value : ', model?.value );

    if(props.checked === undefined)
      setChecked(model?.value || false);

  }, [ model?.value ]); 



  function onLocalChange(e: React.ChangeEvent<HTMLInputElement>) {

    //console.log('LY_CheckBox onLocalChange e: ', e);

    if (model)
        model.value = e.target.checked;
 
   // console.log('LY_CheckBox onLocalChange e.target.checked: ', e.target.checked);

    setChecked(e.target.checked);

  if (onChange){
   // console.log('LY_CheckBox onLocalChange onChange: ', model);

    onChange(model, e)
  }
 
 

  }


  function render() {


    var combinedInputClassName = ` `;
 

    if (inputClassName)
      combinedInputClassName += ' ' + inputClassName;

    var readOnlyValue = model?.value;
    if (!readOnlyValue && value)
      readOnlyValue = value;

   // if (checked === undefined)
  //    checked = value;
 
  var rootClassName:any = 'LY_CheckBoxInputContainerRoot';  
   if(!props.hideLabel)
        rootClassName= 'LY_CheckBoxInputContainerRoot2';

    return (
      <LY.LY_InputBase  {...props} 
      isRequired={props.isRequired}
      readOnly={readOnly}
      style={style} 
      tooltip={props.tooltip || model?.column?.helpText}
      //inputContainerClassName='LY_CheckBoxInputContainer'  
      >

        <Checkbox
          name={name}
          ref={ref}
          id={checkboxProps?.id}

          checked={checked}
          //defaultChecked={defaultChecked}
         // className={combinedInputClassName}
          classNames={{ body: inputContainerBodyClassName, root:rootClassName,
                       inner:'LY_CheckBoxInputContainerInner', input:'LY_CheckBoxInputContainerInput'  }}
          style={{ width: 'auto', height: 'auto' }}
          disabled={readOnly || disabled}
          {...checkboxProps}
          readOnly={readOnly}
         // required = {props.isRequired}
          onChange={onLocalChange}
          
 
        />



      </LY.LY_InputBase>
    );

  }


  return render();




});


