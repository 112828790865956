import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_RecordUpdateModule.css";


import { LY_RecordUpdateModule_Form } from './Components/LY_RecordUpdateModule_Form/LY_RecordUpdateModule_Form';
import { LY_RecordUpdateModuleContextProvider } from './Context/LY_RecordUpdateModuleContext';
 


export const LY_RecordUpdateModule: React.FC<LY.LY_RecordUpdateModuleProps> = (props) => {

  function render() {
    var view = props.view;
    // console.log('LY_FiltersModule render view:', view);
    // console.log('LY_FiltersModule render view.filter:', view?.filter);
    //console.log('LY_FiltersModule render view.orderByColumns:', view?.filter?.orderByColumns);
 
    return (
      <LY_RecordUpdateModuleContextProvider {...props}  >
        <div className="LY_RecordUpdateModuleContainer">
        <LY_RecordUpdateModule_Form {...props} />
        </div>
      </LY_RecordUpdateModuleContextProvider>
    );
  }




  return render();
};
