import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_RecordUpdateModule_Form.css";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
import { useFocusTrap } from '@mantine/hooks';
import { Responsive, WidthProvider } from "react-grid-layout";
import RGL  from "react-grid-layout";

import GridLayout from "react-grid-layout";


export const LY_RecordUpdateModule_Form: React.FC<LY.LY_RecordUpdateModuleProps> = (props) => {


    const context = LY.useRecordUpdateModuleContext();
    const { state, services  } = context;
    const item = state.row;
    const rowId = item?item['id']:undefined;
    const rowUpdates = state.rowUpdates;
    const focusTrapRef = useFocusTrap();
    const numberOfColumns = props.numberOfColumns || 1;

    //const columns = props.columns;
    //const currentWorkspace = App.AppBase.currentWorkspace;
   
    const workspaceVm = App.AppBase.getCurrentWorkspaceVm();
    const currentWorkspace =workspaceVm.selectedWorkspace;
    
  
  
    function onSubmit() {
   
      console.log('LY_RecordUpdateModule_Form onSubmit rowUpdates:', state.rowUpdates);
  
      state.errors.clear();
   
      var isValid = validateAll();
      if (!isValid) {
        console.log('LY_RecordUpdateModule_Form onSubmit isValid:', isValid);
        console.log('LY_RecordUpdateModule_Form onSubmit Errors:', state.errors);
  
        context.forceUpdate();
        return false;
      }
  
  
      console.log('LY_RecordUpdateModule_Form onSubmit state:', state);
  
    
      services.updateRowValues();
    
    }
  
     
  
    function validateAll(): boolean {
  
      var columns = state?.view?.columns;
  
      if (!columns)
        columns = state?.view?.columns;
  
      if (!columns) return false;
  
      var isValid = true;
  
      for (var col of columns) {
  
        if (col.isSystemHidden || col.hasAutoGenerate)
          continue;
    
        if (isValid && !validateOne(col))
             isValid= false;
      }
  
      return isValid;
  
    }
    
    function validateOne(col:Api.ListColumn): boolean {
  
      if(!col){
        state.errors.set('all', 'Invlaid input, column is empty!');
        return false;
      } 
  
     // console.log('validateOne item:', item);
      var value = item[col.name];
     // console.log('validateOne item value:', value);
  
      if(typeof value === 'string')
       value = value.trim();
  
    //  console.log('validateOne value:', value);
  
      state.errors.delete(col.name);
      var isRequired = (!col.isNull || col.isRequired);
   
    //  console.log('validateOne isRequired:', isRequired);
  
  
      //console.log('validateOne is required:', (!col.isNull || col.isRequired));
      //console.log('validateOne isNullOrEmpty:', LIB.Common.isNullOrEmpty(value));
  
      //if not required, return true if empty
      if(!isRequired && LIB.Common.isNullOrEmpty(value))
        return true;
  
      if(isRequired && LIB.Common.isNullOrEmpty(value)){
        //console.log('validateOne got into isRequired:', value);
        state.errors.set(col.name, `${col.displayName} is Required!`);
         
      }
      else if(col.listColumnTypeId == App.ListColumnTypeEnum.Email && !LIB.ValidationHelper.isValidEmail(value)){
        state.errors.set(col.name, `${col.displayName} is not a valid email address!`);
      }
      else if(col.listColumnTypeId == App.ListColumnTypeEnum.Phone && !LIB.ValidationHelper.isValidPhoneNumber(value)){
        state.errors.set(col.name, `${col.displayName} is not a valid phone number!`);
      }
  
      else if(col.listColumnTypeId == App.ListColumnTypeEnum.Website && !LIB.ValidationHelper.isValidUrl(value, false)){
        state.errors.set(col.name, `${col.displayName} is not a valid url!`);
      }
      else if (col.minLength && value.length < col.minLength) {
        state.errors.set(col.name, `${col.displayName} must have minimum of ${col.minLength} characters!`);
      }
      else if (col.maxLength && value.length > col.maxLength)  {
        state.errors.set(col.name, `${col.displayName} must have maximum of ${col.maxLength} characters!`);
      }
      else if(col.validationRegex && !LIB.ValidationHelper.isValidRegex(value,col.validationRegex)){
        state.errors.set(col.name, `${col.displayName} is not valid!`);
      }
   
      var isValid = (state.errors.size<1); 
  
      //console.log('validateOne isValid:', isValid);
      //console.log('validateOne state.errors:', state.errors);
      //console.log('validateOne col.name error:', state.errors[col.name]);
  
      return isValid;
  
    }
  
    
    function onInputChange(col:Api.ListColumn, e: React.ChangeEvent<any> | undefined,  m: LY.DataItemModel|undefined) {
      //e.preventDefault();
   
      //  console.log('LY_RecordUpdateModule_Form onInputChange m:', m);
      // console.log('LY_RecordUpdateModule_Form onInputChange e.target.name:', e.target);
  
      if (col.isSystemHidden || col.hasAutoGenerate)
           return;
   
        //   App.AppBase.showSaveSuccess(100000);
        var key:any = col.name;
        //console.log('LY_RecordUpdateModule_Form onInputChange key1:', key);
  
        if(m){
          item[key] = m.value;
  
  /*         console.log('LY_RecordUpdateModule_Form onInputChange value2:', m.value);
          console.log('LY_RecordUpdateModule_Form onInputChange key2:', key);
          console.log('LY_RecordUpdateModule_Form onInputChange item[key]1:',item[key]); */
  
        }
        else if(e)
          key = LIB.FormHelper.setInputChangeData(e.target, item);
         
    
        if(item[key]===undefined)
          item[key] = null;
  /*      console.log('LY_RecordUpdateModule_Form onInputChange key:', key);
       console.log('LY_RecordUpdateModule_Form onInputChange value:',m?.value);
       console.log('LY_RecordUpdateModule_Form onInputChange item:',item);
       console.log('LY_RecordUpdateModule_Form onInputChange item[key]:',item[key]); */
  
      // if (LIB.Common.isNullOrEmpty(key))
      //   return;
  
        validateOne(col);
     
       //todo this.validationManager.validateAccountProfileEdit(key, item);
  
       rowUpdates.set(key,item[key]);
      // console.log('onInputChange rowUpdates', rowUpdates);
  
       /* console.log('onInputChange item', item);
       console.log('onInputChange rowUpdates', rowUpdates);
       console.log('onInputChange rowUpdates size', rowUpdates.size);
   */
       context.forceUpdate();
       
      
    }
   
   
    function getInputByType(col: Api.ListColumn) {
  
      var result: any;
  
      if (col?.listColumnTypeId == App.ListColumnTypeEnum.LongText) {
        result = getLongTextRenderer(col);
      }
      else
        result = getInputSelectorRenderer(col);
  
  
      return result;
    }
  
  
    function getLongTextRenderer(col: Api.ListColumn) {
  
   
      var columns = state?.view?.columns;
  
  
      if (props.forceReadOnly)
        return <span className='LY_ListColumnCellReadOnly'>{item?.value}</span>;
  
      var type: any = 'text';
      if (!LIB.Common.isNullOrEmpty(col.listColumnClientType))
        type = col.listColumnClientType;
  
  
      var result = (
  
        <LY.LY_TextArea
          key={`formItem_${col.name}_${rowId}`}
          name={col.name}
          label={col.displayName}
          placeholder="Enter Value"
          //form={{ register }}
          labelPlacement='top'
          withStar={!col.isNull}
          readOnly={props.readOnly}
          //{...field}
          value={item[col.name]}
          error={state.errors.get(col.name)}
          helpText={col.description}
          // helpTextPlacement='bottom'
          onChange={(model, e) => {
            onInputChange(col,e!, model);
          //  console.log('LY_RecordUpdateModule_Form LY_TextArea onChange', model);
          }
          
          }
  
          inputContainerClassName={`LY_RecordUpdateModule_FormContainerItem ${props.formContainerItemClassName || ''}`}
         
        />
        /*  <div key={index}>
         {col.displayName}
       </div> */
      );
  
  
      return result;
  
    }
  
    
    function getInputSelectorRenderer(col: Api.ListColumn) {
  
      if(!col || !item)  
        return null;

      var columns = state?.view?.columns;
  
  /* 
      if (props.forceReadOnly)
        return <span className='LY_ListColumnCellReadOnly'>{item?.value}</span>;
   */
  
   /*      if(col.name=='fsc_code'){
          console.log('LY_RecordUpdateModule_Form fsc_code getInputSelectorRenderer col:', col);
          console.log('LY_RecordUpdateModule_Form fsc_code getInputSelectorRenderer item:', item);
        }
   */
      var value = item[ col.name ];  
  
  
  
  
  
      var itemModel = new LY.DataItemModel(); //{...props.model || new LY.DataItemModel()};
 
      itemModel.row = item;
      itemModel.column = col;
      itemModel.value = value;
      itemModel.listId = props.listId;
  
      var linkedWorkspaceId = state?.linkedWorkspaceId;
  
      if(col.fkIsLinkedWorkspace && col.fkWorkspaceId)
        linkedWorkspaceId = col.fkWorkspaceId;
  
  
       var formContainerItemClassName =  props.formContainerItemClassName;
        if(formContainerItemClassName && col.listColumnTypeId == App.ListColumnTypeEnum.Status)
          formContainerItemClassName = formContainerItemClassName+=' '+formContainerItemClassName+'Status';
  
  
      var result = (
  
        <LY.LA_InputSelector
          key={`formItem_${col.name}_${rowId}`}
          model={itemModel}
          linkedWorkspaceId={linkedWorkspaceId}
          workspaceId={props.workspaceId}
          column={col}
          lookupTypes={props.lookupTypes}
          dropDownData={props.dropDownData}
          users={workspaceVm?.users}
          forceReadOnly={props.readOnly}
          name={col.name}
          label={col.displayName}
          value={value}
          
         // placeholder={'Select ' + col.displayName}
  
          labelPlacement='top'
  
          withStar={!col.isNull || col.isRequired}
           error={state.errors.get(col.name)}
  
          onChange={(model, e) => {
               // console.log('LY_RecordUpdateModule_Form onChange', model);
              //  console.log('LY_RecordUpdateModule_Form e', e);
  
            onInputChange(col,e, model);
          }
          }
          maxDisplayLength={30}
  
          isForList={false}
          inputContainerClassName={`LY_RecordUpdateModule_FormContainerItem ${formContainerItemClassName || ''}`}
  
        />
        /*  <div key={index}>
         {col.displayName}
       </div> */
      );
  
  
      return result;
  
    }
  
    function getInputRenderer(col: Api.ListColumn) {
  
      
     
  
      if (props.forceReadOnly)
        return <span className='LY_ListColumnCellReadOnly'>{item?.value}</span>;
  
      var type: any = 'text';
      if (!LIB.Common.isNullOrEmpty(col.listColumnClientType))
        type = col.listColumnClientType;
  
      var value = item[ col.name ];
  
    /*   if (col?.listColumnTypeId == App.ListColumnTypeEnum.Date) {
        console.log('LY_RecordUpdateModule_Form getInputRenderer Date', value);
      } */
     // console.log('LY_RecordUpdateModule_Form getInputRenderer item:', item);
     // console.log('LY_RecordUpdateModule_Form getInputRenderer value:', value);
  
  
      var result = (
  
        <LY.LY_Input
          key={`formItem_${col.name}_${rowId}`}
  
          name={col.name}
          type={type}
          label={col.displayName}
          placeholder="Enter Value"
          labelPlacement='top'
          withStar={!col.isNull || col.isRequired}
          readOnly={props.readOnly}
          //model={item}
            value={value}
           error={state.errors.get(col.name)}
          //helpText={col.description}
          // helpTextPlacement='bottom'
          onChange={(model, e) => {
            //field.onChange(event);
            onInputChange(col,e!, model);
            //console.log('LY_RecordUpdateModule_Form onChange', model);
          }
          }
        />
        /*  <div key={index}>
         {col.displayName}
       </div> */
      );
  
  
      return result;
  
    }
  
    function onClose() {
      console.log('LY_RecordUpdateModule_Form onClose', props.onClose);
  
      //reset({});
  
      if (props.onClose)
        props.onClose();
  
    }

    function getForm():React.ReactNode[] {
 
  
      /*   console.log('LY_RecordUpdateModule_Form getForm columns:', columns);
        console.log('LY_RecordUpdateModule_Form getForm row:', item?.row);
        console.log('LY_RecordUpdateModule_Form getForm selectColumns:', view?.filter?.selectColumns);
       */

      if(!item)
        return [];

      var view = state?.view;
      var columns = props.columns;
        
      var selectColumns = view?.filter?.selectColumns;
  
      if (!columns) return [];
  
      if(props.onlyIncludeColumns && props.onlyIncludeColumns.length>0){
        columns = columns.filter(col=>props.onlyIncludeColumns!.includes(col.name));
      }
      else if(props.skipColumns && props.skipColumns.length>0){
        columns = columns.filter(col=>!props.skipColumns!.includes(col.name));
      }
       
  
      var colList = columns.map(col => col.name).join(',');
     // console.log('LY_RecordUpdateModule_Form getForm colList:', colList);
   
      var elements = columns.map((col, index) => {
  
        //console.log(col.name);
  
       // console.log('LY_RecordUpdateModule_Form getForm col:', col.name);
  
        if (col.isSystemHidden || col.hasAutoGenerate)
          return null;
  
        if (col.fkIsMultiple && props.excludeFKMultiple)
          return null;
   
         if(selectColumns && selectColumns.length>0 && !selectColumns.includes(col.name))
            return null;
  
         var result = getInputByType(col);
         return result;
       
      });
  
      return elements;
  
    }
  

    function getFormWithGridLayout() {

      const ResponsiveReactGridLayout = WidthProvider(Responsive);
      const ReactGridLayout = WidthProvider(RGL);
  // layout is an array of objects, see the demo for more complete usage
  const layout = [
    { i: "a", x: 0, y: 0, w: 1, h: 2 },
    { i: "b", x: 0, y: 1, w: 2, h: 2 },
    { i: "c", x: 1, y: 2, w: 1, h: 1 },
    { i: "d", x: 0, y: 4, w: 2, h: 3 }
  ];
  var layouts =  {lg: layout};
  var itemClass = 'LY_RecordUpdateModule_Form_GridLayoutItem';


  var items = [
    <div className="grid-item">aaa</div>,
    <div className="grid-item">bbb</div>,
    <div className="grid-item">ccc</div>,
    <div className="grid-item">DDDDDDDDD</div>
  ];

  return <div>

{/* 
<div className="LY_RecordUpdateModule_Form_GridLayout2">
  <div className="grid-item grid-item-a">aaa</div>
  <div className="grid-item grid-item-b">bbb</div>
  <div className="grid-item grid-item-c">ccc</div>
  <div className="grid-item grid-item-d">DDDDDDDDD</div>
</div>
 */}
  <LY.LY_GridLayout 
 
    idProperty="i"
    numberOfColumns={2}
    layout={layout}
    items={items}
    
    />
 
 
    <ReactGridLayout
      className="layout"
      //layouts={layouts}
      layout={layout}
      cols={2}
  //    cols: {lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}
      rowHeight={30}
      isResizable={false}
      isDraggable={false}
      allowOverlap={false}
    // width={1200}
     
    >
      <div key="a"  className='LY_RecordUpdateModule_Form_GridLayoutItem'>aaa</div>
      <div key="b"  className='LY_RecordUpdateModule_Form_GridLayoutItem'>bbb</div>
      <div key="c"  className='LY_RecordUpdateModule_Form_GridLayoutItem'>ccc</div>  
      <div key="d"  className='LY_RecordUpdateModule_Form_GridLayoutItem'>DDDDDDDDD</div>  
    {/*   {getForm()} */}

    </ReactGridLayout>
 

  </div>

    }
  
    function render() {
     // console.log('LY_RecordUpdateModule_Form render state', state);
  
     
      //var globalError = state.errors.get('all');
      var globalError = state.errors?.values()?.next()?.value;
  
  
      // console.log('LY_RecordUpdateModule_Form render name:', item?.row['name']);
  
      /* console.log('LY_RecordUpdateModule_Form form:', rowId);
      console.log('LY_RecordUpdateModule_Form render rowUpdates size', rowUpdates.size);
      console.log('LY_RecordUpdateModule_Form render state.errors', state.errors); */
  
      var result = (
        <form id={'form_' + rowId} key={'form' + rowId}
     
          className='LY_RecordUpdateModule_Form'
          ref={focusTrapRef}
        >
            {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}
  
          <div 
           className={`LY_RecordUpdateModule_FormContainer LY_Scrollbar ${props.formContainerClassName || ''}`}
           
           //todo style={{ gridTemplateColumns: `repeat(${props.numberOfColumns || 1}, 1fr)`}}

           >
        
            {getForm()}
       {/*      {getFormWithGridLayout()} */}
  
          </div>
  
          {!props.excludeButtons &&
            <MT.Group mt="xl" justify='right'>
              <MT.Button variant='default'
                onClick={() => onClose()} >Close</MT.Button>
              <MT.Button  
                loading={state.isActionInProgress} 
                loaderProps={{ type: 'dots' }}
                onClick={onSubmit}
                disabled={(state.rowUpdates.size<1)}
                hidden={props.forceReadOnly}
              >
                Save
              </MT.Button>
            </MT.Group>
          }
  
   
  
        </form>
  
   
      );
  
      return result;
  
    }
  
  
  
  
    return render();
  };
  