
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { DLAContractLabelSize } from './DLAContractLabelSize';


export class DLAContractLabelModel   {

    public rfq:string;
    public nsn:string;
    public part_number:string;
    public companyCage:string;
    public mfgCage:string;
    public quantity:number;
    public qup:number;
    public unit_of_issue:string;
    public description:string;
    public date:string;
    public size:DLAContractLabelSize = new DLAContractLabelSize('4w X 2h - 8 Per Page','4in', '2in',8);

    public totalCount:number = 0;


    constructor() {
     
    }



    
    

}