import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_DLA_LabelMakerModule_Label.css";

import JsBarcode from "jsbarcode";

import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';

interface LY_DLA_LabelMakerModule_LabelProps extends LY.LY_DLA_LabelMakerModuleProps {
  onlyLabel?: boolean;
}

export const LY_DLA_LabelMakerModule_Label: React.FC<LY_DLA_LabelMakerModule_LabelProps> = (props) => {

  const context = LY.useDLALabelMakerModuleContext();
  const { state, services } = context;
  var item = state.model;

  // const { isMobile } = useIsMobile(768);


  React.useEffect(() => {
    JsBarcode('#nsnBarCode', item?.nsn, {
      format: "CODE128",
      lineColor: "#000",      // Black lines
      width: 2,                  // Bar width
      height: 32,                // Bar height (taller for similar style)
      displayValue: true,        // Show the text below
      fontOptions: "bold",       // Font styling for the text
      fontSize: 18,              // Text font size
      margin: 0                 // Margins around the barcode

    });
    //JsBarcode("#nsnBarCode", "1234567890128", {format: "ean13"});

  });



  function getFormattedNSN(nsn: string) {
    if (!nsn || nsn.length !== 13)

      return `${nsn.slice(0, 4)}-${nsn.slice(4, 6)}-${nsn.slice(6, 9)}-${nsn.slice(9)}`;
  }

  function getFormattedRFQ(rfq: string) {
    if (!rfq || rfq.length !== 13)
      return rfq;

    return `${rfq.slice(0, 4)}-${rfq.slice(4, 6)}-${rfq.slice(6, 9)}-${rfq.slice(9)}`;
  }


  function getBarCode() {

  }




  function getLabelOnly(classNameAppend:string='') {

    var size = item.size;

 
    return <div
        className={'LY_DLA_LabelMakerModule_Label_Item_Container'+classNameAppend}
        style={{ width: size?.width, height: size?.height }}
      >

        <div className={'LY_DLA_LabelMakerModule_Label_ContainerItems'+classNameAppend}>
          <div
            className='LY_DLA_LabelMakerModule_Label_BarCode_Container'
          >
            <svg id='nsnBarCode'
              className='LY_DLA_LabelMakerModule_Label_BarCode'
            ></svg>
          </div>
          <div>{getFormattedNSN(item?.nsn)}</div>
          <div
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          ><span>CAGE: {item?.companyCage}</span>  <span> MFG CAGE: {item?.mfgCage}</span></div>
          <div>PN: {item?.part_number}</div>
          <div>{item?.description}</div>
          <div>{item?.qup} {item?.unit_of_issue}</div>
          <div>{getFormattedRFQ(item?.rfq)}</div>
          <div>MCM - {item?.date}</div>


        </div>

      </div>;

  }


  function render() {

    if (props.onlyLabel)
      return getLabelOnly();

    return <div
      className='LY_DLA_LabelMakerModule_Label_Container'
      style={{ width: '6in', minHeight: '4in' }}
    
    >
      <div dangerouslySetInnerHTML={{ __html: `<style>${context.getPrintStyleClasses(false,'Show')}</style>` }} />
 
      {getLabelOnly('Show')}
   
    </div>

  }

  return render();

};
