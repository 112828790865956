import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_NotesModule_Note_Menu.css";
 
import { Menu, Button, rem } from "@mantine/core";
 

import { LY_NotesModuleProps } from "../../Interfaces/LY_NotesModuleProps";
import { useNotesModuleContext } from "../../Context/LY_NotesModuleContext";

interface LY_NoteMoLY_NoteModule_Note_Menu_Props extends LY_NotesModuleProps {
  note?: Api.Note;
  reply?: Api.NoteReply;
}

export const LY_NoteModule_Note_Menu: React.FC<LY_NoteMoLY_NoteModule_Note_Menu_Props> = (props) => {


  const context = useNotesModuleContext();
  const [confirmModalVisible, setConfirmModalVisible] = React.useState(false);

  let message: string;
  var isCurrentUser: boolean;

/*   console.log('LY_NoteModule_Note_Menu props:', props);
  console.log('LY_NoteModule_Note_Menu currentUser?.id2:', props.currentUser?.id2 );
  console.log('LY_NoteModule_Note_Menu props.note?.author_id:', props.note?.author_id);

   */

  if (props.reply) {
    message = props.reply?.message!;
    isCurrentUser = props.currentUser?.id2 === props.reply.author_id;
  } 
  else {
    message = props.note?.message!;
    isCurrentUser = props.currentUser?.id2 === props.note?.author_id;
  }

  function onMenuModalConfirmClick() {
    if (props.reply) {
      context.deleteReply();
    } else {
      context.deleteNote();
    }
    setConfirmModalVisible(false);
  }

  function onMenuDotsClick(note: Api.Note, reply: Api.NoteReply) {
    if (props.reply) {
      context.selectReply(reply.note_id!, reply.id!);
      context.setReplyInputOn(false);
    } else {
      context.selectNote(note.id!);
      context.setReplyInputOn(false);
    }
  }

  function onMenuUpdateClick(note: Api.Note, reply: Api.NoteReply) {
    if (props.reply) {
      context.setUpdatingReply(true);
      context.selectReply(reply.note_id!, reply.id!);
    } else {
      context.setUpdatingNote(true);
      context.selectNote(note.id!);
    }
  }

  function menuConfirmModalRenderer() {
    return (
      <LY.ConfirmModal
        confirmButtonLabel="Delete"
        title={"Are you sure you want to delete the item ?"}
        visible={confirmModalVisible}
        content={message}
        onConfirmClick={onMenuModalConfirmClick}
        onCancelClick={() => {
          setConfirmModalVisible(false);
        }}
      />
    );
  }

  function render() {
    return (
      <Menu shadow="md" width={120} withArrow arrowSize={10}  >
        <Menu.Target>
          <Button className="LY_NoteModule_Note_Menu_Button">
            <Icons.IconDots
              onClick={() => onMenuDotsClick(props.note!, props.reply!)}
            />
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            leftSection={
              <Icons.IconTrash style={{ width: rem(14), height: rem(14) }} />
            }
            disabled={!isCurrentUser}
            onClick={() => {
              setConfirmModalVisible(true);
            }}>
            Delete
          </Menu.Item>
          <Menu.Item
            leftSection={
              <Icons.IconPencil style={{ width: rem(14), height: rem(14) }} />
            }
            disabled={!isCurrentUser}
            onClick={() => onMenuUpdateClick(props.note!, props.reply!)}>
            Edit
          </Menu.Item>
        </Menu.Dropdown>
        {menuConfirmModalRenderer()}
      </Menu>
    );
  }

  return render();
};
