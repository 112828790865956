import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { LY_StaticDropDown_Edit } from '../LY_StaticDropDown_Tabs/LY_StaticDropDown_Edit';
import { LY_StaticDropDown_Options } from '../LY_StaticDropDown_Options/LY_StaticDropDown_Options';
import { LY_StaticDropDown_View } from '../LY_StaticDropDown_View/LY_StaticDropDown_View';
import { Popover } from "@mantine/core";



export const LY_StaticDropDown_Container: React.FC<LY.LY_StaticDropDownProps> = (props) => {
 

    const context = LY.useStaticDropDownContext();
    const state = context.state;

    var multipleClass = '';

    if(props.allowMultiSelect)
        multipleClass = 'LY_StaticDropDownContainerMultiple ';

    function getPopOver() {


        return <Popover
            closeOnClickOutside={!state.isEditMode}
            opened={context.state.isSelectStaticPopoverOpen}
            onChange={context.setPopoverOpened}
            position="bottom"
            offset={1}
            withArrow
            arrowSize={12}
        >
            <Popover.Target>
                <LY_StaticDropDown_View {...props} 
                style={props.style}
                inputContainerClassName={'LY_StaticDropDownContainer ' +multipleClass + (props.inputContainerClassName || '')}
                 />
            </Popover.Target>
            {!state.readOnly && <Popover.Dropdown
                className={`LY_StaticDropDownPopoverDropdown`}
            >
                {!state.isEditMode ? <LY_StaticDropDown_Options {...props} /> : <LY_StaticDropDown_Edit {...props} />}

            </Popover.Dropdown>
            }
        </Popover >
    }

    function render() {

        return <LY.LY_InputBase {...props}
        style={{ width: '100%' }}
        >
            {getPopOver()}

        </LY.LY_InputBase>

    }

    return render();
}