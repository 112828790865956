import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { createContext, useContext, useEffect } from "react";

import { LY_RecordUpdateModuleServiceManager } from '../Managers/LY_RecordUpdateModuleServiceManager';
import { LY_RecordUpdateModuleState } from './LY_RecordUpdateModuleState';


import { useDisclosure } from '@mantine/hooks';

export interface LY_RecordUpdateModuleContextProps {
  state: LY_RecordUpdateModuleState;
  forceUpdate: () => void;
  onCancelOrCloseReset: () => void;
  resetState: () => void;
  services: LY_RecordUpdateModuleServiceManager; 
}

const useExampleModuleState = (props: LY.LY_RecordUpdateModuleProps): LY_RecordUpdateModuleContextProps => {


  const initialState = new LY_RecordUpdateModuleState();

  const [state, setState] = React.useState<LY_RecordUpdateModuleState>(initialState);
  const [propsState, setPropsState] = React.useState<LY_RecordUpdateModuleState>(new LY_RecordUpdateModuleState());
  

  const contextObjBase: LY.LY_RecordUpdateModuleContextProps = {
    state,
    forceUpdate,
    onCancelOrCloseReset,
    resetState,
    services: {} as LY_RecordUpdateModuleServiceManager
  };

  const services = new LY_RecordUpdateModuleServiceManager(props, state, contextObjBase);


  const contextObj: LY.LY_RecordUpdateModuleContextProps = {...contextObjBase, services: services};

  
  React.useEffect(() => {

    console.log('LY_RecordUpdateModule useEffect props', props);

    state.workspaceId = props.workspaceId;
    state.view = props.view;
    state.workspaceId = props.workspaceId;
    state.linkedWorkspaceId = props.linkedWorkspaceId;
    state.isContainerModalOpen = props.isContainerModalOpen || false;
    state.row = props.row;
    state.errors.clear();
      
    forceUpdate();

    var stateCopy = clone(state);
    setPropsState(stateCopy);


  }, [ props.row, props.workspaceId, props.view ]);



  function clone(stateIn: LY_RecordUpdateModuleState): LY_RecordUpdateModuleState {

    var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_RecordUpdateModuleState;
    stateCopy.errors = new Map<string, any>();
    return stateCopy;
  }


  function forceUpdate() {
    setState({ ...state })
  }
  
  function resetState() {
    var stateCopy = clone(propsState);
    setState({ ...state })
  }


  function onCancelOrCloseReset() {

    state.isActionInProgress = false;
    state.isContainerModalOpen = false;


    state.errors.clear();
    forceUpdate();
  }

 

 

  return contextObj;
};

const LY_RecordUpdateModuleContext = createContext<LY.LY_RecordUpdateModuleContextProps | undefined>(undefined);

export const useRecordUpdateModuleContext = () => {


  const context = useContext(LY_RecordUpdateModuleContext)


  if (!context) {
    throw new Error(
      "useLY_StatusDropDownContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};


const LY_RecordUpdateModuleContextProvider: React.FC<LY.LY_RecordUpdateModuleProps & { children: React.ReactNode }> = (props) => {


  const contextValue = useExampleModuleState(props);

  function render() {


    return (
      <LY_RecordUpdateModuleContext.Provider value={contextValue}>
        {props.children}
      </LY_RecordUpdateModuleContext.Provider>
    );
  }

  return render();
};
export { LY_RecordUpdateModuleContextProvider, LY_RecordUpdateModuleContext };
