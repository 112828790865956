
import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import './LY_LinkSelector.css';

 
import { Button, Popover, TextInput } from "@mantine/core";
import { EditorInstance, useEditor } from "novel";
import { useEffect, useRef } from "react";


export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (_e) {
    return false;
  }
}

export function getUrlFromString(str: string) {
  if (isValidUrl(str)) return str;
  try {
    if (str.includes(".") && !str.includes(" ")) {
      return new URL(`https://${str}`).toString();
    }
  } catch (_e) {
    return null;
  }
}

interface LinkSelectorProps extends LY.LY_SlashEditorBaseProps {
  editor?: EditorInstance | null;

  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const LY_LinkSelector = ({ open, onOpenChange, editor, ...props }: LinkSelectorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
 
/*   useEffect(() => {
    inputRef.current?.focus();
  }); */


  function render() {
    if (!editor) return null;

    return <Popover
      opened={open}
      onClose={() => onOpenChange(false)}
      position="bottom-start"
      withArrow
     // arrowPosition='side'
      shadow="md"
      withinPortal={false}

      closeOnClickOutside={true}
      
      //classNames={{ dropdown: "LY_LinkSelector_PopoverDropdown" }}
    >
      <Popover.Target>
 
        <LY.LY_ButtonPinkHover
          isActive={open}
          title={'Link'}
          onClick={() => onOpenChange(!open)}
        >
          {props.isTextButton ?
            <div className="LY_SlashEditor_ButtonText">
              <Icons.IconLink size={18} color='#757B87' />
              <span>Link</span>
            </div>
            :
            < Icons.IconLink size={20} />}
        </LY.LY_ButtonPinkHover>
 
      </Popover.Target>
      <Popover.Dropdown style={{ width: "240px", padding: "0" }}>
        <form
          onSubmit={(e) => {
             e.preventDefault();
            const input = inputRef.current;
            if (!input) return;
            const url = getUrlFromString(input.value);


            if (url) {
              editor.chain().focus().setLink({ href: url }).run();
              onOpenChange(false);
            }
          }}
          className="LY_LinkSelector_DropdownForm"
        >
          <TextInput
            ref={inputRef}
            placeholder="Paste a link"
            size="sm"
            defaultValue={editor.getAttributes("link").href || ""}
          />
          {editor.getAttributes("link").href ? (
            <Button
              variant="subtle"
              size="compact-sm"
              onClick={() => {
                editor.chain().focus().unsetLink().run();
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
                onOpenChange(false);
              }}
            >
              <Icons.IconTrash color="red" size={20} />
            </Button>
          ) : (
            <Button type="submit" size="compact-sm" variant="subtle"
            onClick={()=>onOpenChange(false)}
            >
              <Icons.IconCheck type="submit" size={20} />
            </Button>
          )}
        </form>
      </Popover.Dropdown>
    </Popover>
  }

  return render();
};
