import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

export class LY_BulkEmailSenderModuleState extends LIB.BaseVmModel {

  public emailToItems: any[];
  public record?:any;
  public listState:App.ListState;

  public selectedItem:any;
  public selectedItemName:any;

  public selectedEmailToItems:any[];

  public workspaceId?:number | undefined;
  public linkedWorkspaceId?:number | undefined;

  public isContainerModalOpen:boolean = false;
  public refEmailContentDiv:HTMLDivElement | null = null;

  public reportState:Api.ReportState;
  public reportFiltered:Api.ReportState;
  public reportData?:any[]=[];

  public selectedItemToEmails:string[]=[];

  public selectedItemReportFirstRow:any;


//  public toEmails?:string[]=[];


  constructor() {
    super();
  }
}
