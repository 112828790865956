import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_BulkEmailSenderModuleState } from "../Context/LY_BulkEmailSenderModuleState";

export class LY_BulkEmailSenderModuleServiceManager {


  public forceUpdate: () => void;
  public state: LY_BulkEmailSenderModuleState;
  public props: LY.LY_BulkEmailSenderModuleProps;
  public context: LY.LY_BulkEmailSenderModuleContextProps;


  public listViewService: App.ListViewService;
  public reportsService: App.ReportsService;

  public sm: Api.ListViewGetDataSm;

  constructor(props: LY.LY_BulkEmailSenderModuleProps,
    state: LY_BulkEmailSenderModuleState,
    context: LY.LY_BulkEmailSenderModuleContextProps) {


    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;

    this.sm = new Api.ListViewGetDataSm()

    this.listViewService = new App.ListViewService(props.workspaceId)
    this.listViewService.linkedWorkspaceId = this.props.linkedWorkspaceId;

    this.reportsService = new App.ReportsService(props.workspaceId)

  }
 
  public onServiceError(error: any) {

    if (this.state == undefined)
      return;

    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    App.AppBase.showError(errorMessage)
    this.state.isActionInProgress = false;
    this.forceUpdate();

  }

 

  public runReport(sm: Api.RunReportSm) {

    console.log('LY_BulkEmailSenderModuleServiceManager runReport start sm:', sm);
 
    this.reportsService.runReport(sm,

      //onSuccess
      (result: Api.ReportState) => {
        console.log('LY_BulkEmailSenderModuleServiceManager runReport onSuccess:', result);

     
        this.state.isActionInProgress = false;
        
        this.context.setReportResult(result);
  
      },
      //onError
      this.onServiceError.bind(this)
    );

    this.state.isActionInProgress = true;
    this.forceUpdate();
 
  }

}
