import * as Api from 'app-api';
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';
import * as React from 'react';
import * as App from 'AppReferences';


import "./LY_StaticDropDown_Edit_Options.css";


import { Button, Input, Popover, Switch } from '@mantine/core';
import { DndContext, DragEndEvent, } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { getNewSelectedColor } from "../../Constants/constants";
import { useDisclosure } from "@mantine/hooks";

interface SortableItemProps {
    id: string;
    children: React.ReactNode;
}


const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {


    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            className="sortable-item"
        >
            <div
                {...listeners}
                style={{ cursor: 'grab', display: 'inline-block' }}
                className="sortable-item-handle"
            >
                <Icons.IconGripVertical />
            </div>
            {children}
        </div>
    );
};

interface LY_StaticDropDown_Edit_Options_Props extends LY.LY_StaticDropDownProps {

    onInputChange: (key: string, value: any, item: Api.SystemLookupType, index: number) => void;
    setActiveTab: React.Dispatch<React.SetStateAction<string | null>>
}


export const LY_StaticDropDown_Edit_Options: React.FC<LY_StaticDropDown_Edit_Options_Props> = (props) => {


    const context = LY.useStaticDropDownContext();
    const state = context.state;


    const [ opened, { open, close } ] = useDisclosure(false);
    const [ tooltipText, setTooltipText ] = React.useState(state.selectedEditItem?.help_text || '');
    const [ deletedItem, setDeletedItem ] = React.useState<Api.SystemLookupType | null>(null);
    const [ search, setSearch ] = React.useState('');


    const item = state.selectedEditItem;
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    const column = state?.model?.column;

    function handleDeleteOption() {
        if (!deletedItem?.id) return;
        context.deleteById(deletedItem.id);
        setDeletedItem(null);
    }

 
    function addOptionAndScroll() {
        const newItem = new Api.SystemLookupType();
        newItem.name = '';
        newItem.text_color = state.allowMultipleSelection? '#FFFFFF':undefined;
        newItem.id = LIB.StringHelper.generateUUID();
        newItem.color = state.allowMultipleSelection ? getNewSelectedColor(state.items.length) : undefined;
        newItem.sort_order = state.items.length;
        context.addItem(newItem);
        scrollDown();
    }

    function scrollDown() {
        setTimeout(() => {
            if (wrapperRef.current) {
                wrapperRef.current.scrollTo({
                    top: wrapperRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }, 100);
    }
    function tooltipModal() {
        return <MT.Modal
            centered
            opened={opened}
            onClose={close}
        
            styles={{
                root: {
                    zIndex: 9990,
                    paddingTop: 0
                },
                body: {
                    paddingTop: 0
                }
            }}
            title={<div>Tooltip for <b>{item?.name}</b></div>}>
            <MT.Modal.Body>
                <div>
                    This tooltip will show on mouse-over of this Option
                </div>
                <MT.Textarea
                    value={tooltipText}
                    placeholder="Enter Tooltip"
                    styles={{
                        wrapper: {
                            marginTop: 10,
                        },
                        input: {
                            height: "60px",
                            borderRadius: "8px"
                        }
                    }}

                    onChange={(e) => {
                        setTooltipText(e.target.value);
                    }}
                />
                <div className="LY_StatusSelect_Options_Modal_Footer">
                   
                    <LY.LY_ButtonCancel  onClick={() => {
                 
                        setTooltipText(item?.help_text || '');
                        close();
                        context.forceUpdate();

                    }}>Cancel</LY.LY_ButtonCancel >
                   
                    <LY.LY_Button  onClick={() => {
                        if (item) {
                            item.help_text = tooltipText;
                        }
                        setTooltipText(item?.help_text || '');
                        close();
                        context.forceUpdate();

                    }}>Save</LY.LY_Button >
                </div>
            </MT.Modal.Body>
        </MT.Modal>
    }




    function getItemMenu(item: Api.SystemLookupType) {
        return <MT.Menu shadow="md" width={200} zIndex={9999}
            withArrow arrowSize={10}
        >
            <MT.Menu.Target>
                <div className="LY_StaticSelect_Options_item_delete_div">
                    <Icons.IconDots
                        className="LY_StaticSelect_Options_item_delete"
                        style={{ minWidth: '16px', width: '16px' }}
                    />
                </div>
            </MT.Menu.Target>

            <MT.Menu.Dropdown className="LY_StaticSelect_Options_ContextMenu_DropDown"  >
                <MT.Menu.Item onClick={() => {

                    state.selectedEditItem = item;
                    context.forceUpdate();
                 

                    open()
                }}
                // leftSection={<Icons.IconPlus style={{ width: MT.rem(14), height: MT.rem(14) }} />}
                >
                    Add/Edit Toolip
                </MT.Menu.Item>
                <MT.Menu.Item onClick={() => {
                    context.state.previewItem = item;
                    props.setActiveTab('style');
                    context.forceUpdate();
                }}
                  //  leftSection={<Icons.IconPlus style={{ width: MT.rem(14), height: MT.rem(14) }} />}
                    
                    >
                    Preview Style
                </MT.Menu.Item>
                <MT.Menu.Item
                    onClick={() => setDeletedItem(item)}
                   // leftSection={<Icons.IconTrash style={{ width: MT.rem(14), height: MT.rem(14) }} />}
                >
                    Delete
                </MT.Menu.Item>
            </MT.Menu.Dropdown>
        </MT.Menu>
    }




    function searchInputRenderer() {
        if (state.items.length < 5)
            return null;

        return <Input
            classNames={{
                input: "LY_StaticSelect_Options_input",
                wrapper: "LY_StaticSelect_Options_input_wrapper"
            }}
            styles={{
                wrapper: {
                    minHeight: "30px"
                },
                input: {
                    minHeight: "25px",
                    height: "30px",
                }
            }}
            value={search}
            placeholder="Search..."
            onChange={e => setSearch(e.target.value)}
            leftSection={< Icons.IconSearch width={16} height={16} />}
        />;
    }



    function getRenderOptionItem(index: number, item: Api.SystemLookupType) {


        const nameInputName = 'edit_option_name' + index;
        const valueInputName = 'edit_option_value' + index;

        const type = props.valueProperty === 'value_id' ? 'number' : 'text';
        var valPlaceholder = props.valueProperty === 'value_id' ? 'number' : 'string';

 
        return (
            <SortableItem key={item.sort_order!} id={item.id!}>
                <LY.LY_DoubleColorInput
                    onInputChange={(value) => {
                        props.onInputChange('name', value, item, index);
                    }}
                    item={{
                        name: nameInputName,
                        text_color: item.text_color || "",
                        color: item.color || ""
                    }}
                    onColorChange={(type: string, value) => {
                        //@ts-ignore
                        item[ type ] = value
                    }}
                    value={item.name}
                    placeholder="Name"
                    error={state.errors.get(nameInputName)}
                />
                {props.valueProperty !== 'id' && (
                    <MT.TextInput
                        styles={{
                            wrapper: {
                                minHeight: "30px",
                                width: '100px'
                            },
                            input: {
                                minHeight: "25px",
                                height: "30px",
                                width: '100px'
                            }
                        }}
                        name={valueInputName}
                        type={type.toLowerCase()}
                        value={item[ props.valueProperty as keyof Api.SystemLookupType ] as string || ""}
                        onChange={e => {
                            props.onInputChange(props.valueProperty, e.target.value, item, index);
                        }}
                        classNames={{
                            wrapper: 'LY_StaticSelect_Options_item_advancedInput_wrapper',
                            input: 'LY_StaticSelect_Options_item_advancedInput'
                        }}
                        placeholder={valPlaceholder}
                        error={state.errors.get(valueInputName)}
                    />
                )}
                {getItemMenu(item)}
            </SortableItem>
        );
    }

    function renderOptions() {
        return (
            <Popover
                width="auto"
                position="bottom"
                withArrow
            >
                <div className="LY_StaticSelect_Options_wrapper LY_ScrollbarDropDown" ref={wrapperRef}>
                    <LIB.Loading show={state.vm.isActionInProgress} isModal disablePortal />
                    <SortableContext
                        items={state.items.map(item => item.id!)}
                        strategy={verticalListSortingStrategy}
                    >
                        {state.items.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
                            .map((item, index) => getRenderOptionItem(index, item))
                        }
                    </SortableContext>
                </div>
            </Popover>
        );
    }

    function advancedFilterRenderer() {
        return (
            <div className="LY_StaticSelect_Options_add_option">
                <Button
                    size="xs"
                    onClick={addOptionAndScroll}
                    classNames={{
                        root: 'LY_StaticSelect_Options_add_option_button',
                        inner: 'LY_StaticSelect_Options_add_option_button_inner',
                    }}
                    styles={{
                        section: { marginRight: 5 }
                    }}
                    variant='outline'
                    leftSection={<Icons.IconPlus stroke={1} size={20} />}
                >
                    Add
                </Button>


               {/*  {props.valueProperty !== 'id' && <Switch
                    label="Advanced Settings"
                    labelPosition='left'
                    onClick={(e) => {
                        state.isShowingAdvancedSettings = e.currentTarget.checked;
                        context.forceUpdate();
                    }}
                    checked={state.isShowingAdvancedSettings}
                    classNames={{
                        trackLabel: 'LY_StaticSelect_Options_switch_track_label',
                        body: 'LY_StaticSelect_Options_switch_body'
                    }}
                />} */}

            </div>
        );
    }

    function deleteConfirmationModalRenderer() {
        return (
            <LY.ConfirmModal
                visible={Boolean(deletedItem?.id)}
                onCancelClick={() => setDeletedItem(null)}
                onConfirmClick={handleDeleteOption}
                title={<div>Confirm Delete: <b>{deletedItem?.name}</b></div>}
                content={
                    <div>Are you sure you want to delete the <b>{deletedItem?.name}</b> Item?
                        <MT.Alert
                            variant="light"
                            color="red"
                            icon={<Icons.IconAlertTriangle />}
                            style={{ marginTop: 10, padding: 8 }}
                        >
                            There is NO Rollback for this action!
                        </MT.Alert>
                    </div>
                }
                confirmButtonLabel="Delete"
            />
        );
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const fromIndex = state.items.findIndex(item => item.id === active.id);
            const toIndex = state.items.findIndex(item => item.id === over?.id);
            context.moveItems(fromIndex, toIndex);
        }
    }

    return (
        <DndContext onDragEnd={handleDragEnd}>
            {searchInputRenderer()}
            {renderOptions()}
            {advancedFilterRenderer()}
            {deleteConfirmationModalRenderer()}
            {tooltipModal()}
        </DndContext>
    );
};

export default LY_StaticDropDown_Edit_Options;
