import './LY_UsersPopup.css'

import * as Api from "app-api";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB"
import * as LY from '_LY_Components';
import * as App from 'AppReferences';

import { useState } from 'react';
import { TextInput, Center, ActionIcon } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';


interface LY_UsersPopupProps {
  columns?: Api.ListColumn[]
  onCloseUsersPopup: () => void;
  onClickUser?: (user: string) => void;
  displayValue: keyof Api.ListColumn;
}

export const LY_UsersPopup: React.FC<LY_UsersPopupProps> = ({ onClickUser, onCloseUsersPopup, columns, displayValue }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems = columns ? columns.filter((item) =>
    (item[displayValue] as string).toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  const handleUserClick = (user: string) => {
    if(onClickUser)
        onClickUser(user);
      
    onCloseUsersPopup()
  }

  function render() {
    return <div className='LY_UsersPopup' style={{ width: '100%', backgroundColor: '#fff', border: '1px solid #4DABF7' }}>
      <div className='LY_UsersPopup_Header' >
        <ActionIcon onClick={onCloseUsersPopup} size={25} variant="light" color="blue">
          <IconArrowLeft size={25} />
        </ActionIcon>
        <TextInput
          placeholder="Search"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          styles={{ root: { width: '100%', borderBottom: '1px solid #E9ECEF', margin: '0 5px' }, input: { all: 'unset', marginLeft: '5px', }, }}
        />
      </div>
      <div className='LY_UsersPopup_ScrollArea' style={{ marginTop: '5px' }}>
        {filteredItems.length > 0 ? (
          filteredItems.map((item, index) => (
            <div onClick={() => handleUserClick(item.name)} className='LY_UsersPopup_Option' key={index} style={{ padding: '10px' }} >
              {item[displayValue] as string}
            </div>
          ))
        ) : (
          <Center style={{ padding: '10px' }}>Nothing found</Center>
        )}
      </div>
    </div>
  }

  return render()
}
