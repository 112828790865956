import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

export class LY_StaticDropDownState extends LIB.BaseVmModel {

    public isEditMode: boolean = false;
    public selectedItems?: Api.SystemLookupType[];
    public model?: LY.DataItemModel = new LY.DataItemModel();
    public items: Api.SystemLookupType[] = [];
    public vm: LIB.BaseVmModel = new LIB.BaseVmModel()
    public updatableItems: Api.SystemLookupType[] = [];
    public newItems: Api.SystemLookupType[] = [];
    public isShowingAdvancedSettings = false;
    public isSelectStaticPopoverOpen = false;
    public allowMultipleSelection? = false;
    public previewItem?: Api.SystemLookupType;
    public chagnedItems: Api.SystemLookupType[] = [];
    public readOnly?: boolean = false;
    public disableEditOptions?: boolean = false;

    public valueProperty: keyof Api.SystemLookupType = 'id';

    public selectedEditItem: Api.SystemLookupType;

    public isFullWidthStyle: boolean = false;
    

    constructor() {
        super();
    }
}
