import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_DLA_LabelMakerModule_Form.css";


import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';

export const LY_DLA_LabelMakerModule_Form: React.FC<LY.LY_DLA_LabelMakerModuleProps> = (props) => {

  const context = LY.useDLALabelMakerModuleContext();
  const { state, services } = context;
  var item = state.model;

  const { isMobile } = useIsMobile(768);

  function onInputChange(e: React.ChangeEvent<any>) {
    if (!item) return;

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    //@ts-ignore
    item[ key ] = value;


    if(key === 'quantity' || key === 'qup'){
      context.updateTotalCount();
    }

    validate(key);

    context.forceUpdate();

  }


  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all

    state.errors.clear();

    if ((vAll || key == 'rfq') && LIB.Common.isNullOrEmpty(item.rfq)) {
      state.errors.set('rfq', `RFQ is required!`);
    }

    if ((vAll || key == 'nsn') && LIB.Common.isNullOrEmpty(item.nsn)) {
      state.errors.set('nsn', `NSN is required!`);
    }

    if ((vAll || key == 'part_number') && LIB.Common.isNullOrEmpty(item.part_number)) {
      state.errors.set('part_number', `Part number is required!`);
    }

    if ((vAll || key == 'companyCage') && LIB.Common.isNullOrEmpty(item.companyCage)) {
      state.errors.set('companyCage', `Company CAGE is required!`);
    }

    if ((vAll || key == 'mfgCage') && LIB.Common.isNullOrEmpty(item.mfgCage)) {
      state.errors.set('mfgCage', `MFG CAGE is required!`);
    }

    if ((vAll || key == 'quantity') && LIB.Common.isNullOrEmpty(item.quantity)) {
      state.errors.set('quantity', `Quantity is required!`);
    }

    if ((vAll || key == 'qup') && LIB.Common.isNullOrEmpty(item.qup)) {
      state.errors.set('qup', `QUP is required!`);
    }

    if ((vAll || key == 'unit_of_issue') && LIB.Common.isNullOrEmpty(item.unit_of_issue)) {
      state.errors.set('unit_of_issue', `Unit of issue is required!`);
    }

    if ((vAll || key == 'date') && LIB.Common.isNullOrEmpty(item.date)) {
      state.errors.set('date', `Date is required!`);
    }


    var isValid = (state.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }


  function render() {
    return <div
      className='LY_DLA_LabelMakerModule_Form_Container'
    >
      <div>Size: {item?.size?.name}</div>
      <div className='LY_DLA_LabelMakerModule_Form_Inputs'>
        <div className='LY_DLA_LabelMakerModule_Form_Row'>
          <LY.LY_Input
            name="rfq"
            value={item?.rfq}
            type='text'
            label="RFQ"
            placeholder="RFQ"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('rfq')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

          <LY.LY_Input
            name="nsn"
            value={item?.nsn}
            type='text'
            label="NSN"
            placeholder="NSN"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('nsn')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

          <LY.LY_Input
            name="date"
            value={item?.date}
            type='text'
            label="Date"
            placeholder="Date"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('date')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />
        </div>


        <div className='LY_DLA_LabelMakerModule_Form_Row'>
          <LY.LY_Input
            name="companyCage"
            value={item?.companyCage}
            type='text'
            label="Your CAGE"
            placeholder="Your CAGE"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('companyCage')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

          <LY.LY_Input
            name="mfgCage"
            value={item?.mfgCage}
            type='text'
            label="MFG CAGE"
            placeholder="MFG CAGE"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('mfgCage')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />
          <LY.LY_Input
            style={{ flex: 1 }}
            name="part_number"
            value={item?.part_number}
            type='text'
            label="Part Number"
            placeholder="Part Number"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('part_number')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

        </div>

        <div className='LY_DLA_LabelMakerModule_Form_Row'>

          <LY.LY_Input
            name="qup"
            value={item?.qup}
            type='text'
            label="QUP"
            placeholder="QUP"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('qup')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />
          <LY.LY_Input
            name="quantity"
            value={item?.quantity}
            type='text'
            label="Quantity"
            placeholder="Quantity"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('quantity')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

          <LY.LY_Input
            name="unit_of_issue"
            value={item?.unit_of_issue}
            type='text'
            label="Unit of Issue"
            placeholder="Unit of Issue"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('unit_of_issue')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />
        </div>


        <div className="LY_DLA_LabelMakerModule_Form_Row"
        style={{ justifyContent: 'right' }}
      >
{/*       <span>Total Label Count:</span> */}
      <LY.LY_Input
            name="totalCount"
            value={item?.totalCount}
            type='text'
            label="Total Label Count"
            placeholder="Total Count"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('totalCount')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

      </div>

      </div>



    </div>



  }

  return render();

};
