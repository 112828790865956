import "./UserAvatar.css";

import { Avatar, AvatarProps, Tooltip } from "@mantine/core";

import React from "react";

interface UserAvatarProps extends AvatarProps {
  name?: string;
  isMe?: boolean;
  isAuthor?: boolean;
  showName?: boolean;
  showTooltip?: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  name,
  isMe,
  isAuthor,
  showName = true,
  showTooltip,
  ...props
}) => {
  return (
    <div className="LY_UserAvatar_Container">
      <Tooltip withArrow disabled={!showTooltip} label={name}>
        <Avatar
          {...props}
          // className="LY_UserAvatar_Avatar"

          size={"sm"}
          // alt={name}
        />
      </Tooltip>
      {showName && <span>{name}</span>}
      {isMe && <span className="LY_UserAvatar_User_Badge">You</span>}
      {isAuthor && <div className="LY_UserAvatar_Author_Badge">Author</div>}
    </div>
  );
};

export default UserAvatar;
