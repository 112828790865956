
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';

enum BiddingLists {
    biddingBoardId = 'a804fd32-e676-49b4-9506-7092794e189c',
    supplierQuotesId = '9303b86d-796c-425b-ae39-d2466018b118',
    productDetailsId = 'c9928eac-180a-463f-9776-78abd53c38d8',
    serviceDetailsId = 'cf8bf117-a296-4e47-8063-fa7f87c41d2f',
}

enum Reports {
    supplierQuotesBulkEmailReportId = '394edf47-06a9-471b-8f94-cfb8d20b74f0',
 
}

export class LYTemplates {



    public static workspacesBiddingId = 128;
    public static workspacesDibbsId = 10;
 
    public static BiddingLists = BiddingLists;
    public static Reports = Reports;

    public static getPageName(pageName:string, model?:LY.DataItemModel):string {

        if(!model || LIB.Common.isNullOrEmpty(pageName))
          return pageName;

          var result = pageName;
          // check if Bidding Board Detail Page
        if(pageName=='item' && LYTemplates.isBiddingBoardList(model?.list)){
            result = 'dla/bidding/item';
       
        }
    
        
  /*       if(model?.rowId=='9b80590b-dc91-42bc-8b6f-febd236102cb'){
            console.log('LYTemplates getPageName model:',model);
            console.log('LYTemplates getPageName pageName:',pageName);
            console.log('LYTemplates getPageName result:',result);

          } */
    
        return result;
    
      }
    

    public static isBiddingWorkspace(workspace:Api.Workspace):boolean{
        return LYTemplates.isTemplateWorkspace(workspace, LYTemplates.workspacesBiddingId);
    }

    public static isBiddingBoardList(list?:Api.List):boolean{
        return LYTemplates.isTemplateList(list, BiddingLists.biddingBoardId);
    }

    
    public static isTemplateList(list?:Api.List, tplListId?:string):boolean{
        if(!list || LIB.Common.isNullOrEmpty(tplListId))
            return false;


        var listId = BiddingLists.biddingBoardId;
        if(list?.baseTemplateId == tplListId || list?.listId==tplListId)
            return true; 
        return false;
    }

    public static isTemplateListRel(list?:Api.ListGetRelatedDataSm, tplListId?:string):boolean{
        if(!list || LIB.Common.isNullOrEmpty(tplListId))
            return false;


        var listId = BiddingLists.biddingBoardId;
        if(list?.baseTemplateId == tplListId || list?.listId==tplListId)
            return true; 
        return false;
    }

    public static isTemplateWorkspace(workspace:Api.Workspace, tplWorkspaceId?:number):boolean{
        if(tplWorkspaceId==undefined || !workspace)
            return false;

        if(workspace?.baseTemplateId == tplWorkspaceId || workspace?.workspaceId==tplWorkspaceId)
            return true;
        return false;
    }

}