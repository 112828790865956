import * as LIB from '_LIB';
 

export class ClipboardHelper {

  
 
    public static copyToClipBoard(html:string) {

    
      if (!html)
        return; 
    
        const blob = new Blob([html], { type: 'text/html' });
        const data = [new ClipboardItem({ 'text/html': blob })];
    
        navigator.clipboard.write(data).then(() => {
        //   alert('Content copied to clipboard!');
        }).catch(err => {
            console.error('copyToClipBoard error:', err);
        });
    
      }


}