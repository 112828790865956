import {
  AIHighlight,
  CharacterCount,
  CodeBlockLowlight,
  Color,
  CustomKeymap,
  GlobalDragHandle,
  HighlightExtension,
  HorizontalRule,
  MarkdownExtension,
  Placeholder,
  StarterKit,
  TaskItem,
  TaskList,
  TextStyle,
  TiptapImage,
  TiptapLink,
  TiptapUnderline,
  Twitter,
  UpdatedImage,
  Youtube,
  Mathematics,
} from "novel/extensions";
import { Markdown } from "tiptap-markdown";

import { UploadImagesPlugin } from "novel/plugins";
import { common, createLowlight } from "lowlight";

import './extentions.css'

const aiHighlight = AIHighlight;
const placeholder = Placeholder;

const tiptapLink = TiptapLink.configure({
  HTMLAttributes: {
    class: "tiptap-link",
  },
});

const tiptapImage = TiptapImage.extend({
  addProseMirrorPlugins() {
    return [
      UploadImagesPlugin({
        imageClass: "tiptap-image-plugin",
      }),
    ];
  },
}).configure({
  allowBase64: true,
  HTMLAttributes: {
    class: "tiptap-image",
  },
});

const updatedImage = UpdatedImage.configure({
  HTMLAttributes: {
    class: "updated-image",
  },
});

const taskList = TaskList.configure({
  HTMLAttributes: {
    class: "task-list",
  },
});

const taskItem = TaskItem.configure({
  HTMLAttributes: {
    class: "task-item",
  },
  nested: true,
});

const horizontalRule = HorizontalRule.configure({
  HTMLAttributes: {
    class: "horizontal-rule",
  },
});

const starterKit = StarterKit.configure({
  bulletList: {
    HTMLAttributes: {
      class: "bullet-list",
    },
  },
  orderedList: {
    HTMLAttributes: {
      class: "ordered-list",
    },
  },
  listItem: {
    HTMLAttributes: {
      class: "list-item",
    },
  },
  blockquote: {
    HTMLAttributes: {
      class: "blockquote",
    },
  },
  codeBlock: {
    HTMLAttributes: {
      class: "code-block",
    },
  },
  code: {
    HTMLAttributes: {
      class: "inline-code",
      spellcheck: "false",
    },
  },
  horizontalRule: false,
  dropcursor: {
    color: "#DBEAFE",
    width: 4,
  },
  gapcursor: false,
});

const codeBlockLowlight = CodeBlockLowlight.configure({
  lowlight: createLowlight(common),
});

const youtube = Youtube.configure({
  HTMLAttributes: {
    class: "youtube",
  },
  inline: false,
});

const twitter = Twitter.configure({
  HTMLAttributes: {
    class: "twitter",
  },
  inline: false,
});

const mathematics = Mathematics.configure({
  HTMLAttributes: {
    class: "mathematics",
  },
  katexOptions: {
    throwOnError: false,
  },
});

const markdown = Markdown.configure({
  html: false,
  transformCopiedText: true,
  transformPastedText: true,
})

const characterCount = CharacterCount.configure();

export const defaultExtensions = [
  starterKit,
  placeholder,
  tiptapLink,
  tiptapImage,
  updatedImage,
  taskList,
  taskItem,
  horizontalRule,
  aiHighlight,
  codeBlockLowlight,
  youtube,
  twitter,
  mathematics,
  characterCount,
  markdown,
  TiptapUnderline,
  MarkdownExtension,
  HighlightExtension,
  TextStyle,
  Color,
  CustomKeymap,
  GlobalDragHandle,
];
 