import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class GlobalWorkspaceReducers {


    constructor() {

    }


    public static getReducers(state: App.GlobalState | undefined, action: App.AppAction): App.GlobalState {
        var str = "";

        var result = new App.GlobalState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("GLOBAL_WORKSPACE"))
            return state;

             

        var appState = action.appState;

        console.log('GlobalReducers.getActionHandlersReducers');

        let selectedWorkspace= state.workspaceVm?.selectedWorkspace;

        var workspaceService = new App.WorkspaceService(selectedWorkspace?.workspaceId);


        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.GLOBAL_WORKSPACE_API_ERROR:{
                console.log('GLOBAL_WORKSPACE_API_ERROR action.payload:',action);
                result = { ...state };
                let vm = result.workspaceVm;

                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
 
                vm.isActionInProgress = false;
                result.forceUpdateCount++;

                break;
            }
            case App.ActionTypes.GLOBAL_WORKSPACE_LIST_CHANGED: {
                console.log('GLOBAL_WORKSPACE_LIST_CHANGED:', action.payload);
                result = { ...state };
                var data = action.payload as App.WorkspaceVm;
                //result.workspaceVm.workspaces = data;
 
                //console.log('GLOBAL_WORKSPACE_LIST_CHANGED new list:', result.workspaceVm.workspaces);

                result.forceUpdateCount++;
                //return result;
                break;
            }
            case App.ActionTypes.GLOBAL_WORKSPACE_MODAL_CHANGE: {
                    console.log('GLOBAL_WORKSPACE_MODAL_CHANGE:', action.payload);
    
                    result = { ...state };
                    let data = action.payload

                    let vm = result.workspaceVm
                    let selectedListId = data.selectedListId;
                    let list = data.list;
                    let listWorkspaceId = list?.workspaceId;

                    vm.apiErrorMessage = '';
                    vm.isItemDeleteOpen = data.isItemDeleteOpen;

                   // let selectedItem = result.initialData.
                 //  console.log('GLOBAL_WORKSPACE_MODAL_CHANGE workspaceVm', vm);
                 //  console.log('GLOBAL_WORKSPACE_MODAL_CHANGE selectedListId', selectedListId);
                 //  console.log('GLOBAL_WORKSPACE_MODAL_CHANGE listWorkspaceId', listWorkspaceId);

                    if(selectedListId && listWorkspaceId){
                     //   console.log('GLOBAL_WORKSPACE_MODAL_CHANGE selectedListId 1', data.selectedListId);

                        if(vm.selectedWorkspace.workspaceId==listWorkspaceId)
                            vm.selectedItem = vm.selectedWorkspace?.workspaceItems?.find(x => x.listId == data.selectedListId);
                        else{
                            let listWorkspace = vm.allWorkspaces.find(x => x.workspaceId == listWorkspaceId);

                          //  console.log('GLOBAL_WORKSPACE_MODAL_CHANGE listWorkspace', listWorkspace);


                            vm.selectedItem = listWorkspace?.workspaceItems?.find(x => x.listId == data.selectedListId);
                        }
 
                       // console.log('GLOBAL_WORKSPACE_MODAL_CHANGE selectedListId 2', vm.selectedItem);

                    }
                    else if(data.selectedItem!=vm.selectedItem){
                        console.log('GLOBAL_WORKSPACE_MODAL_CHANGE selectedItem change');
                        vm.selectedItem = data.selectedItem;
                    }
                  
                    result.forceUpdateCount++;
     
                    break;
                }
                case App.ActionTypes.GLOBAL_WORKSPACE_CHANGE_REQUEST: {
                    console.log('GLOBAL_WORKSPACE_CHANGE_REQUEST:', action.payload);
                    result =  { ...state };
                    result.apiErrorMessage='';
                   
                    let sm = action.payload;
     
                    workspaceService.getWorkspaceWithItems(sm.workspaceId);
                    result.isServiceCallPending = true;
                    result.forceUpdateCount++;
    
                     break;
                }   
                case App.ActionTypes.GLOBAL_WORKSPACE_CHANGE_SUCCESS  : {
                    console.log('GLOBAL_WORKSPACE_CHANGE_SUCCESS  :', action.payload);
                    result = { ...state }; 
    
                    let data = action.payload;
    
                    result.workspaceVm.selectedWorkspace = data;
                    let workspaceVm = result.workspaceVm;

                    let selectedWorkspace = workspaceVm.selectedWorkspace;

                     var items = selectedWorkspace?.workspaceItems;
                     var firstItem = items?.[0];
                   /*   var foundSelectedItem = items?.find(x => x.defaultViewId == selectedWorkspace?.selectedViewId); 
                
                    if(foundSelectedItem)
                        workspaceVm.selectedItem = foundSelectedItem; */

                    workspaceVm.hasWorkspacesDataLoaded= true;
                    console.log('GLOBAL_WORKSPACE_CHANGE_SUCCESS workspaceVm:',workspaceVm);
                    console.log('GLOBAL_WORKSPACE_CHANGE_SUCCESS selectedItem:',workspaceVm.selectedItem);

                    console.log('GLOBAL_WORKSPACE_CHANGE_SUCCESS selectedWorkspace:',workspaceVm.selectedWorkspace);
                    console.log('GLOBAL_WORKSPACE_CHANGE_SUCCESS availableConnections:',result.availableConnections);

                    let linkedWorkspaces =  workspaceVm.selectedWorkspace.linkedWorkspaces;

                    console.log('GLOBAL_WORKSPACE_CHANGE_SUCCESS  linkedWorkspaces',linkedWorkspaces);
         
                    console.log('GLOBAL_WORKSPACE_CHANGE_SUCCESS firstItem:',firstItem);

                    //todo something better - set default view on workspace and call that
           /*          if(firstItem?.defaultViewId)
                        App.RouteManager.redirectToList(firstItem?.defaultViewId, selectedWorkspace?.workspaceId);
 */
                    result.isServiceCallPending = false;
                    result.forceUpdateCount++;
                    break;
                }

                 
           
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}