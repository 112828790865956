import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';



import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge, ScrollArea } from '@mantine/core';
import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


import { useDisclosure } from '@mantine/hooks';

import './_CopyToBiddingBoardScreen.css';
 

interface CopyToBiddingBoardScreenProps {
  records: any[];
  listId: string;
  onClose?: () => void;
  onCopySuccess?: () => void;
  vm:LY.SelectedItemActionsVm;
  linkedWorkspaceId:number | undefined;
  //refreshData: () => void;

}

export const CopyToBiddingBoardScreen: React.FC<CopyToBiddingBoardScreenProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();

 
  const [ vm, setVm ] = React.useState<LIB.BaseVmModel>(new LIB.BaseVmModel());
  const [ open, setOpen ] = React.useState(props.vm.isCopyToBiddingBoardOpen);

  const currentWorkspace = App.AppBase.currentWorkspace;

  const [ sm, setSm ] = React.useState<Api.CopyToBiddingBoardSm>();

  useEffect(() => {
    console.log('CopyToBiddingBoardScreen useEffect props.vm', props.vm);
    console.log('CopyToBiddingBoardScreen useEffect props.records', props.records);

    if(!props.records || props.records.length==0){
      onOpenChange(false);
      return
    }
  

    // vm.isActionInProgress=true;
    setOpen(props.vm.isCopyToBiddingBoardOpen);

    var newSm = new Api.CopyToBiddingBoardSm();
    newSm.copyApprovedPartCompaniesToSuppliers = true;
    newSm.copyNSNToProducts = true;
    newSm.copyRFQToBiddingBoard = true;
    newSm.items = props.records.map(x => x.id);
    setSm(newSm);
 
  }, [ props.vm.isCopyToBiddingBoardOpen, props.records ]);



  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

     console.log('CopyToBiddingBoardScreen onInputChange key:', key);
    // console.log('CopyToBiddingBoardScreen onInputChange newWorkspace:', newWorkspace);

  //  onValueChange(key, value);

  }
/* 
  function onValueChange(key: string, value: any) {


     console.log('CopyToBiddingBoardScreen onValueChange key:', key);
     console.log('CopyToBiddingBoardScreen onValueChange value:', value);
 
    newColumn[ key ] = value;
 
    validate(key);
 
    forceUpdate();


  } */
 


  function onSubmit(e: React.FormEvent<HTMLFormElement>) {

    e.preventDefault();

    console.log('CopyToBiddingBoardScreen onSubmit:');
  
/*     var isValid = true;//validate('all');
    if (!isValid) {
      console.log('CopyToBiddingBoardScreen onSubmit isValid:', isValid);
      forceUpdate();
      return false;
    } */

    if(sm==undefined)
      return;
  
    console.log('CopyToBiddingBoardScreen sm send:', sm);
    console.log('CopyToBiddingBoardScreen sm linkedWorkspaceId:', props.linkedWorkspaceId);

    //   return;

    var service = new App.DLADataService(currentWorkspace.workspaceId, props.linkedWorkspaceId);

    service.copyToBiddingBoard(sm,
      //onSuccess
      (result: any) => {
        console.log('CopyToBiddingBoardScreen onSuccess:', result);

        var successMsg = `Copied, Success: ${result.successCount}, Duplicate: ${result.duplicateCount}`;
        if(!result.errorCount)
          App.AppBase.showMessage(successMsg);
        else 
          App.AppBase.showMessage(`${successMsg}, Errors: ${result.errorCount}`);

        vm.isActionInProgress = false;
        onOpenChange(false);

        if(props.onCopySuccess){
          props.onCopySuccess();
        }
        

      },
      //onError
      (error: any) => {

        console.error('CopyToBiddingBoardScreen onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        vm.errors.set('all', errorMessage);
        forceUpdate();
      });

 
    vm.isActionInProgress = true;
    forceUpdate();
 


  }
 

 

  function getForm() {
    var item = props.records;

    var globalError = vm.errors.get('all');


    var form = (
      <form
        onSubmit={onSubmit}
        className='CopyToBiddingBoardScreenForm'
        ref={focusTrapRef}>

        {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}

        <div className='CopyToBiddingBoardScreenFormScrollArea' >

        
            <LY.LY_CheckBox
              name="copyRFQToBiddingBoard"
              checked={sm?.copyRFQToBiddingBoard}
              label="Copy RFQ Details to Bidding Board"
              labelPlacement='right'
              // withStar={true}
              error={vm.errors.get('copyRFQToBiddingBoard')}

             // helpText="Force unique values in this column."
             // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
              disabled={true}
            />
            <LY.LY_CheckBox
              name="copyNSNToProducts"
              checked={sm?.copyNSNToProducts}
              label="Copy Parts/NSNs To Products"
              labelPlacement='right'
              // withStar={true}
              error={vm.errors.get('copyNSNToProducts')}

              // helpText="Force unique values in this column."
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
   
            />

        <LY.LY_CheckBox
              name="copyApprovedPartCompaniesToSuppliers"
              checked={sm?.copyApprovedPartCompaniesToSuppliers}
              label="Copy Approved Source Companies to Supplier List"
              labelPlacement='right'
              // withStar={true}
              error={vm.errors.get('copyApprovedPartCompaniesToSuppliers')}

              // helpText="Force unique values in this column."
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
   
            />
 
         
        </div>
 
        <Group mt="xl" justify='right'>
          <MT.Button variant='default' onClick={() => onOpenChange(false)} >Cancel</MT.Button>
          <MT.Button type='submit'
            loading={vm.isActionInProgress} loaderProps={{ type: 'dots' }}
          >
            Copy
          </MT.Button>
        </Group>

      </form>
    );

    return form;

  }





  function onOpenChange(open: boolean) {
 
    props.vm.isCopyToBiddingBoardOpen = open;
    vm.errors.clear();
    setOpen(open);
    forceUpdate();

    if(!open && props.onClose){
      props.onClose();
    }
  }


  function render() {
    //console.log('CopyToBiddingBoardScreen render');

    var selectedItems = props.records;

    if(!selectedItems || selectedItems.length==0 || !open)
        return null;

   console.log('CopyToBiddingBoardScreen render selectedItems:', selectedItems);
   console.log('CopyToBiddingBoardScreen render open:', open);


    return (
      <>
        <LY.LY_Modal
          id='CopyToBiddingBoardScreenModal'
          opened={open}
          onClose={() => onOpenChange(false)}
          title={`Copy: ${selectedItems?.length} Selected Items To Bidding Board`}
          //scrollAreaComponent={ScrollArea.Autosize}
          centered
          overlayProps={{
            backgroundOpacity: 0.1,
            blur: 0,
          }}
          transitionProps={{ duration: 0, timingFunction: 'linear' }}
          className={'LY_Global_Modals'}
          size={'lg'}
          styles={{
            root: {
                zIndex: 888 
            } 
        }}
        >

          {getForm()}

        </LY.LY_Modal>

      </>
    );
  }

  return render();
};

