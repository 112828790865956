import "./LY_ListFilterModule_Search.css";

import * as Icons from "@tabler/icons-react";
import * as LY from '_LY_Components';

import { Combobox, Paper, Popover, Switch, TextInput, useCombobox } from "@mantine/core";
import React, { useRef, useState } from "react";

import { LY_FiltersModule_Filter_Footer } from "../Components/LY_ListFilterModule_Filter_Footer/LY_ListFilterModule_Filter_Footer";
import { LY_FiltersModule_Filter_Header } from "../Components/LY_ListFilterModule_Filter_Header/LY_ListFilterModule_Filter_Header";
import useIsMobile from "_LIB/Components/Hooks/UseIsMobile";
import { useClickOutside } from "@mantine/hooks";

export const LY_ListFilterModule_Search: React.FC = () => {
 
  const currentSearch = useRef('')
 

  const context = LY.useListFilterModuleContext();
  
  const { columns, view } = context.state;

  const [ search, setSearch ] = useState("");


  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
    },
  });


  const { isMobile } = useIsMobile(768);
  const [ isSearchActive, setIsSearchActive ] = useState(false);
  const ref = useClickOutside(() => setIsSearchActive(false));


  function renderMobile() {

  }


  function getSeachColumnsComboBox() {


    return <Combobox
      classNames={{ dropdown: "LY_ListFilterModule_Search_comboboxDropdown" }}
      store={combobox}
      width={380}
      withinPortal={true}
      withArrow={true}
      arrowSize={10}
    >

      <Combobox.Target withAriaAttributes={false}>
        <Icons.IconAdjustmentsHorizontal
          stroke={1.5}
          color="#757B87"
          className="LY_ListFilterModule_Search_adjustmentsIcon"
          onClick={() => combobox.toggleDropdown()}
        />
      </Combobox.Target>

      <Combobox.Dropdown className="LY_ListFilterModule_Search_comboboxDropdown">
        <LY_FiltersModule_Filter_Header
          fieldName="Choose column to search"
          selectedItemCount={view?.filter?.searchColumns?.length}
        />


        <TextInput
          leftSection={
            <Icons.IconSearch className="LY_ListFilterModule_Filter_Searchbar_SearchIcon" />
          }
          classNames={{
            root: "LY_ListFilterModule_Filter_Header_Searchbar_Root",
            wrapper: "LY_ListFilterModule_Filter_Header_Searchbar_Wrapper",
            input: "LY_ListFilterModule_Filter_Header_Searchbar_Input",
          }}
          onChange={(e) => setSearch(e.currentTarget.value || "")}
          placeholder="Search"
          value={search}
          variant="filled"
        />


        <Combobox.Options
          classNames={{ options: "LY_ListFilterModule_Columns_options" }}
        >
          {columns?.length ? (

            columns.filter(x => x.displayName?.toLowerCase().includes(search?.toLowerCase())).map((item, id) => (
              <Combobox.Option
                value={item.displayName}
                key={id}
                className="LY_ListFilterModule_Columns_optionItem"
              >
                <Switch
                  classNames={{
                    root: "LY_ListFilterModule_Columns_optionItem_root",
                    label: "LY_ListFilterModule_Columns_optionItem_label",
                    labelWrapper:
                      "LY_ListFilterModule_Columns_optionItem_labelWrapper",
                  }}
                  label={item.displayName}
                  checked={
                    view?.filter?.searchColumns?.includes(item.name) ||
                    view?.filter?.searchColumns?.length === 0
                  }
                  onChange={() => context.onSelectSearchColumns(item.name)}
                />
              </Combobox.Option>
            ))
          ) : (
            <Combobox.Empty className="LY_ListFilterModule_Search_emptyOption">
              Nothing found
            </Combobox.Empty>
          )}
        </Combobox.Options>
        <LY_FiltersModule_Filter_Footer
          leftAction={() => context.onRemoveAllColumnsSearch()}
          leftActionName="Remove all"
          rightAction={() => context.onSelectAllColumnsSearch()}
          rightActionName="Select all"
        />
      </Combobox.Dropdown>
    </Combobox>
  }


  function onSearchApply() {

    currentSearch.current = view.filter?.searchKeyword || '';
    setIsSearchActive(false)
    context.onSearch();
  }


  function render() {



    return (
      <div
        className={`LY_ListFilterModule_Search  ${isMobile && isSearchActive ? 'LY_ListFilterModule_Search_active_Mobile' : ''}`}
      >
        {isMobile && !isSearchActive ? <>
          <Icons.IconSearch
            width={36}
            height={36}
            color="#757B87"
            onClick={() => setIsSearchActive(true)}
            className={`LY_ListFilterModule_Filter_Searchbar_SearchIcon_Mobile`}
          />

        </>
          :
          <TextInput
           
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                onSearchApply();
              }
            }}

            rightSection={
              <Icons.IconSearch
                onClick={() => onSearchApply()}

                className="LY_ListFilterModule_Filter_Searchbar_SearchIcon"
              />
            }

            classNames={{
              section: 'LY_ListFilterModule_Filter_Searchbar_Icon',
              root: "LY_ListFilterModule_Filter_Searchbar_Root",
              wrapper: "LY_ListFilterModule_Filter_Header_Wrapper",
              input: "LY_ListFilterModule_Filter_Header_Input",
            }} 
            onChange={(e) => context.setSearch(e.currentTarget.value || "")}
            value={view.filter?.searchKeyword || ""}
            placeholder="Search"
            variant="filled"

          />}

        {getSeachColumnsComboBox()}

      </div>
    );
  }

  return render();
};
