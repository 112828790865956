import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class ListDataService extends App.BaseAppService {


    private linkedListDataService: App.LinkedListDataService | undefined = undefined

    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
        this.linkedListDataService = new App.LinkedListDataService(workspaceId);
    }


    public async getRecords(sm: Api.ListGetDataSm, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm || sm.viewId == undefined || sm.viewId < 1 || isNaN(sm.viewId)) {
            console.log('ListDataService.getRecords sm.viewId is required', sm);
            return;
        }

        if (sm.linkedWorkspaceId && sm.linkedWorkspaceId > 0 && this.linkedListDataService) {
            return this.linkedListDataService.getRecords(sm, onSuccess, onError);
        }

        console.log("ListDataService.getRecords sm: ", sm);

      /*   if (sm.filter) {

            if (!sm.filter.conditionSets)
                sm.filter.conditionSets = [];

            if (sm.filter?.conditionSet) {
                sm.filter.conditionSets.push(sm.filter.conditionSet);
            }

        } */
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListData/getRecords`;


        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListDataService.getRecords onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });



    }



    public insertItems(sm: Api.ListInsertDataSm) {

        console.log("ListDataService.insertItems sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListData/insertItems`;
        this.post(apiUrl, sm,
            App.ActionTypes.LIST_DATA_ITEM_ADD_SUCCESS,
            App.ActionTypes.LIST_API_ERROR,
            //onAfterSuccess
            /*   ()=>{
                  App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);
  
              } */
        );

    }


    public async insertItem(sm: Api.ListInsertDataSm, onSuccess: Function, onError: Function | undefined = undefined) {


        console.log("ListDataService.insertItem sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListData/insertItems`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListDataService.insertItem onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });



    }

    public async updateValues(sm: Api.ListUpdateDataSm, onSuccess: Function, onError: Function | undefined = undefined) {


        console.log("ListDataService.updateValues sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListData/updateValues`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListDataService.updateValues onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'patch'
        });



    }

    public async deleteItems(sm: Api.ListDeleteDataSm, onSuccess: Function, onError: Function | undefined = undefined) {


        console.log("ListDataService.deleteItems sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListData/deleteItems`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListDataService.deleteItems onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'delete'
        });



    }


    public async copyItems(sm: Api.ListDataCopyItemsSm, onSuccess: Function, onError: Function | undefined = undefined) {


        console.log("ListDataService.copyItems sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListData/copyItems`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListDataService.copyItems onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });



    }
}
