import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import * as Icons from '@mui/icons-material/';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as App from 'AppReferences';


//import { TreeItem } from 'react-complex-tree';

interface WorkspaceItemContextMenuProps {
    vm: App.WorkspaceVm;
}

const WorkspaceItemContextMenu: React.FC<WorkspaceItemContextMenuProps> = (props) => {

    var vm = props.vm;
    var item = vm.selectedItem;

    const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(document.body);
    const forceUpdate = LIB.useForceUpdate();

    React.useEffect(() => {

        console.log('WorkspaceItemContextMenu  useEffect selectedItem', vm.selectedItem);
        console.log('WorkspaceItemContextMenu  useEffect selectedItemElement', vm.selectedItemElement);

        if (vm.selectedItem && anchorEl != vm.selectedItemElement) {
            //  var id = `title_${(vm.selectedItem as any)?.index}`;
            // var id = vm.selectedItemElement?.id;
            // console.log('WorkspaceItemContextMenu useEffect id',id);

            //  var el = document.getElementById(id);

            if (vm.selectedItemElement)
                setAnchorEl(vm.selectedItemElement);
        }


    })


    /* const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        console.log('WorkspaceItemContextMenu click onCollapseItem item', props.vm);

        setAnchorEl(e.currentTarget);
    };
 */
    const handleClose = () => {
        console.log('WorkspaceItemContextMenu handleClose');
        vm.selectedItemElement = null;
        vm.isItemContextMenuOpen = false;
        setAnchorEl(null);

        //console.log('WorkspaceItemContextMenu handleClose anchorEl', anchorEl);
    };

    const onDuplicateClick = (e: React.MouseEvent<HTMLSpanElement>) => {

        console.log('WorkspaceItemContextMenu onDuplicateClick');

        if(vm.selectedItem?.itemTypeId == App.WorkspaceItemTypeEnum.List){
             
            vm.isCopyListScreenOpen = true;
            console.log('WorkspaceItemContextMenu onDuplicateClick vm.isCopyListScreenOpen',vm.isCopyListScreenOpen);

        }
  
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);
 
        handleClose();
    };
    const onDuplicateListClose = (e: React.MouseEvent<HTMLSpanElement>) => {

        vm.isCopyListScreenOpen = false;
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

    }


    const onRenameClick = (e: React.MouseEvent<HTMLSpanElement>) => {

        console.log('WorkspaceItemContextMenu onRenameClick');

        vm.isListSettingsOpen = true;

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);
        handleClose();
    };

    const onSettingsClick = (e: React.MouseEvent<HTMLSpanElement>) => {

        console.log('WorkspaceItemContextMenu onSettingsClick');

        vm.isListSettingsOpen = true;

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);
        handleClose();
    };


    const onNewListClick = (e: React.MouseEvent<HTMLSpanElement>) => {

        console.log('WorkspaceItemContextMenu onNewListClick');

        vm.isNewListModalOpen = true;
        vm.newItem = App.WorkspaceVm.setupNewItem(vm) || new Api.WorkspaceItem();
        var newItem = vm.newItem;
        newItem.itemTypeId = App.WorkspaceItemTypeEnum.List;


        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_MODAL_CHANGE, newItem);
        handleClose();
    };

    const onNewFolderClick = (e: React.MouseEvent<HTMLSpanElement>) => {

        console.log('WorkspaceItemContextMenu onNewListClick');

        vm.isNewFolderModalOpen = true;

        vm.newItem = App.WorkspaceVm.setupNewItem(vm) || new Api.WorkspaceItem();
        var newItem = vm.newItem;
        newItem.itemTypeId = App.WorkspaceItemTypeEnum.Folder;

        if (vm.selectedItem?.itemTypeId === App.WorkspaceItemTypeEnum.Folder)
            newItem.parentId = vm.selectedItem?.workspaceItemId;
        else
            newItem.parentId = vm.selectedItem?.parentId;

        console.log('WorkspaceItemContextMenu onNewListClick newItem.parentId', newItem.parentId);

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_MODAL_CHANGE, newItem);
        handleClose();
    };

    const onDeleteClick = (e: React.MouseEvent<HTMLSpanElement>) => {

        console.log('WorkspaceItemContextMenu onDeleteClick');

        if(item?.isDeleteLocked)
            return;

        vm.isItemDeleteOpen = true;

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_MODAL_CHANGE, vm);
        handleClose();
    };




    const onOpenInNewTabClick = (e: React.MouseEvent<HTMLSpanElement>) => {

        var url = vm.selectedItem?.url;

        url = '/pages/list/223444';

        if (url)
            window.open(url, '_blank', 'noopener,noreferrer');

        //App.RouteManager.redirect(url);

        handleClose();
    };

    const onCopyLink = (e: React.MouseEvent<HTMLSpanElement>) => {

        console.log('WorkspaceItemContextMenu onCopyLink', vm.selectedItem);

        var url = vm.selectedItem?.url;


        url = App.RouteManager.getFullUrl(`/list/${vm.selectedItem?.defaultViewId}`);

        //copy url to clickboard
        navigator.clipboard.writeText(url);
        App.AppBase.showMessage('Link copied to clipboard');

        //forceUpdate();
        handleClose();
    };


    function getMenuItems(): any[] {

        var list: any[] = [];

        if (!item?.isFolder) {


            list.push(
                <MenuItem key='inaNewTab' className='WIContextMenuItem' onClick={onOpenInNewTabClick}>
                    <Icons.OpenInNewOutlined />
                    Open in a New Tab
                </MenuItem>
            );
            list.push(
                <Divider key='Divider1' />
            );

        }

        return list;

    }


    if (!vm.isItemContextMenuOpen)
        return null;

    var currentUser = App.AppBase.currentUser;
    var workspace = App.AppBase.currentWorkspace;

    var blockChangeTags = ['DLA','SYS','DATA'];
    //var canAddChangeList = (currentUser.isAdmin || currentUser.company?.companyId==102); //YC
    //var canAddChangeList = false;
    
    var canAddChangeList =  (currentUser.isAdmin || (workspace && !blockChangeTags.includes(workspace.tag)))

 
    return (

        <Menu
            anchorEl={anchorEl}
            open={vm.isItemContextMenuOpen}
            onClose={handleClose}
            style={{ width: '30rem' }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {getMenuItems()}

            <MenuItem key='newFolder' className='WIContextMenuItem' onClick={onNewFolderClick}>
                <Icons.AddOutlined />
                New Folder
            </MenuItem>
            {canAddChangeList && (
                <MenuItem key='newList' className='WIContextMenuItem' onClick={onNewListClick}>
                    <Icons.AddOutlined />
                    New List
                </MenuItem>
            )}
            {!item?.isFolder && (
                <MenuItem key='CopyLinkItem' className='WIContextMenuItem' onClick={onCopyLink}>
                    <Icons.LinkOutlined />
                    Copy Link
                </MenuItem>
            )}
            {canAddChangeList && !item?.isNameLocked && (
                <MenuItem key='RenameItem' className='WIContextMenuItem' onClick={onRenameClick}>
                    <Icons.EditOutlined />
                    Rename
                </MenuItem>

            )}
            {!item?.isFolder && (
                <MenuItem key='DuplicateItem' className='WIContextMenuItem' onClick={onDuplicateClick}>
                    <Icons.CopyAll />
                    Duplicate
                </MenuItem>
            )}
            <MenuItem key='ArchiveItem' className='WIContextMenuItem' onClick={handleClose}>
                <Icons.ArchiveOutlined />
                Archive
            </MenuItem>

            {canAddChangeList && !item?.isDeleteLocked && (
                <MenuItem key='DeleteItem' className='WIContextMenuItem' onClick={onDeleteClick}>
                    <Icons.DeleteOutlined />
                    Delete
                </MenuItem>
            )}


            <Divider key='Divider2' />
            {canAddChangeList && (
                <MenuItem key='settingsItem' className='WIContextMenuItem' onClick={onSettingsClick}>
                    <Icons.SettingsOutlined />
                    Settings
                </MenuItem>
            )}
        </Menu>

    );
};

export default WorkspaceItemContextMenu;
