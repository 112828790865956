import './LY_SuggestionItems.css'

import * as Api from "app-api";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB"
import * as LY from '_LY_Components';
import * as App from 'AppReferences';


import { SuggestionItem } from 'novel/extensions';
import React from 'react';
import { LY_UsersPopup } from '../LY_UserPopup/LY_UsersPopup';
import { EditorCommandItem, EditorInstance } from 'novel';
import { Range } from "@tiptap/core";

interface LY_SuggestionItemsProps {
    suggestionItems: SuggestionItem[];
    isSelectColumnsActive: boolean;
    columns?: Api.ListColumn[];
    setIsSelectColumnsActive: (val: boolean) => void;
    setEditorText?: (newText: string) => void;
    handleCommand: (val: {
        editor: EditorInstance;
        range: Range;
    }, item: SuggestionItem) => void
}


export const LY_SuggestionItems: React.FC<LY_SuggestionItemsProps> = ({ suggestionItems, isSelectColumnsActive, columns, setIsSelectColumnsActive, setEditorText, handleCommand }) => {
    return <>           {isSelectColumnsActive ? (
        <>
            <LY_UsersPopup
                displayValue="displayName"
                columns={columns}
                onCloseUsersPopup={() => setIsSelectColumnsActive(false)}
                onClickUser={setEditorText}
            />
        </>
    ) : (
        suggestionItems.map((item) => (
            <EditorCommandItem
                value={item.title}
                onCommand={(val) => handleCommand(val, item)}
                className="LY_SlashEditor_CommandItem"
                key={item.title}
            >
                <div className="LY_SlashEditor_CommandItem_Icon">
                    {item.icon}
                </div>
                <div>
                    <p className="LY_SlashEditor_CommandItem_Title">
                        {item.title}
                    </p>
                    <p className="LY_SlashEditor_CommandItem_Description">
                        {item.description}
                    </p>
                </div>
            </EditorCommandItem>
        ))
    )}
    </>
}
