import * as LIB from '_LIB';
import moment from "moment";


export class DateHelper {



    public static isValidDate(value: any) {
        var dateWrapper = new Date(value);
        var result = !isNaN(dateWrapper.getDate());
        return result;
    }
    
    public static getDateOrUndefined(val: any) {

        if (val == undefined || !DateHelper.isValidDate(val))
            return undefined;

        var dt = new Date(val.toString());

        return dt;
    }

    public static getDateOnly(dateValue:any):string {
        if (LIB.Common.isNullOrEmpty(dateValue))
            return '';

        var str = dateValue.toString();
        var tIndex = str.indexOf("T");
        if(tIndex<0)
            return str;


        var dt = str.substring(0,tIndex);
        //console.log('dt:'+dt);

        return dt;
    }

    public static getFormattedDate(date:any=null, format: string, orCurrentDate:boolean=true): string {
        //console.log('...getFormattedDate...');
        if ((!date || LIB.Common.isNullOrEmpty(date)) && orCurrentDate)
              date = new Date();
        else  if (LIB.Common.isNullOrEmpty(date))
             return '';      

        //console.log('...getFormattedDate date:');
        //console.log(date);

        var mDate = moment(date).format(format);
        var dateStr = mDate.toString();

        //console.log('getFormattedDate dateStr:' + dateStr, format);

        return dateStr;
    }

    public static getFormattedDateWithAMPM(date:any=null): string {

   //   console.log('getFormattedDateWithAMPM');

        var format = 'MM/DD/YYYY hh:mm A';
        if (!date || date==null)
            date = new Date();

    //  console.log('getFormattedDateWithAMPM date:',date);

        return DateHelper.getFormattedDate(date, format, true);
    }


    public static getFormattedShortDate(date:any=null): string {

        var format = 'MM/DD/YYYY';
        return DateHelper.getFormattedDate(date, format, false);
    }
 


    public static getFriendlyDate(date: any = null, 
        format: string = "MMM D, h:mm A",   orCurrentDate: boolean = true
      ): string {



        if (!date && orCurrentDate) {
          date = moment.utc().toDate();
        } else if (!date) {
          return "";
        }
    
        const mDate = moment.utc(date);
        const now = moment.utc();
        const duration = moment.duration(now.diff(mDate));
        const minutesAgo = Math.floor(duration.asMinutes());
        const hoursAgo = Math.floor(duration.asHours());
    
        if (minutesAgo < 1) {
          return "just now";
        } else if (minutesAgo < 60) {
          return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
        } else if (hoursAgo === 1) {
          return "an hour ago";
        } else if (hoursAgo < 24) {
          return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;

        } else if (hoursAgo < 48) {
          return "Yesterday, " + mDate.format('h:mm A');
        } else {
          return mDate.format(format);
        }
      }


}