import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as React from 'react';
import * as LY from '_LY_Components';


export class ListTableManager {


  public props: LY.ListTableViewProps
  public state: App.ListState;
  public forceUpdate: Function;
  public onListDataLoaded: Function;
  public onListDataChanged: Function;

  constructor(props: LY.ListTableViewProps, 
    state: App.ListState,
    forceUpdate: Function, 
    onListDataLoaded: 
    Function, onListDataChanged: Function
    ) {
 
    this.props = props;
    this.state = state;
    this.forceUpdate = forceUpdate;
    this.onListDataLoaded = onListDataLoaded;
    this.onListDataChanged = onListDataChanged;

    //console.log('ListTableManager props:', props);


  }


  public async getListData(sm: Api.ListGetDataSm | undefined = undefined, 
    filter: Api.QueryFilter | undefined = undefined, showLoading:boolean = true) {

    //  console.log('ListTableManager getListData filter:', filter);
    //  console.log('ListTableManager getListData sm:', sm);
    //  console.log('ListTableManager getListData props:', this.props);

    var vm = this.state.vm;
    vm.isActionInProgress = false;

    var state = this.state;
    var listId = this.props.listId;

   /*  if(filter)
      state.listFilter = filter; */

      if(!sm){
        sm = new Api.ListGetDataSm();
        sm.viewId = this.props.viewId;
        sm.listId = this.props.listId;
        sm.linkedWorkspaceId = this.props.linkedWorkspaceId;
        sm.filter = filter;
      }

      if(!sm.viewId)
        sm.viewId = this.props.viewId;
      if(!sm.listId)
        sm.listId = this.props.listId;

/*       console.log('ListTableManager getListData sm2:', sm);
      console.log('ListTableManager getListData sm2 props:',  this.props);
 */
    if (this.props.workspaceId === undefined || sm == undefined || sm.viewId === undefined || sm.viewId < 1) {
      var msg = 'List - required info missing!';
      console.log(msg);
      //vm.apiErrorMessage = msg;

      //this.state.records = [];
      //this.setListState( this.state);
      return;
    }
 

   // console.log('ListTableManager getListData workspaceId:', this.props.workspaceId);
   // console.log('ListTableManager getListData sm:', sm);
   //console.log('ListTableManager getListData sm.filter:', sm.filter);
   //console.log('ListTableManager getListData props.filter:',this.props.filter);

    var service = new App.ListDataService(this.props.workspaceId);
    service.linkedWorkspaceId = this.props.linkedWorkspaceId;

    service.getRecords(sm,
      //onSuccess
      (data: any) => {
        console.log('ListTableView getRecords onSuccess data:', data);
        vm.apiErrorMessage = '';

        /* let newState = data;
        
        vm.isAddColumnScreenOpen = false;
        vm.apiErrorMessage = '';
        vm.viewId = sm.viewId;
        newState.vm = vm;
        newState.isLinkedList = newState.view.isLinkedView || false;
        newState.forceReadOnly = newState.isLinkedList; */

        var isUpdate = this.state.hasDataLoaded;

        var result = this.state;
        result.records = data.records;
        result.dropDownData = data.dropDownData;
        result.workspaceLookupTypes = data.lookupTypes;
        //result.lookupTypes = data.lookupTypes;
        //result.listViews = data.listViews;
        result.recordsCount = data.recordsCount;
       // result.columns = data.columns;
        result.columnTypes = data.columnTypes;
        result.list = data.list;
        result.listId = data.listId;
        result.view = data.view;
        result.view.filter = { ...data.view.filter };
        vm.viewId = this.props.viewId;
        result.isLinkedList = result.view.isLinkedView || false;
        result.forceReadOnly = result.isLinkedList;
        result.hasDataLoaded = true;
        result.vm = vm;
        result.selectedColumn = new Api.ListColumn();
        vm.isActionInProgress = false;
        vm.isSearchInProgress = false;
        vm.isDetailPopupModalOpen = false;
        vm.isDetailRightPanelOpen = false;

        //console.log('ListTableManager getRecords result.listFilter1 :', result.listFilter);


    /*     let listFilterSm = result.listFilterSm;
        if (listFilterSm?.listId != this.state?.list?.listId) {
          result.listFilter = new Api.QueryFilter();
          result.listFilterSm = new Api.ListGetDataSm();
        } */
 
      //  console.log('ListTableManager getRecords result :', result);
       // console.log('ListTableManager getRecords result.listFilter :', result.listFilter);

       console.log('ListTableManager getRecords result.view.filter :', result.view.filter);

        this.onListDataLoaded(result);

        if(isUpdate)
          this.onListDataChanged(result);

 
        this.forceUpdate();
        //console.log('ListTableView vm.isActionInProgress success 2', vm.isActionInProgress);

      },
      //onError
      this.onServiceError.bind(this),

    );


    vm.isActionInProgress = showLoading;

    //console.log('ListTableManager getListData isActionInProgress:', showLoading);

    this.forceUpdate();

  }

  private onServiceError(error: any) {
    var vm = this.state.vm;

    //vm.apiErrorMessage = LIB.ErrorHelper.getErrorMessage(error);
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

    App.AppBase.showError(errorMessage);

    vm.isActionInProgress = false;
    vm.isSearchInProgress = false;
    this.state.newItem.loading = false;

    this.forceUpdate();
  }



  public async onTableCellChange(sm: Api.ListUpdateDataSm, e: React.ChangeEventHandler | undefined) {

   // console.log('ListTableManager onTableCellChange sm:', sm);
  //  console.log('ListTableManager onTableCellChange e:', e);

    if (sm == undefined)
      return;

    var vm = this.state.vm;
    var state = this.state;
    vm.isActionInProgress = false;
    var recordManager = new App.RecordManager();

    // var service = new App.ListDataService(this.props.workspaceId);
    var service = new App.ListDataService(this.props.workspaceId);
    service.updateValues(sm,
      //onSuccess
      (data: any) => {
        //console.log('ListTableManager onTableCellChange onSuccess data:', data);
        vm.apiErrorMessage = '';

        let updatedRow = data.records[0];
        var result = { ...this.state };

        //console.log('ListTableManager onTableCellChange onSuccess updatedRow.id:', updatedRow?.id);

        let found = recordManager.getItemById(updatedRow?.id,result.records);
        //console.log('ListTableManager onTableCellChange onSuccess found1:',found);

        if(found){
          // LIB.ObjectHelper.setData(updatedRow,found);

           sm.updates.forEach((update) => {
             for(var key in update.data){
               console.log('ListTableManager onTableCellChange updatedRow column/value:',key,updatedRow[key]);
               found[key] = updatedRow[key];
             }
           });
 
        } 
        
        console.log('ListTableManager onTableCellChange onSuccess updatedRow:',updatedRow);
        console.log('ListTableManager onTableCellChange onSuccess found:',found);
        console.log('ListTableManager onTableCellChange onSuccess total:',found?.total);

        this.onListDataChanged(result);
      //  this.forceUpdate();


      },
      //onError
      this.onServiceError.bind(this),

    );



  }

  public onNewItemSave(sm: Api.ListInsertDataSm, e: React.ChangeEventHandler | undefined) {

    console.log('ListTableManager onNewItemSave sm:', sm);
    console.log('ListTableManager onNewItemSave e:', e);

    if (sm == undefined)
      return;

    var vm = this.state.vm;
    vm.isActionInProgress = false;

    // var service = new App.ListDataService(this.props.workspaceId);

 
    console.log('ListTableManager onNewItemSave props:', this.props);

    var service = new App.ListDataService(this.props.workspaceId);


    service.insertItem(sm,
      //onSuccess
      (data: any) => {
        console.log('ListTableManager onNewItemSave onSuccess data:', data);
        vm.apiErrorMessage = '';
        vm.isActionInProgress = false;
        
        var result = { ...this.state, newItem: new LY.DataItemModel() };

        // var result = this.state;
        result.newItem = new LY.DataItemModel();

        let newItems = data.records as any[];

        console.log('ListTableManager.onNewItemSave newItems:', newItems);

        if (newItems && newItems.length > 0) {

          console.log('ListTableManager.onNewItemSave newItems adding...', newItems);

          //add range
          //result.records.push(...newItems);
          result.records = result.records.concat(newItems);

          var nn = new LY.DataItemModel();
          result.newItem = new LY.DataItemModel();
          

          console.log('ListTableManager.onNewItemSave result.newItem', result.newItem);

        }

        this.onListDataChanged(result);
        this.forceUpdate();


      },
      //onError
      this.onServiceError.bind(this),

    );


  }

  



}