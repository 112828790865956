import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';

import { GlobalWorkspaceReducers } from './Workspace/GlobalWorkspaceReducers';
import { GlobalWorkspaceItemReducers } from './Workspace/GlobalWorkspaceItemReducers';
 
export class GlobalReducers {


    constructor() {

    }


    public static getActionHandlersReducers(state: App.GlobalState | undefined, action: App.AppAction): App.GlobalState {
        var str = "";

        //  console.log('GlobalReducers start:', action.type);


        var result = new App.GlobalState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("GLOBAL"))// && !action.type.startsWith("LIST"))
            return state;

        if (action.type.startsWith("GLOBAL_WORKSPACE_ITEM"))
            return GlobalWorkspaceItemReducers.getReducers(state, action);

        if (action.type.startsWith("GLOBAL_WORKSPACE"))
            return GlobalWorkspaceReducers.getReducers(state, action);
 

        var appState = action.appState;

        console.log('GlobalReducers.getActionHandlersReducers');

        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.GLOBAL_API_ERROR:
                console.log('GLOBAL_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                result.initialData = { ...state.initialData };
                result.initialData.isActionInProgress = false;
                //result.initialData.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action.payload);
                result.isInitialDataLoaded = false;
                console.log('apiErrorMessage:' + result.initialData.apiErrorMessage);
                let vm = result.workspaceVm
                vm.isActionInProgress = true;

                App.AppBase.setApiError(action, appState);

                //return result;
                break;
            case App.ActionTypes.GLOBAL_WINDOW_SIZE_CHANGED: {
                console.log('GLOBAL_WINDOW_SIZE_CHANGED:', action.payload);

                var isMobile = action.payload.isMobile;

                console.log('GLOBAL_WINDOW_SIZE_CHANGED state:', state.globalUIState.isMobile);

                if (isMobile == state.globalUIState.isMobile) {
                    return state;
                }


                result = { ...state };

                LIB.ObjectHelper.setData(action.payload, result.globalUIState);
                // result.globalUIState.isMobile = action.payload.isMobile;


                App.AppBase.isMobile = result.globalUIState.isMobile;

                console.log('GLOBAL_WINDOW_SIZE_CHANGED:', action.payload);
                result.forceUpdateCount++;
                // return result;
                break;
            }
            case App.ActionTypes.GLOBAL_LOG_API_RESULT:
                console.log('GLOBAL_LOG_API_RESULT:');
                console.log(action.payload);
                //return result;
                break;

            case App.ActionTypes.GLOBAL_UI_STATE_CHANGED: {
                console.log('GLOBAL_UI_STATE_CHANGED:', action.payload);
                result = { ...action.appState.globalData };

                // result.globalUIState = action.payload;

                LIB.ObjectHelper.setData(action.payload, result.globalUIState);

                console.log('GLOBAL_UI_STATE_CHANGED:done:', result.globalUIState);

                result.forceUpdateCount++;
                //return result;
                break;
            }
            case App.ActionTypes.GLOBAL_INITIAL_DATA_RECEIVED:
                console.log('GLOBAL_INITIAL_DATA_RECEIVED:', action.payload);
                //console.log(action.payload);
                //result.initialData.currentUser = action.payload.currentUser;

                let data = action.payload;

                result = { ...state };
                result.initialData.currentUser.setData(data.currentUser);
                result.initialData.userRoleTypes = data.userRoleTypes;
                result.initialData.testSessionVal = data.testSessionVal;
                result.initialData.apiEnvName = data.apiEnvName;
                result.initialData.appSettings = data.appSettings;
                result.initialData.lookupTypes = data.lookupTypes;
                result.initialData.users = data.users;

                result.initialData.sessionHasCurrentUser = data.sessionHasCurrentUser;

                console.log('testSessionVal:' + result.initialData.testSessionVal);
                console.log('sessionHasCurrentUser:' + result.initialData.sessionHasCurrentUser);
                console.log('GLOBAL_INITIAL_DATA_RECEIVED currentUser:', result.initialData.currentUser);

                App.AppBase.setAppInfo(data.appSettings);


                var globalUi = result.globalUIState;
                globalUi.companyStats = data.companyStats;

                var workspaceVm = result?.workspaceVm;

                if (workspaceVm && workspaceVm?.selectedWorkspace) {

                    workspaceVm.users = data.users;
                    workspaceVm.workspaces = data.workspaces;
                    workspaceVm.linkedWorkspaces = data.linkedWorkspaces;

                   // console.log('GLOBAL_INITIAL_DATA_RECEIVED  linkedWorkspaces', workspaceVm.linkedWorkspaces);
                   // console.log('GLOBAL_INITIAL_DATA_RECEIVED  linkedWorkspaces items 1 views', workspaceVm.linkedWorkspaces?.[0]?.workspaceItems);

                    workspaceVm.selectedWorkspace = data.selectedWorkspace;

                    if (Array.isArray(workspaceVm.workspaces))
                        workspaceVm.allWorkspaces = workspaceVm.workspaces.concat(workspaceVm?.linkedWorkspaces);

                    let linkedWorkspaces = workspaceVm?.selectedWorkspace?.linkedWorkspaces;

                    // console.log('GLOBAL_INITIAL_DATA_RECEIVED  linkedWorkspaces',linkedWorkspaces);
                    console.log('GLOBAL_INITIAL_DATA_RECEIVED  allWorkspaces', workspaceVm.allWorkspaces);

                    result.availableConnections = data.availableConnections;

                    let selectedWorkspace = workspaceVm?.selectedWorkspace;
                    let selectedViewId = selectedWorkspace?.selectedViewId;
                    let selectedListId = selectedWorkspace?.selectedListId;

                    let items = selectedWorkspace?.workspaceItems;
                    let foundSelectedItem = items?.find(x => x.defaultViewId == selectedViewId &&
                        selectedViewId != undefined && selectedViewId > 0);

                   if (!foundSelectedItem)
                        foundSelectedItem = items?.find(x => x.listId == selectedListId && selectedListId != undefined  );

                    console.log('GLOBAL_INITIAL_DATA_RECEIVED foundSelectedItem 1:',foundSelectedItem);
                    if (!foundSelectedItem)
                        foundSelectedItem = items?.find(x => x.itemTypeId == App.WorkspaceItemTypeEnum.List);

                    if (foundSelectedItem) {
                        workspaceVm.selectedItem = foundSelectedItem;
                        selectedWorkspace.selectedViewId = foundSelectedItem.defaultViewId;
                        selectedWorkspace.selectedListId = foundSelectedItem.listId;

                        console.log('GLOBAL_INITIAL_DATA_RECEIVED foundSelectedItem 2:',workspaceVm.selectedItem);

                    }
                    workspaceVm.hasWorkspacesDataLoaded = true;
                    console.log('GLOBAL_INITIAL_DATA_RECEIVED workspaceVm:', workspaceVm);
                    console.log('GLOBAL_INITIAL_DATA_RECEIVED workspaceVm.selectedItem:', workspaceVm.selectedItem);

                }
                result.isInitialDataLoaded = true;
                result.isAppInfoDataLoaded = true;
                //result.forceUpdateCount++;


                break;


            case App.ActionTypes.GLOBAL_APPINFO_DATA_RECEIVED:
                console.log('GLOBAL_APPINFO_DATA_RECEIVED:');
                console.log(action.payload);

                App.AppBase.setAppInfo(action.payload);

                result.isAppInfoDataLoaded = true;

                break;


            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}