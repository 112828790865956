import "./LY_ListFilterModule_Filter_Group.css";

import * as Api from "app-api";
import * as Icons from "@tabler/icons-react";
import * as LY from '_LY_Components';

import { Button } from "@mantine/core";
import LYFiltersModuleFilterRow from "../LY_ListFilterModule_Filter_Row/LY_ListFilterModule_Filter_Row";
import React from "react";

interface LYFiltersModuleFilterGroupProps extends LY.LY_ListFilterModuleProps {
  disabledJoinOperation?: boolean;
  joinOperator?: Api.FilterConditionJoinOperatorEnum;
  groupIndex: number;
  groupJoinOperator: Api.FilterConditionJoinOperatorEnum;
  conditions?: Api.FilterCondition[];
  scrollToBottom?: boolean;
  setScrollToBottom: React.Dispatch<React.SetStateAction<boolean>>;
}

const LYFiltersModuleFilterGroup: React.FC<LYFiltersModuleFilterGroupProps> = ({
  disabledJoinOperation = false,
  joinOperator,
  conditions,
  groupIndex,
  groupJoinOperator,
  scrollToBottom,
  setScrollToBottom,
  ...props
}) => {

  const latestGroupRef = React.useRef<HTMLDivElement>(null)
  const context = LY.useListFilterModuleContext();
  React.useEffect(() => {
    if (scrollToBottom) {
      // Assume you have a ref to the latest group or a way to select it
      latestGroupRef.current?.scrollIntoView({ behavior: 'smooth' });
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  function operatorRenderer() {
    const joinOperatorData = Object.values(Api.FilterConditionJoinOperatorEnum).map(i => ({ value: i }))

    return <LY.LY_DropDown
      disabled={disabledJoinOperation}
      name="joinOperator"
      valueProperty="value"
      displayProperty="value"
      isClearable={false}
      data={joinOperatorData}
      placeholder="Op"
      style={{ width: "80px" }}
      isAutoCompleteStyle={true}
      selectedItem={joinOperatorData.find(i => i.value === joinOperator)}
      error={context.state?.errors?.get('joinOperator' + groupIndex)}
      onChange={(_, option: any) => {
        const joinOperator = option.value as Api.FilterConditionJoinOperatorEnum;
        context.onChangeJoinOperator(joinOperator);
      }}
    />
  }


  function optionsRenderer() {
    return conditions?.map((condition, index) => {
      return (
        <LYFiltersModuleFilterRow
          isConditionSets={true}
          groupIndex={groupIndex}
          key={index}
          rowIndex={index}
          joinOperator={groupJoinOperator}
          isFirstCondition={index === 0}
          withDisabledOperations={index !== 1}
          condition={condition}
          {...props}
        />
      );
    })
  }


  function buttonRenderer() {
    return <Button
      variant="transparent"
      leftSection={<Icons.IconPlus width={16} height={16} />}
      onClick={() => {
        context.onAddNewAdvancedFilterRow(groupIndex);
      }}
    >
      Add Filter
    </Button>
  }

  function render() {

    return (
      <div className="LY_ListFiltersModule_Filter_body_group_wrapper" ref={latestGroupRef}>
        {operatorRenderer()}
        <div className="LY_ListFiltersModule_Filter_body_group">
          {optionsRenderer()}
          {buttonRenderer()}
        </div>
      </div>
    );
  }


  return render();

};

export default LYFiltersModuleFilterGroup;
