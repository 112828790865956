import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { EditorBubbleItem, EditorInstance } from "novel";

import { SelectorItem } from '../../Interfaces/LY_SlashEditor_SelectorItem';

import "./LY_SlashEditor_Action_Buttons.css"


interface LY_SlashEditor_Action_ButtonsProps extends LY.LY_SlashEditorBaseProps {
    editor: EditorInstance;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    items: SelectorItem[]
}

export const LY_SlashEditor_Action_Buttons: React.FC<LY_SlashEditor_Action_ButtonsProps> = (props) => {

    function render() {

        var editor = props.editor;

        return <div className="LY_SlashEditor_ActionButtons_Container">
            {props.items.map((item) => (
                <EditorBubbleItem
                    key={item.name}
                    onSelect={(editor) => {
                        item.command(editor);
                    }}
                >
                    <LY.LY_ButtonPinkHover
                        isActive={item.isActive(editor)}
                        title={props.isTextButton ? undefined : item.tooltip}
                    >
                        {props.isTextButton ?
                            <div className="LY_SlashEditor_ButtonWithText" >
                                <item.icon size={18} color='#757B87' />
                                <span>{item.name}</span>
                            </div>
                            :
                            <item.icon size={18} />}
                    </LY.LY_ButtonPinkHover>
                </EditorBubbleItem>
            ))}
        </div>
    }

    return render()
};
