import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { createContext, useContext, useEffect } from "react";

import { LY_DLA_LabelMakerModuleServiceManager } from '../Managers/LY_DLA_LabelMakerModuleServiceManager';
import { LY_DLA_LabelMakerModuleState } from './LY_DLA_LabelMakerModuleState';


import { useDisclosure } from '@mantine/hooks';

export interface LY_DLA_LabelMakerModuleContextProps {
  state: LY_DLA_LabelMakerModuleState;
  forceUpdate: () => void;
  onCancelOrCloseReset: () => void;
  resetState: () => void;
  updateTotalCount: () => void;
  getPrintStyleClasses: (isPreview:boolean, classNameAppend:string) => string;
  services: LY_DLA_LabelMakerModuleServiceManager; 
}

const useDLALabelMakerModuleState = (props: LY.LY_DLA_LabelMakerModuleProps): LY_DLA_LabelMakerModuleContextProps => {


  const initialState = new LY_DLA_LabelMakerModuleState();

  const [state, setState] = React.useState<LY_DLA_LabelMakerModuleState>(initialState);
  const [propsState, setPropsState] = React.useState<LY_DLA_LabelMakerModuleState>(new LY_DLA_LabelMakerModuleState());
  

  const contextObjBase: LY.LY_DLA_LabelMakerModuleContextProps = {
    state,
    forceUpdate,
    onCancelOrCloseReset,
    resetState,
    updateTotalCount,
    getPrintStyleClasses,
    services: {} as LY_DLA_LabelMakerModuleServiceManager
  };

  const services = new LY_DLA_LabelMakerModuleServiceManager(props, state, contextObjBase);


  const contextObj: LY.LY_DLA_LabelMakerModuleContextProps = {...contextObjBase, services: services};

  
  React.useEffect(() => {

    console.log('LY_DLA_LabelMakerModule useEffect props', props);
    console.log('LY_DLA_LabelMakerModule useEffect isContainerModalOpen', props.isContainerModalOpen);

    state.workspaceId = props.workspaceId;
    state.workspaceId = props.workspaceId;
    state.isContainerModalOpen = props.isContainerModalOpen || false;
    state.row = props.row;
    state.errors.clear();

    if(props.model)
      state.model = props.model;
    else if(props.row){
      state.model = getModelFromRow(props.row);
    }
      
    forceUpdate();

    var stateCopy = clone(state);
    setPropsState(stateCopy);


  }, [ props.row, props.isContainerModalOpen ]);


  function getModelFromRow(row: any): LY.DLAContractLabelModel {

    if(!row)
      return new LY.DLAContractLabelModel();

    var model = new LY.DLAContractLabelModel();
    LIB.ObjectHelper.setData(row, model);
    model.rfq = row.name;
    model.qup = row.packaging_qup;

    updateTotalCount();

    return model;

  }

  function updateTotalCount(){
    if(!state.model)
      return;

    if(!isNaN(state.model.quantity) && state.model.quantity > 0 && state.model.qup > 0){
      state.model.totalCount = state.model.quantity / state.model.qup;
      //roundup
      state.model.totalCount = Math.ceil(state.model.totalCount);

    }
  }

  function getPrintStyleClasses(isPreview:boolean, classNameAppend:string=''): string {


    var styleClasses = `
    .LY_DLA_LabelMakerModule_Label_Item_Container${classNameAppend}{
      display: flex;
     flex-direction: column;  
     padding:10px;
     font-weight: 700;
     font-size: 16px;
     align-items: center;
     justify-content: start;
     background-color: #ffffff;
     line-height: 20px;
   }

  `;
  if(!isPreview){
    styleClasses += `
    .LY_DLA_LabelMakerModule_Label_Item_Container${classNameAppend}{
     border: 1px solid #000; 
   }

  `;
  }

    return styleClasses;
  }


  function clone(stateIn: LY_DLA_LabelMakerModuleState): LY_DLA_LabelMakerModuleState {

    var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_DLA_LabelMakerModuleState;
    stateCopy.errors = new Map<string, any>();
    return stateCopy;
  }


  function forceUpdate() {
    setState({ ...state })
  }
  
  function resetState() {
    var stateCopy = clone(propsState);
    setState({ ...state })
  }


  function onCancelOrCloseReset() {

    state.isActionInProgress = false;
    state.isContainerModalOpen = false;


    state.errors.clear();

    if(props.onClose)
      props.onClose();  
    
    forceUpdate();
  }

 

 

  return contextObj;
};

const LY_DLA_LabelMakerModuleContext = createContext<LY.LY_DLA_LabelMakerModuleContextProps | undefined>(undefined);

export const useDLALabelMakerModuleContext = () => {


  const context = useContext(LY_DLA_LabelMakerModuleContext)


  if (!context) {
    throw new Error(
      "useLY_StatusDropDownContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};


const LY_DLA_LabelMakerModuleContextProvider: React.FC<LY.LY_DLA_LabelMakerModuleProps & { children: React.ReactNode }> = (props) => {


  const contextValue = useDLALabelMakerModuleState(props);

  function render() {


    return (
      <LY_DLA_LabelMakerModuleContext.Provider value={contextValue}>
        {props.children}
      </LY_DLA_LabelMakerModuleContext.Provider>
    );
  }

  return render();
};
export { LY_DLA_LabelMakerModuleContextProvider, LY_DLA_LabelMakerModuleContext };
