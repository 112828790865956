import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_RecordUpdateModuleState } from "../Context/LY_RecordUpdateModuleState";

export class LY_RecordUpdateModuleServiceManager {


  public forceUpdate: () => void;
  public state: LY_RecordUpdateModuleState;
  public props: LY.LY_RecordUpdateModuleProps;
  public context: LY.LY_RecordUpdateModuleContextProps;


  public listDataService: App.ListDataService;
  public sm: Api.ListViewGetDataSm;

  constructor(props: LY.LY_RecordUpdateModuleProps,
    state: LY_RecordUpdateModuleState,
    context: LY.LY_RecordUpdateModuleContextProps) {


    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;

    this.sm = new Api.ListViewGetDataSm()

    // this.service = new App.ListViewService(props.workspaceId)
    this.listDataService = new App.ListDataService(props.workspaceId);

    this.listDataService.linkedWorkspaceId = this.props.linkedWorkspaceId;


  }

  public onServiceError(error: any) {

    if (this.state == undefined)
      return;

    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    App.AppBase.showError(errorMessage)
    this.state.isActionInProgress = false;
    this.forceUpdate();

  }

  /*   public createView(sm: Api.ListViewSm, setView: React.Dispatch<React.SetStateAction<Api.ListView>>, close: Function) {
  
      console.log('LY_RecordUpdateModuleServiceManager createView start sm:', sm);
   
      this.service.createView(sm,
  
        //onSuccess
        (result: Api.ListView) => {
          console.log('LY_RecordUpdateModuleServiceManager createView onSuccess:', result);
  
          //todo code
          this.state.isActionInProgress = false;
          App.AppBase.showSaveSuccess();
        
          this.forceUpdate();
        },
        //onError
        this.onServiceError.bind(this)
      );
  
      this.state.isActionInProgress = true;
      this.forceUpdate();
   
    } */


  public updateRowValues() {

    console.log('LY_RecordUpdateModuleServiceManager updateRowValues rowUpdates:', this.state.rowUpdates);

    if (!this.state.rowUpdates || this.state.rowUpdates.size == 0) {
      console.log('LY_RecordUpdateModuleServiceManager updateRowValues no updates');
      return;
    }

    var updates = new Api.UpdateValue();//App.RecordManager.getUpdateValue(model!);
    updates.listId = this.props.listId;
    updates.rowId = this.state.row['id'];
    updates.data = Object.fromEntries(this.state.rowUpdates);

    var sm = new Api.ListUpdateDataSm();
    sm.updates = [ updates ];
    console.log('LY_RecordUpdateModuleServiceManager updateRowValues sm:', sm);
    console.log('LY_RecordUpdateModuleServiceManager updateRowValues updates:', updates);


    this.listDataService.updateValues(sm,
      //onSuccess
      (result: any) => {
        console.log('LY_RecordUpdateModuleServiceManager onSuccess:', result);

        var updated = result?.records[ 0 ];


        this.state.errors.clear();
        this.state.isActionInProgress = false;

        if (updated) {
          LIB.ObjectHelper.setData(updated, this.state.row);
        }

        App.AppBase.showSaveSuccess(false);

        this.forceUpdate();


      },
      //onError
      this.onServiceError.bind(this)
    );


    this.state.isActionInProgress = true;
    this.forceUpdate();


  }


}
