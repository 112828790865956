import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_DLA_LabelMakerModule_Preview.css";
import { Tabs } from '@mantine/core';


import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
import { LY_DLA_LabelMakerModule_Label } from '../LY_DLA_LabelMakerModule_Label/LY_DLA_LabelMakerModule_Label';


export const LY_DLA_LabelMakerModule_Preview: React.FC<LY.LY_DLA_LabelMakerModuleProps> = (props) => {


  const context = LY.useDLALabelMakerModuleContext();
  const { state, services } = context;
  var item = state.model;




  function getLabels() {

    if (!item.totalCount || item.totalCount === 0)
      return [];

    var pages = [];
    for (let index = 0; index < item.totalCount; index++) {
      pages.push(<LY_DLA_LabelMakerModule_Label {...props} onlyLabel={true} />);
    }

    return pages;
  }


  function render() {

    if(state.selectedMainContainerTab!=='preview')
      return null;

    console.log('LY_DLA_LabelMakerModule_Preview render state', state);



    var container = <div className='LY_DLA_LabelMakerModule_Preview_Container'>
      <div className='LY_DLA_LabelMakerModule_Preview_Paper'>
        <div dangerouslySetInnerHTML={{ __html: `<style>${context.getPrintStyleClasses(true,'')}</style>` }} />
        <div className='LY_DLA_LabelMakerModule_Preview_PaperItems'>
          {getLabels()}
        </div>
      </div>
    </div>

    return container;

  }




  return render();
};
