import * as LIB from "_LIB";
import * as Api from "app-api";
import * as App from "AppReferences";

import { LY_NotesModuleState } from "../Context/LY_NotesModuleState";
import { LY_NotesModuleProps } from "../Interfaces/LY_NotesModuleProps";

export class NotesManager extends LIB.BaseManager {
  public forceUpdate: Function;
  public workspaceId?: number;
  public props: LY_NotesModuleProps;
  public state: LY_NotesModuleState;
  public setState: Function;

  constructor(
    props: LY_NotesModuleProps,
    state: LY_NotesModuleState,
    setState: Function,
    forceUpdate: Function
  ) {
    super("id");
    this.workspaceId = props.workspaceId;
    this.state = state;
    this.forceUpdate = forceUpdate;
    this.setState = setState;
  }

  public onServiceError(error: any) {
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

    App.AppBase.showError(errorMessage);
    this.state.isActionInProgress = false;
    this.state.errors.set("all", errorMessage);
    this.forceUpdate();
  }

  public getNotes(recordId?: string) {
    var service = new App.NotesService(this.workspaceId);

    var sm = new Api.NotesGetDataSm();

    sm.recordId = recordId;

    this.state.isActionInProgress = true;
    this.forceUpdate();

    return service.getNotes(
      sm,
      (result: any) => {
        console.log("getNotesApi onSuccess:", result);
        var notes = result?.records;
        var count = result?.recordsCount;
        this.state.notes = notes;


        this.state.isActionInProgress = false;
        //console.log(this.state.notes, this.state);

        this.forceUpdate();

      },
      this.onServiceError.bind(this)
    );
  }

  public updateNotePinned(note: Api.Note) {
    var service = new App.NotesService(this.workspaceId);

    //this.state.isActionInProgress = true;
    // this.forceUpdate();

    return service.updateNotePinned(
      note.id!,
      !note.pinned!,
      (result: any) => {
        console.log("Toggle Note Pin onSuccess:", result.pinned);
        this.state.isActionInProgress = false;
        this.forceUpdate();
      },
      this.onServiceError.bind(this)
    );
  }

  public updateNoteReplyPinned(reply: Api.NoteReply) {
    var service = new App.NotesService(this.workspaceId);

    //this.state.isActionInProgress = true;

    // this.forceUpdate();

    return service.updateNoteReplyPinned(
      reply.id!,
      !reply.pinned!,
      (result: any) => {
        console.log("Toggle Reply Pin onSuccess:", result);
        this.state.isActionInProgress = false;
       this.forceUpdate();
      },
      this.onServiceError.bind(this)
    );
  }

  public createNote(note: Api.Note, recordId?: string) {
    var service = new App.NotesService(this.workspaceId);
    var sm = new Api.Note();

    sm.id = note.id;
    sm.record_id = recordId;
    sm.message = note.message;
    sm.pinned = false;

    // sm.name = LIB.StringHelper.maxLength(note, 20);
   // this.state.isActionInProgress = true;

  //  this.forceUpdate();

    service.createNote(
      sm,
      (result: any) => {
        console.log("createNote onSuccess", result);
        this.state.isActionInProgress = false;
        this.forceUpdate();
      },
      this.onServiceError.bind(this)
    );


  }

  public createNoteReply(reply: Api.NoteReply) {
    var service = new App.NotesService(this.workspaceId);

    var sm = new Api.NoteReply();

    sm.note_id = reply.note_id;
    sm.pinned = false;
    sm.message = reply.message;
    sm.id = reply.id;
    //sm.name = LIB.StringHelper.maxLength(reply.message!, 20);
    sm.author_id = reply.author_id;
   // sm._ly_created_by = reply.author_id;
  //  this.state.isActionInProgress = true;
   // this.forceUpdate();

    return service.createNoteReply(
      sm,
      (result: any) => {
        console.log("Post Reply onSuccess:", result);
        this.state.isActionInProgress = false;
        this.forceUpdate();
      },
      this.onServiceError.bind(this)
    );
  }

  public updateNote(note: Api.Note) {
    var service = new App.NotesService(this.workspaceId);
    var sm = new Api.Note();

    // sm.name = LIB.StringHelper.maxLength(value, 20);
    sm.id = note.id;
    sm.message = note.message;
    sm.pinned = note.pinned;
    //this.state.isActionInProgress = true;
    // this.forceUpdate();

    return service.updateNote(
      sm,
      (result: any) => {
        App.AppBase.showMessage("Successfully Saved!");
        this.state.isActionInProgress = false;
        // this.forceUpdate();
      },
      this.onServiceError.bind(this)
    );
  }

  public updateNoteReply(reply: Api.NoteReply) {
    var service = new App.NotesService(this.workspaceId);
    var sm = new Api.NoteReply();

    // sm.name = LIB.StringHelper.maxLength(value, 20);
    sm.id = reply.id;
    // sm.note_id = noteId;
    sm.author_id = reply.author_id;
    sm.message = reply.message;
    sm.pinned = reply.pinned;

    //this.state.isActionInProgress = true;
    // this.forceUpdate();

    return service.updateNoteReply(
      sm,
      (result: any) => {
        console.log("Reply Updated onSuccess:", result);
        App.AppBase.showMessage("Successfully Saved!");
        this.state.isActionInProgress = false;
        // this.forceUpdate();
      },
      this.onServiceError.bind(this)
    );
  }

  public deleteNote(noteId: string) {
    var service = new App.NotesService(this.workspaceId);

   // this.state.isActionInProgress = true;

    // this.forceUpdate();

    return service.deleteNote(
      noteId,
      (result: any) => {
        App.AppBase.showMessage("Successfully Deleted!");
       // this.state.isActionInProgress = false;
        // this.forceUpdate();
      },
      this.onServiceError.bind(this)
    );
  }

  public deleteNoteReply(replyId: string) {
    var service = new App.NotesService(this.workspaceId);

    this.state.isActionInProgress = true;
    // this.forceUpdate();

    return service.deleteNoteReply(
      replyId,
      (result: any) => {
        App.AppBase.showMessage("Successfully Deleted!");
        this.state.isActionInProgress = false;
        // this.forceUpdate();
      },
      this.onServiceError.bind(this)
    );
  }
};


