import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import { ButtonHTMLAttributes, ChangeEvent, ChangeEventHandler, HTMLAttributes, ReactElement } from 'react';

 
import './LY_CopyButton.css';
 

interface LY_CopyButtonProps   {
  children?: any; 
  htmlValue?: string;
};


export const LY_CopyButton: React.FC<LY_CopyButtonProps & MT.ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ((
  {
 
    ...props

  }) => { 

    const [ copied, setCopied ] = React.useState(false);


    
  function onClickLocal() {
      console.log('LY_CopyButton onClickLocal, html:',props.htmlValue);

     if (!props.htmlValue)
      return;

 
      LIB.ClipboardHelper.copyToClipBoard(props.htmlValue);

 
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

 

    return <MT.Button
    
    onClick={onClickLocal}

    color={copied ? 'green' : 'blue'}
    {...props}
  >
    {copied ? 'Copied' : 'Copy'}
  </MT.Button>


 
 

  });
