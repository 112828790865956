import "./LY_SlashEditor.css";

import * as Api from "app-api";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB"
import * as LY from '_LY_Components';
import * as App from 'AppReferences';


import React, { useRef, useState, useCallback } from "react";

import {
  EditorCommand,
  EditorCommandList,
  EditorContent,
  type EditorInstance,
  EditorRoot,
  type JSONContent,
} from "novel";

import { ImageResizer, SuggestionItem, handleCommandNavigation, } from "novel/extensions";

import { EditorBubbleItem, useEditor } from "novel";

import { defaultExtensions } from "./lib/extensions";
import { slashCommand, suggestionItems } from "./lib/slash-command";
import { debounce } from 'lodash';

import { Range } from "@tiptap/core";


import { LY_ColorSelector } from "./components/LY_ColorSelector/LY_ColorSelector";
import { LY_SuggestionItems } from "./components/LY_SuggestionItems/LY_SuggestionItems";

import { generateJSON } from '@tiptap/html';
import MarkdownIt from "markdown-it/lib";
import { LY_SlashEditor_ToolBar } from "./components/LY_SlashEditor_ToolBar/LY_SlashEditor_ToolBar";
import { EditorEvents } from "@tiptap/react";





const menuItems = [
  { id: "html", name: "HTML" },
  { id: "markdown", name: "Markdown" },
  { id: "editor", name: "Editor" },
  { id: "json", name: "Json" },
] as const;

type ContentDisplayActiveType = (typeof menuItems)[ number ][ "id" ];
const extensions = [ ...defaultExtensions, slashCommand ];



export interface LY_SlashEditorBaseProps extends LY.LY_InputBaseProps {
  onChange?: ({ html, markdown, text, json, }: { html: string; markdown: string; text: string; json: JSONContent; }) => void;
  columns?: Api.ListColumn[];

  style?: React.CSSProperties;
  className?: string;
  classNames?: { editor?: string, toolbar?: string };
  jsonValue?: JSONContent;
  htmlValue?: string;
  markdownValue?: string;
  editorRef?: React.RefObject<HTMLDivElement>;

  isTextButton?: boolean;
  isToolBarMenuDisplayed?: boolean;

  middlePartRenderer?: () => React.JSX.Element;
  additionalButtonRenderer?: () => React.JSX.Element;
  additionalNavBtnsRenderer?: () => React.JSX.Element;
}

export const LY_SlashEditor: React.FC<LY_SlashEditorBaseProps> = ({
  onChange,
  readOnly,
  model,
  ...props
}) => {
  const editorRef = useRef<EditorInstance | null>(null);
  const rangeRef = useRef<Range | null>(null);


  const [ contentMenuActiveItem, setContentMenuActiveItem ] =
    useState<ContentDisplayActiveType>("editor");

  const [ htmlContent, setHtmlContent ] = useState("");
  const [ markdownContent, setMarkdownContent ] = useState("");
  const [ jsonContent, setJsonContent ] = useState({});

  const [ isSelectColumnsActive, setIsSelectColumnsActive ] = useState(false);

  const [ changed, setChanged ] = useState(false);


  const menuActiveItems = menuItems.filter(
    (item) => item.id !== contentMenuActiveItem
  );

/* 
  const handleUpdates = async (editor: EditorInstance) => {

    console.log('LY_SlashEditor handleUpdates');

    setIsSelectColumnsActive(false);
    const json = editor.getJSON();
    const html = editor.getHTML();
    const markdown = editor.storage.markdown.getMarkdown();
    const text = editor.getText();

    onChange && onChange({ html, markdown, text, json });
    setHtmlContent(html);
    setMarkdownContent(markdown);
    setJsonContent(json);
  }; */

  function onEditorChange(props: EditorEvents['update']) {
     

    console.log('LY_SlashEditor onEditorChange docChanged:', props.transaction);
 
    setChanged(true);
   // onChange && onChange({ html, markdown, text, json });

  }


  function onEditorBlur(props: EditorEvents['blur']) {
   
    if(!changed)
        return;
      
    console.log('LY_SlashEditor onEditorBlur docChanged:', props.transaction.docChanged);

    let editor = props.editor;

    setIsSelectColumnsActive(false);
    const json = editor.getJSON();
    const html = editor.getHTML();
    const markdown = editor.storage.markdown.getMarkdown();
    const text = editor.getText();

    setHtmlContent(html);
    setMarkdownContent(markdown);
    setJsonContent(json);

  
    onChange && onChange({ html, markdown, text, json });

    setChanged(false);
  }
  const handleMenuClick = (item: ContentDisplayActiveType) => {
    setContentMenuActiveItem(item);
  };

  const setEditorText = (newText: string) => {
    const editor = editorRef.current;

    if (!editor) return;

    editor.commands.insertContent({
      type: "text",
      text: `{{${newText}}}`,
      marks: [
        {
          type: "span",
          attrs: {
            class: "custom-span",
          },
        },
      ],
    });

    rangeRef.current &&
      editor.chain().focus().deleteRange(rangeRef.current).run();
  };

  function handleCommand(val: { editor: EditorInstance; range: Range }, item: SuggestionItem) {


    if (item.title === "Select Columns") {
      setIsSelectColumnsActive(true);

      if (rangeRef?.current) {
        rangeRef.current = val?.range;
      }

      if (editorRef?.current) {
        editorRef.current = val?.editor;
      }
    }
    if (item.command) {
      item.command(val);
    }
  }

  function getEditorInitialValue() {


    if (props.jsonValue) {
      return props.jsonValue;
    }

    else if (props.htmlValue) {
      return generateJSON(props.htmlValue, extensions);
    }

    else if (props.markdownValue) {
      const md = new MarkdownIt();
      const html = md.render(props.markdownValue);
      return generateJSON(html, extensions);
    }
  }

  function renderEditor() {


    //console.log('LY_SlashEditor render props', props);
    //  console.log('LY_SlashEditor render htmlValue', props.htmlValue);


    return <div
      style={props.style}
      className={"LY_SlashEditor " + (props.className || '')}
    >
      <EditorRoot>

        <EditorContent

          ref={props.editorRef}
          extensions={extensions}


          initialContent={getEditorInitialValue()}

          //className="LY_SlashEditor_EditorContent"
          className={"LY_SlashEditor_EditorContent " + (props.classNames?.editor || '')}

          editorProps={
            {
              handleDOMEvents: {
                keydown: (_view: any, event: any) => handleCommandNavigation(event),
              },
              attributes: {

                id: 'LY_SlashEditor_EditorContentId',
                class:
                  contentMenuActiveItem !== "editor"
                    ? "LY_SlashEditor_Attributes_Disabled LY_Scrollbar"
                    : "LY_SlashEditor_Attributes LY_Scrollbar",
              },
            }}
            onUpdate={onEditorChange} 
            onBlur={onEditorBlur}

        /*   onUpdate={(item: any) => {

            useCallback(
              debounce((item: any) => {
              //  console.log('Editor content updated item:', item);
                handleUpdates(item.editor);
              }, 300),
              []
            )
          }} */

          slotAfter={<ImageResizer />}


        >





          {contentMenuActiveItem === "html" && (
            <div className="LY_SlashEditor_Attributes LY_Scrollbar">{htmlContent}</div>
          )}

          {contentMenuActiveItem === "markdown" && (
            <div className="LY_SlashEditor_Attributes LY_Scrollbar">{markdownContent}</div>
          )}

          {contentMenuActiveItem === "json" && (
            <div className="LY_SlashEditor_Attributes LY_Scrollbar">
              {JSON.stringify(jsonContent)}
            </div>
          )}

          <span>
            <LY_SlashEditor_ToolBar
              menuActiveItems={menuActiveItems}
              onContextMenuClick={handleMenuClick}
              generateJSON={generateJSON}
              {...props}
            />
          </span>



          <EditorCommand className="LY_SlashEditor_Command">
            <EditorCommandList>
              <LY_SuggestionItems
                columns={props.columns}
                handleCommand={handleCommand}
                isSelectColumnsActive={isSelectColumnsActive}
                setEditorText={setEditorText}
                setIsSelectColumnsActive={setIsSelectColumnsActive}
                suggestionItems={suggestionItems} />
            </EditorCommandList>
          </EditorCommand>



        </EditorContent>
      </EditorRoot>
    </div>
  }

  function render() {

    //console.log('LY_SlashEditor render htmlValue', props.htmlValue);

    var readOnlyValue = model?.value;
    if (!readOnlyValue && props.htmlValue)
      readOnlyValue = props.htmlValue;

    return (
      <LY.LY_InputBase  {...props}  >

        {readOnly && (
          <div className='LY_SlashEditor_ReadOnly' dangerouslySetInnerHTML={readOnlyValue}></div>
        )}

        {!readOnly && (
          renderEditor()
        )}


      </LY.LY_InputBase >
    );

  }

  return render();
};

