import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import  * as UI from '@mui/material/'; 
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import CssBaseline from '@mui/material/CssBaseline';
//import { WithStyles } from '@mui/material/styles';
import * as LIB from '_LIB';
import Alert from '@mui/material/Alert';
import { Notifications } from '@mantine/notifications';

 
import './MainLayout.css';
 
 import TopMenu from 'Components/Menus/TopMenu/TopMenu';
import ImpersonateAdminModal from 'Components/Admin/ImpersonateAdminModal';

 
interface MainLayoutProps {
 
   // showLeftMenu: boolean;
    errorMessage?:string;
    children?: React.ReactNode;
    loading?: boolean;
    className?:string;
    pagesWith100Vh?: string[];
    isFullHeightPage?: boolean;

}

export class MainLayout extends React.Component<MainLayoutProps, {}> {
  
    public getErrorAlert(){

        var error = this.props.errorMessage;
        if(LIB.Common.isNullOrEmpty(error))
        return null;

        return  <Alert severity="error">{error}</Alert>;
    }

    public render() {
        console.log('render MainLayout:');
        
     /*    var marginLeft='12rem';
        if(!this.props.showLeftMenu)
            marginLeft='0'; */

        var appState  = App.AppBase.getState();

        var content:any = this.props.children; 
        
       // console.log('MainLayout errorMessage:',this.props.errorMessage);

        if(!LIB.Common.isNullOrEmpty(this.props.errorMessage))
            content=this.getErrorAlert();

       // console.log('MainLayout content:',content);

        var currentUser = App.AppBase.currentUser;
        
          //console.log('MainLayout currentUser:',currentUser);

          var impersonateModal:any=null;

          if(currentUser && currentUser.realUserIsAdmin){
            impersonateModal =  <ImpersonateAdminModal 
            // adminUserState ={appState.adminUserData}
            // globalUIState={appState.globalData.globalUIState} 
             />
          }

          var loading = this.props.loading;
          if (loading === undefined || !LIB.Common.isNullOrEmpty(this.props.errorMessage))
              loading = false;
  

          var currentPage = App.RouteManager.getCurrentUrlPage()?.toLowerCase();//?.substring(1);
           console.log('MainLayout currentPage:',currentPage);
           console.log('MainLayout scrollingPages:',this.props.pagesWith100Vh);

          var isScrolling = true;
          
     /*      if(this.props.isFullHeightPage)
            isScrolling = false; */

         if(this.props.pagesWith100Vh && this.props.pagesWith100Vh.length>0 && currentPage){

            for(var item of this.props.pagesWith100Vh){
                if(currentPage==item || currentPage.includes(`/${item?.trim()}/`)){
                    isScrolling = false;
                    console.log('MainLayout NOT isScrolling:',item);
                    break;
                }
            }            
          }  
          
          console.log('MainLayout Final isScrolling:',isScrolling);

          var extraClassName = '';
          if(isScrolling)
            extraClassName = 'MainLayoutContentScroll';
               
        //return <div className='appRoot' >
        return <div  >
        
               <LIB.Loading show={loading} isModal={true} />
   {/*      <CssBaseline /> */}
        <TopMenu /> 
       

            <div className={`MainLayoutContent ${extraClassName} ${this.props.className || ''}`} /* style={{marginLeft:marginLeft}} */ 
             style={{height:isScrolling?'unset':undefined}} 
            >
                  {content}
 
                
             </div>  

          {/*   {content} */}

           {impersonateModal}


        </div>
        ;
    }
}
 