import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_DLA_LabelMakerModuleState } from "../Context/LY_DLA_LabelMakerModuleState";

export class LY_DLA_LabelMakerModuleServiceManager {


  public forceUpdate: () => void;
  public state: LY_DLA_LabelMakerModuleState;
  public props: LY.LY_DLA_LabelMakerModuleProps;
  public context: LY.LY_DLA_LabelMakerModuleContextProps;


  public listDataService: App.ListDataService;
  public sm: Api.ListViewGetDataSm;

  constructor(props: LY.LY_DLA_LabelMakerModuleProps,
    state: LY_DLA_LabelMakerModuleState,
    context: LY.LY_DLA_LabelMakerModuleContextProps) {


    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;

    this.sm = new Api.ListViewGetDataSm()

    // this.service = new App.ListViewService(props.workspaceId)
    //this.listDataService = new App.ListDataService(props.workspaceId);

   // this.listDataService.linkedWorkspaceId = this.props.linkedWorkspaceId;


  }

  public onServiceError(error: any) {

    if (this.state == undefined)
      return;

    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    App.AppBase.showError(errorMessage)
    this.state.isActionInProgress = false;
    this.forceUpdate();

  }

  /*   public createView(sm: Api.ListViewSm, setView: React.Dispatch<React.SetStateAction<Api.ListView>>, close: Function) {
  
      console.log('LY_DLA_LabelMakerModuleServiceManager createView start sm:', sm);
   
      this.service.createView(sm,
  
        //onSuccess
        (result: Api.ListView) => {
          console.log('LY_DLA_LabelMakerModuleServiceManager createView onSuccess:', result);
  
          //todo code
          this.state.isActionInProgress = false;
          App.AppBase.showSaveSuccess();
        
          this.forceUpdate();
        },
        //onError
        this.onServiceError.bind(this)
      );
  
      this.state.isActionInProgress = true;
      this.forceUpdate();
   
    } */


 


}
