import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import { Combobox, ComboboxOptionProps } from '@mantine/core';

export class ListState extends LIB.BaseStateModel {

  public workspace: Api.Workspace;
  public workspaceId: number=-1;
  public linkedWorkspaceId?: number;

  public vm: App.ListVm;
  public newItem: LY.DataItemModel = new LY.DataItemModel();
  public newColumn: Api.ListColumn = new Api.ListColumn();
  public selectedColumn: Api.ListColumn = new Api.ListColumn();
  public aggregationModel:any;

  public selectedCellModel?: LY.DataItemModel;
  public selectedCellElement: HTMLElement;

  public list: Api.List= new Api.List();
  public listId:string;
  public view: Api.ListView = new Api.ListView();

 // public columns: Api.ListColumn[]=[];
  public columnTypes: Api.ListColumnType[]=[];

  public records: any[]=[];
  public selectedItems: any[] = [];


  public selectedTabValue:any;

  public recordsCount:number=0; 

   public listFilterSm: Api.ListGetDataSm = new Api.ListGetDataSm();
   //public listFilter: Api.QueryFilter = new Api.QueryFilter();
 

  public hasPageDataLoaded: boolean = false;
  public isPageCreateUpdatePending: boolean = false;
 
  public isLinkedList: boolean = false;


  public isSearching: boolean;

  public forceReadOnly: boolean = false;
  public hideCheckboxSelection: boolean = false;


  public dropDownData:Record<string, Array<Record<string, any>>>;

  
  public lookupTypes?:Api.LookupType[]=[];

  public workspaceLookupTypes?: Api.SystemLookupType[] | undefined;

  constructor() {

    super();
 
    this.vm = new App.ListVm();
    this.newItem = new LY.DataItemModel();
    // this.listFilterSm.filter = new Api.QueryFilter();
  }



}