import * as Api from "app-api";
import * as LIB from "_LIB";

import { LY_ListFilterModuleState } from "../Context/LY_ListFilterModuleState";

export class ValidateManager extends LIB.BaseManager {

    public state: LY_ListFilterModuleState;
    public forceUpdate: Function;

    constructor(state: LY_ListFilterModuleState, forceUpdate: Function) {
        super("id");
        this.state = state;
        this.forceUpdate = forceUpdate;
    }


    public getErrorKey(field: string, rowIndex: number, groupIndex?: number) {

        return groupIndex !== undefined ? groupIndex + field + rowIndex : rowIndex + field;
    }

    public validateOne(condition: Api.FilterCondition, rowIndex: number, groupIndex?: number) {
        
        console.log('LY_ListFilterModule validateOne condition:', condition);
        //["name", "value", "op"]
        var errors: any = this.state.errors;
 
        var nameErrorKeyName = this.getErrorKey('name', rowIndex, groupIndex);
        var valueErrorKeyName = this.getErrorKey('value', rowIndex, groupIndex);
        var opErrorKeyName = this.getErrorKey('op', rowIndex, groupIndex);
   
        if (LIB.Common.isNullOrEmpty(condition.name)) {
            errors.set(nameErrorKeyName, 'Name is required');
        }
        else 
           errors.delete(nameErrorKeyName);

        if (LIB.Common.isNullOrEmpty(condition.op)) {
            errors.set(opErrorKeyName, 'Operator is required');
        }
        else 
           errors.delete(opErrorKeyName);

        if (LIB.Common.isNullOrEmpty(condition.value) && 
            condition.op != Api.FilterConditionOperatorEnum.IsNotNull && 
            condition.op != Api.FilterConditionOperatorEnum.IsNull) {
            errors.set(valueErrorKeyName, 'Value is required');
        }
        else 
           errors.delete(valueErrorKeyName);

        
        this.forceUpdate();
        return errors.length > 0;
    }


    public validateAll() {

 
        this?.state?.view?.filter?.conditionSets?.forEach((conditionSet, groupIndex) => {

            conditionSet.conditions.forEach((condition, rowIndex) => {
                this.validateOne(condition,rowIndex, groupIndex);
            })
        })


        this?.state?.view?.filter?.conditionSet?.conditions?.forEach((condition, rowIndex) => {
            this.validateOne(condition, rowIndex)
        })
    }
}
