import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import { LY_ListFilterModuleContextProps } from "../Context/LY_ListFilterModuleContext";
import { LY_ListFilterModuleState } from "../Context/LY_ListFilterModuleState";

export class FiltersManager extends LIB.BaseManager {
  vm: LIB.BaseVmModel;

  public view: Api.ListView;
  public props: LY.LY_ListFilterModuleProps;
  public state: LY_ListFilterModuleState;
  public forceUpdate: Function;
  public currentWorkspace = App.AppBase.currentWorkspace;
  public service: App.ListViewService;



  constructor(_view: Api.ListView,
    _props: LY.LY_ListFilterModuleProps,
    state: LY_ListFilterModuleState, forceUpdate: Function) {
    super("id");
    this.vm = new LIB.BaseVmModel();
    this.view = _view;
    this.props = _props;
    this.state = state;
    this.forceUpdate = forceUpdate;

    var workspaceId = this.currentWorkspace?.workspaceId;

    this.service = new App.ListViewService(workspaceId);
    this.service.linkedWorkspaceId = this.props.linkedWorkspaceId;
  }

  public getView(setView: React.Dispatch<React.SetStateAction<Api.ListView[]>>) {
    //var workspaceId = Number(process.env.REACT_APP_LOOCEY_WORKSPACE_ID);
    var workspaceId = this.view.workspaceId;


    var sm = new Api.ListViewGetDataSm();

    return this.service.getListViews(sm, (data: Api.ListView[]) => {
      setView(data);
    });

    /* 
    
        service.getListViews(sm,
          //onSuccess
          (result: Api.ListDropDownDataResult) => {
            console.log('LY_DynamicDropDown onSuccess:', result);
       
            vm.isActionInProgress = false;
            forceUpdate();
          },
          //onError
          onServiceError
        );
    
        vm.isActionInProgress = true;
        forceUpdate(); */



  }

  public createView(sm: Api.ListViewSm, setView: React.Dispatch<React.SetStateAction<Api.ListView>>, close: Function) {

    console.log('FiltersManager createView start sm:', sm);

    //var workspaceId = this.view.workspaceId;

    var vm = this.state;

    if (!this.view)
      return;

    sm.filter = this.view.filter!;
    sm.listId = this.view.listId!;
    sm.sortOrder = (this.view.sortOrder || 0) + 1;


    this.service.createView(sm,
      //onSuccess
      (result: Api.ListView) => {
        console.log('FiltersManager createView onSuccess:', result);

        vm.isActionInProgress = false;
        App.AppBase.showSaveSuccess();
        if (this.props.onSaveAsNewViewSuccess)
          this.props.onSaveAsNewViewSuccess(result as Api.ListViewSm);

        setView(result);
        close();
        this.forceUpdate();
      },
      //onError
      this.onServiceError.bind(this)
    );

    vm.isActionInProgress = true;
    this.forceUpdate();

    /*     return service.createView(sm, (data: any) => {
          setView(data);
          close();
        }); */

  }


  public updateView(view: Api.ListView) {



    var sm = new Api.ListViewSm();

    if (view.filter) sm.filter = view.filter

    sm.name = view.name;
    sm.listId = view.listId;
    sm.listViewId = view.listViewId!;
    sm.sortOrder = view.sortOrder;
    sm.detailSortOrder = view.detailSortOrder;
    sm.isPinned = view.isPinned;
    sm.iconName = view.iconName;
 

    /*     return service.updateView(sm, (data: any) => {
          console.log(data);
        }); */

    return this.service.updateView(sm,
      //onSuccess
      (result: any) => {
        console.log('updateView onSuccess:', result);

        App.AppBase.showSaveSuccess();

      },
      //onError
      this.onServiceError.bind(this)
    );

  }



  public onServiceError(error: any) {

    console.error('FiltersManager onServiceError:', error);

    if (this.state == undefined)
      return;

    //console.error('FiltersManager onServiceError 2:', error);

    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

    App.AppBase.showError(errorMessage);
    var vm = this.state;
    vm.isActionInProgress = false;
    vm.errors.set('all', errorMessage);
    this.forceUpdate();

  }
}
