import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';
import * as MT from '@mantine/core';
import * as React from "react";

import { IconDots, IconLock, IconTable, IconTrash } from "@tabler/icons-react";

import { rem } from "@mantine/core";

interface LY_ListViewTabsUpdateSettingsContextMenuProps extends LY.LY_ListViewTabsModuleProps {
    item: Api.ListViewSm
}


export const LY_ListViewTabsUpdateSettingsContextMenu: React.FC<LY_ListViewTabsUpdateSettingsContextMenuProps> = ({ item }) => {


    const context = LY.useListViewTabsContext();
    const currentUser = App.AppBase.currentUser;
    const { state } = context;

   // console.log('LY_ListViewTabsUpdateSettingsContextMenu currentUser', currentUser);
  //  console.log('LY_ListViewTabsUpdateSettingsContextMenu item', item);

    var isMenuOpen = state.tabContextMenuOpenId === item.listViewId;

    var hasContextMenu = !item.isLinkedShared && !item.isLinkedView;
    if(!hasContextMenu && item.companyId==currentUser.companyId && currentUser.companyId)
        hasContextMenu = true;

    return (
        <MT.Menu shadow="md" width={200} opened={isMenuOpen}
            onClose={() => {
                console.log('LY_ListViewTabsUpdateSettingsContextMenu onClose');
                state.tabContextMenuOpenId = -1;
                context.forceUpdate();

            }}
            arrowSize={12}
            withArrow
        >

            <MT.Menu.Target

            >
                <div className="LY_Icon"
                   onClick={(e: React.MouseEvent) => {
                    console.log('LY_ListViewTabsUpdateSettingsContextMenu clicked item', item);
                    e.preventDefault();
                    e.stopPropagation();

                    state.tabContextMenuOpenId = item.listViewId;
 
                    context.forceUpdate();

                }}
                >
 
                  
                   {hasContextMenu && <IconDots className="LY_DotsIcon" size={17} />}
                     
                  
                </div>
            </MT.Menu.Target>

            <MT.Menu.Dropdown>

                <MT.Menu.Item
                    onClick={(e: React.MouseEvent) => {

                        e.preventDefault();
                        e.stopPropagation();

                        console.log('LY_ListViewTabsUpdateSettingsContextMenu Settings item', item);


                        state.isNew = false;
                        state.isTabSettingsOpen = true
                        state.selectedSettingsItem = item;
                        state.updateItem = { ...item };

                        context.forceUpdate();
 
                    }}
                    leftSection={<IconTable style={{ width: rem(14), height: rem(14) }} />}
                >
                    Settings
                </MT.Menu.Item>
                <MT.Menu.Item disabled={item.isLocked}
                    leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}

                    onClick={(e: React.MouseEvent) => {

                        e.preventDefault();
                        e.stopPropagation();

                        state.isDeleteModalOpen = true
                        state.selectedSettingsItem = item;

                        context.forceUpdate();


                    }}>
                    Delete
                </MT.Menu.Item>
            </MT.Menu.Dropdown>
        </MT.Menu>
    );
}