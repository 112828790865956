import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Api from 'app-api';

import './DlaListRightPanelContainer.css';
import { Badge, Tooltip, Tabs, rem } from '@mantine/core';
import * as Icons from '@tabler/icons-react';
import { LY_NotesModule } from '_LY_Components/_Modules/LY_NotesModule/LY_NotesModule';
 

interface DlaListRightPanelContainerProps {
  state?: App.ListState | App.DetailState;
  model?: LY.DataItemModel;
  onChange?: Function;
  onClosePanelClick: Function;

}

export const DlaListRightPanelContainer: React.FC<DlaListRightPanelContainerProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const currentUser = App.AppBase.currentUser;
  const currentWorkspace = App.AppBase.currentWorkspace;
  const list = props.state?.list;
 

  function getSupplierQuotes(){

    console.log('getSupplierQuotes');
 
    //todo
/*     1. Create an api call to get all supplier quotes for all the list items in the current page
       2. filter an show

*/


  }

  function render() {

    var item = props.model;
    var state = props.state;

    var forceReadOnly =false;

    if(state instanceof App.ListState)
      forceReadOnly = state?.forceReadOnly; 
    
    const iconStyle = { width: rem(12), height: rem(12) };

    return (
      <div className="DlaListRightPanelContainer">

        <Tabs defaultValue="form" className='DlaListRightPanelContainerTabs'>
          <Tabs.List>
          <Tabs.Tab value="form" leftSection={<Icons.IconForms style={iconStyle} />}>
              Form
            </Tabs.Tab>

            <Tabs.Tab value="notes" leftSection={<Icons.IconMessage style={iconStyle} />}>
              Notes 
            </Tabs.Tab>

{/*             <Tabs.Tab value="supplier_quotes" leftSection={<Icons.IconMessage style={iconStyle} />}>
            Supplier Quotes
            </Tabs.Tab> */}
  
          </Tabs.List>


          <Tabs.Panel value="form" className='DlaListRightPanelContainerTabPanel'>

 
           <LY.LY_RecordUpdateModule 
            workspaceId={state?.workspaceId}
            linkedWorkspaceId={state?.linkedWorkspaceId}
            listId={state?.list?.listId!}
            view={state?.view}
            columns={state?.view?.columns}
            lookupTypes={currentWorkspace?.lookupTypes}
            dropDownData={state?.dropDownData}
            row={item?.row}
            isModal={false}
            onClose={props.onClosePanelClick}
            formContainerClassName={'DlaListRightPanelContainerFormContainer'}
            formContainerItemClassName={'DlaListRightPanelContainerFormContainerItem'}

           />
 


          </Tabs.Panel>

          <Tabs.Panel value="notes" className='DlaListRightPanelContainerTabPanel'>

          <LY_NotesModule
                workspaceId={state?.workspaceId}
                currentUser={currentUser}
                recordId={item?.rowId}
                className='LY_NotesModuleRightPanel'
              />

          </Tabs.Panel>

        
          <Tabs.Panel value="supplier_quotes" className='DlaListRightPanelContainerTabPanel'>
           Coming Soon...
          </Tabs.Panel>

 

        </Tabs>


      </div>
    );

  }




  return render();
};
