//import * as LIB from '_LIB';

export class BaseVmModel  {
 
 
    public isActionInProgress:boolean;
    public apiErrorMessage:string;
    public hasDataLoaded:boolean=false;

   // public errors:any = {};
    public errors:Map<string, any> =  new Map<string, any>();
    

    constructor() { 

          
        this.isActionInProgress = false;
        this.hasDataLoaded = false;
        this.apiErrorMessage = "";
    
    }
    
  /* 
    public setDefaults(){

        
        this.isActionInProgress = false;
        this.apiErrorMessage = "";

    }

  
    public hasSmData(val:any):boolean{
        if(!LIB.Common.isNullOrEmpty(this.apiErrorMessage) || !LIB.Common.isNullOrEmpty(val) || this.isActionInProgress)
            return true;

        return false;

    }
      */


}