import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_BulkEmailSenderModule_Container.css";
import { Button, Tabs } from '@mantine/core';


import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
import { LY_BulkEmailSenderModule_ItemList } from '../LY_BulkEmailSenderModule_ItemList/LY_BulkEmailSenderModule_ItemList';
import { LY_BulkEmailSenderModule_Form } from '../LY_BulkEmailSenderModule_Form/LY_BulkEmailSenderModule_Form';

export const LY_BulkEmailSenderModule_Container: React.FC<LY.LY_BulkEmailSenderModuleProps> = (props) => {


  const context = LY.useBulkEmailSenderModuleContext();
  const { state, services } = context;
  var item = state.selectedItem;

  const [ copied, setCopied ] = React.useState(false);



  function onClose() {
    console.log('LY_BulkEmailSenderModule_Container onClose', props.onClose);

    context.onCancelOrCloseReset();


  }

 /* 
  function copyEmailContentToClipBoard() {
    console.log('LY_BulkEmailSenderModule_Container copyEmailContentToClipBoard');

    var divRef = state.refEmailContentDiv;
    if (!divRef)
      return;

      LIB.ClipboardHelper.copyToClipBoard(divRef.innerHTML);

 
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }; */

 
 

  function getButtonsRow() {

    return <div className='LY_BulkEmailSenderModule_Container_Buttons'>
      <div>
        Selected Records: {state.selectedEmailToItems?.length}
      </div>
      <LY.LY_ButtonCancel
        onClick={() => {
          context.onCancelOrCloseReset();
        }}
      >
        Cancel
      </LY.LY_ButtonCancel>


      <LY.LY_CopyButton
      htmlValue={state.selectedItem?.body}
        //onClick={copyEmailContentToClipBoard}

       // color={copied ? 'green' : 'blue'}
      />
      {/*   {copied ? 'Copied' : 'Copy'}
     */}



      <LY.LY_Button
        loading={state.isActionInProgress}
        loaderProps={{ type: 'dots' }}
        onClick={() => {
          // context.updateViews();
        }}
        disabled={true}
        title='Coming Soon...'
      >
        Send
      </LY.LY_Button>

    </div>
  }




  function render() {
    console.log('LY_BulkEmailSenderModule_Container render state', state);
    console.log('LY_BulkEmailSenderModule_Container render state.isContainerModalOpen', state.isContainerModalOpen);

    const isMobile = useIsMobile();

    var container = <div className='LY_BulkEmailSenderModule_Container'>
      <div className='LY_BulkEmailSenderModule_Container_Main'>

        <LY.Loading show={state.isActionInProgress} isModal={true} />

        <LY_BulkEmailSenderModule_ItemList {...props} />

        <LY_BulkEmailSenderModule_Form {...props} />
      </div>
      
      {getButtonsRow()}
    </div>


    if (!props.isModal)
      return container;


    return (
      <LY.LY_Modal
        title='Bulk Email Campaign'
        //size="xl"
        size="auto"
        padding="md"
        onClose={onClose}
        opened={state.isContainerModalOpen}
        className='LY_BulkEmailSenderModule_ContainerModal'
        classNames={{ body: 'LY_BulkEmailSenderModule_ContainerModalBody',
                      header: 'LY_BulkEmailSenderModule_ContainerModalHeader' }}
        closeOnClickOutside={false}
        zIndex={8000}
      >

        {container}

      {/*   <MT.Group mt="xl">
          <Button  >Add badge</Button>
          <Button  >Remove badge</Button>
        </MT.Group> */}
      </LY.LY_Modal>
    );



  }




  return render();
};
