import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import * as MT from "@mantine/core";

import * as Api from 'app-api';

import React from "react";
import { Avatar, Combobox, Text } from "@mantine/core";

import "./LY_MultiDropDown.css";


export interface LY_MultiDropDownProps extends LY.LY_DropDownProps {

  valueProperty: string;
  displayProperty: string;
  selectedItems?: any[]
  isSingleSelect?: boolean; 
  maxSelectedItemsShowing?: number;

  onSelectedItemChange?: (val: any | null, isSelected: boolean, isClear: boolean,
    optionProps?: MT.ComboboxOptionProps | undefined, combobox?: MT.ComboboxStore | undefined) => void;
 
  multiOptionsItemRenderer?: (data: any[], selectedItems: any[], combobox?: MT.ComboboxStore | undefined) => React.ReactElement[] | undefined;
  isCheckboxStyle?: boolean;

  
  customSingleSelectedItemRenderer?: (
    combobox?: MT.ComboboxStore | undefined
  ) => React.ReactElement | undefined;

  onCustomSingleItemChange?: ( item: any, optionProps?: MT.ComboboxOptionProps | undefined) => void;
  customSelectedItemsInfoContainerClassName?: string;

  customMultipleItemOptionRenderer?: ( data: any[], selectedItems: any[], selectedItem: any, combobox?: MT.ComboboxStore | undefined
  ) => React.ReactElement[] | undefined;

  customMultipleSelectedItemsRenderer?: ( selectedDisplayItems: any[], selectedItems: any[] ) => any[];


}

export const LY_MultiDropDown = React.forwardRef<HTMLInputElement, LY_MultiDropDownProps>((
  {
    name,
    value,
    displayProperty,
    valueProperty,
    placeholder,
    defaultValue,
    inputClassName,
    model,
    style,
    maxSelectedItemsShowing=3,
    customSelectedItemsInfoContainerClassName,
    customMultipleSelectedItemsRenderer,
    customMultipleItemOptionRenderer,
    isCheckboxStyle = false,
    ...props
  },
  ref
) => {

 
 
  const [ data, setData ] = React.useState<any[]>([]);
  const [ selectedItems, setSelectedItems ] = React.useState<any[]>([]);
 

  React.useEffect(() => {


  /*   console.log(`LY_MultiDropDown useEffect data: `, props.data );
    console.log(`LY_MultiDropDown useEffect selectedItems: `, props.selectedItems );

 */

    let selected = props.selectedItems;

    
    if(!selected && value)
      selected = [value];


    setSelectedItems(selected || []);
    setData(props.data || []);
 

  }, [ props.selectedItems, props.data ]); 
 

  function getOptionsItems(combobox: MT.ComboboxStore | undefined):React.ReactElement[]|undefined {


    if(props.multiOptionsItemRenderer)
      return props.multiOptionsItemRenderer(data, selectedItems, combobox);
    else if (customMultipleItemOptionRenderer) 
        return customMultipleItemOptionRenderer( data,selectedItems,props.selectedItem,combobox);
    else if(isCheckboxStyle)
      return getCheckboxOptionsItems(combobox);
 

 
    let options = data?.map((item, index) => {
 
        // console.log('LY_DropDown item item: ',item);
        // console.log('LY_DropDown item index: ',index);

       var isActive = !!selectedItems?.filter(i => i[ valueProperty ] === item[ valueProperty ]).length;

        var activeClassName = isActive ? 'LY_MultiDropDown_OptionActive' : '';
        var key = `ComboboxOptionKey_${valueProperty}_${item[ valueProperty! ]}_${index}`;

        //console.log('LY_DropDown item key: ',key);

        return <Combobox.Option
          key={key}
          className={'LY_MultiDropDown_Option' + ' ' + activeClassName}
          value={item[ valueProperty ]}
          selected={isActive} >

         {item[ displayProperty ]}


        </Combobox.Option>
      });

    return options;
  }

  function getCheckboxOptionsItems(combobox: MT.ComboboxStore | undefined):React.ReactElement[]|undefined {


    if(!isCheckboxStyle)
      return undefined;

      console.log('LY_MultiDropDown getCheckboxOptionsItems', data);

 
    let options = data?.map((item, index) => {
  
       var isActive = !!selectedItems?.filter(i => i[ valueProperty ] === item[ valueProperty ]).length;

        var activeClassName = isActive ? 'LY_MultiDropDown_OptionActive' : '';
        var key = `ComboboxOptionKey_${valueProperty}_${item[ valueProperty! ]}_${index}`;

        //console.log('LY_DropDown item key: ',key);

        return <Combobox.Option
          key={key}
          className={'LY_MultiDropDown_Option_Checkbox' + ' ' + activeClassName}
          value={item[ valueProperty ]}
          selected={isActive} >
 
         <LY.LY_CheckBox
              checked={isActive}
              name="LYMultiDropDownCheckBox"
              label={item[ displayProperty]}
              labelPlacement="right"
            />

        </Combobox.Option>
      });

    return options;
  }


  function getSelectedItemValue(item: any) {
    if (!item) return "";

    let result = item[ displayProperty ] || "";

    result = LY.ListColumnManager.getCalculatedValue(result, item, result);

    return result;
  }



  function onLocalChange(item: any | null, optionProps?: MT.ComboboxOptionProps | undefined, combobox?: MT.ComboboxStore | undefined) {

    console.log('LY_MultiDropDown onLocalChange item', item);

    //let's check if value is selected, if yes, unselect, else select by pushing into selectedItems array
  
    let isSelected = selectedItems?.find(x => x[ valueProperty ] === item[ valueProperty ]);

    console.log('LY_MultiDropDown onLocalChange isSelected', isSelected);


    if (isSelected) {
      let newSelectedItems = selectedItems.filter(x => x[ valueProperty ] !== item[ valueProperty ]);
      setSelectedItems(newSelectedItems);
    }
    else {
      selectedItems.push(item);
      setSelectedItems(selectedItems);
    }

    if(props.onSelectedItemChange)
      props.onSelectedItemChange(item, !isSelected, false, optionProps, combobox);

  }

  function onClearClick(optionProps?: MT.ComboboxOptionProps | undefined, combobox?: MT.ComboboxStore | undefined){

    setSelectedItems([]);

    if(props.onSelectedItemChange)
    props.onSelectedItemChange(null, false, true, optionProps, combobox);

  }

  function closeDropdown(combobox?: MT.ComboboxStore | undefined) {

    if (combobox?.dropdownOpened)
      combobox?.closeDropdown();

  }


  function getSelectedBadge(value: string) {
    var col = model?.column;
    return <MT.Badge color={col?.textColor || props.selectedItemBadgeColor || 'var(--ly-global-primary-text-color)'}
      variant={col?.styleVariant || props.selectedItemBadgeVariant || 'light'}
      m={2}
      size={col?.styleSize || props.selectedItemBadgeSize || 'lg'}
      radius={col?.styleRadius || props.selectedItemBadgeRadius || 'xl'}
      className='LY_MultiDropDown_SelectedBadge'
      style={{ justifyContent: 'left' }}
    >{value}
    </MT.Badge>
  }

  function getSelectedItemBadges() {


    let items = [];

    var selectedDisplayItems = selectedItems;
    
    if (maxSelectedItemsShowing && selectedItems?.length > maxSelectedItemsShowing)
        selectedDisplayItems = selectedDisplayItems.slice(0,maxSelectedItemsShowing);


  if (customMultipleSelectedItemsRenderer)
    items = customMultipleSelectedItemsRenderer(selectedDisplayItems,selectedItems);
    
  else
    selectedDisplayItems.forEach(x => items.push(getSelectedBadge(x[ displayProperty ])));
 
    if (maxSelectedItemsShowing && selectedItems.length > maxSelectedItemsShowing)
      items.push(getSelectedBadge(`+${selectedItems.length - maxSelectedItemsShowing}`));
 
    //console.log('LY_MultiDropDown getSelectedItem', items);

    return items;

  };

  function getSelectedItem(combobox?: MT.ComboboxStore | undefined) {

    if (props.customSingleSelectedItemRenderer )
    return props.customSingleSelectedItemRenderer(combobox);


    return (
      <div
        className="LY_MultiDropDown_SelectedItem"
        onClick={() => {

          if(props.readOnly)
            return;


          combobox?.dropdownOpened
            ? combobox?.closeDropdown()
            : combobox?.openDropdown();
        }}
        onBlur={() =>{
          if(props.readOnly)
            return;
          closeDropdown(combobox)
        }}
        
      >
        <div 
     className={
      customSelectedItemsInfoContainerClassName
        ? customSelectedItemsInfoContainerClassName
        : "LY_MultiDropDown_SelectedItem_Info"
    }>

          {getSelectedItemBadges()}
        </div>

        <div className="LY_MultiDropDown_SelectedItem_arrow">
          {combobox?.dropdownOpened ? (

            <Icons.IconCaretUpFilled width={16} />
          ) : (
            <Icons.IconCaretDownFilled size={16} />
          )}
        </div>


      </div>
    );
  };

  function getFooterRenderer(combobox?:MT.ComboboxStore|undefined) {


    return <Combobox.Footer
      className={'_LY_DropDownBaseViewEditFooter ' + props.footerContainerClassName || ''}
    >
      <div className='_LY_DropDownBaseViewEditFooterButton'
        onClick={(e) => {
          console.log('LY_MultiDropDown clear selectedItems');
          //e.preventDefault();
          
          onClearClick(undefined, combobox);

        }}
        aria-label="Clear value"
        style={{ cursor: 'pointer' }}
      >
        Clear
      </div>

      {props.footerRightSectionRenderer && props.footerRightSectionRenderer(combobox)}
    </Combobox.Footer>

  }

  function onLocalChangeSingle(item: any | null, optionProps?: MT.ComboboxOptionProps | undefined, combobox?: MT.ComboboxStore | undefined) {

    console.log('LY_MultiDropDown onLocalChange item', item);
    setSelectedItems([item]);

    if(props.onSelectedItemChange)
      props.onSelectedItemChange(item, true, false,optionProps, combobox);

    if(props.onChange)
      props.onChange(item, optionProps, combobox);

  }

  function onSearchChange( value: string, combobox: MT.ComboboxStore | undefined, filtered?: any[]) {

    console.log('LY_MultiDropDown onSearchChange value:',value);
    console.log('LY_MultiDropDown onSearchChange filtered:',filtered);
 
    setData(filtered || []);

    console.log('LY_MultiDropDown onSearchChange data:',data);


  /*   if (filtered) 
        setData(filtered || []);
    else 
        setData(data || []); */
  }


  function renderSingle() {

   // console.log('LY_MultiDropDown render');
 
    var result = (
      <LY.LY_DropDown

        ref={ref}
        name={name}
        data={data}
        displayProperty={displayProperty}
        valueProperty={valueProperty}
        value={value}
        placeholder={placeholder}
        model={model}
        isAutoCompleteStyle={props.isAutoCompleteStyle}
        hideSearch={props.isSingleSelect}
        withArrow={false}
        onChange={onLocalChangeSingle}
        optionsContainerClassName={
          props.optionsContainerClassName +
          " LY_MultiDropDown_OptionsContainer"
        }
        optionsContainerStyle={{
          ...props.optionsContainerStyle,
          padding: "10px",
        }}
        optionsMaxHeight={props.optionsMaxHeight}
        searchInputContainerClassName="LY_MultiDropDown_SearchInputContainer"
        searchInputClassName="LY_MultiDropDown_SearchInput"   
        closeDropDownOnChange={true}
        onSearchChange={onSearchChange}
        
        footerRenderer={getFooterRenderer}
        selectedItemRenderer={getSelectedItem}
        optionsItemRenderer={getOptionsItems}

        {...props}
      />
    );

    return result;
  }

  function render() {

   // console.log('LY_MultiDropDown render');

    if (props.isSingleSelect)
      return renderSingle();
 
    var result = (
      <LY.LY_DropDown

        ref={ref}
        name={name}
        data={data}
        displayProperty={displayProperty}
        valueProperty={valueProperty}
        //value={value}
        placeholder={placeholder}
        model={model}
        isAutoCompleteStyle={false}
        withArrow={false}
        footerRenderer={getFooterRenderer}
        selectedItemRenderer={getSelectedItem}
        optionsItemRenderer={getOptionsItems}
      

        onChange={onLocalChange}
        optionsContainerClassName={
          props.optionsContainerClassName +
          " LY_MultiDropDown_OptionsContainer"
        }
        optionsContainerStyle={{
          ...props.optionsContainerStyle,
          padding: "10px",
        }}

        optionsMaxHeight={props.optionsMaxHeight}
        // optionsTitle="Workspaces"

        //onValueChange={onLocalValueChange}
        // selectedItemDisplayValue={selectedWorkspaceDisplayValue}
        searchInputContainerClassName="LY_MultiDropDown_SearchInputContainer"
        searchInputClassName="LY_MultiDropDown_SearchInput"
       // searchInputRightIcon={<Icons.IconSearch color="#989899" size={22} />}
        //   editContainerClassName="LY_MultiDropDown_EditContainer"
        //  inputContainerClassName="LY_MultiDropDown_Container"
        hideSearch={false}
        closeDropDownOnChange={props.isSingleSelect}
        onSearchChange={onSearchChange}
        readOnly={props.readOnly}
        {...props}
      />
    );

    return result;
  }
  return render();
}
);
