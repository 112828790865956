import "./LY_NotesModule_Update_Input.css";
import React from "react";
import * as Api from "app-api";

import { Textarea } from "@mantine/core";
import { useNotesModuleContext } from "../../Context/LY_NotesModuleContext";
import * as Icons from "@tabler/icons-react";

import { LY_NotesModuleProps } from "../../Interfaces/LY_NotesModuleProps";

interface LY_NoteModule_Update_Input_Props extends LY_NotesModuleProps {
  note?: Api.Note;
  reply?: Api.NoteReply;
}

export const LY_NotesModule_Update_Input: React.FC<
  LY_NoteModule_Update_Input_Props
> = (props) => {
  const context = useNotesModuleContext();

  React.useEffect(() => {
    if (props.reply) {
      context.setUpdatedMessage(props.reply?.message!);
    } else {
      context.setUpdatedMessage(props.note?.message!);
    }
  }, []);

  function onUpdateInputClick() {
    if (props.reply) {
      if (context.state.updatedMessage.trim()) {
        context.updateReplyMessage();
        context.setUpdatingReply(false);
      }
    } else {
      if (context.state.updatedMessage.trim()) {
        context.updateNoteMessage();
        context.setUpdatingNote(false);
      }
    }
  }

  function onNoteKeyDown(e: React.KeyboardEvent<any>) {
    console.log('_LY_DropDownBaseViewEdit onKeyDown key:', e.key);

    if (e.key === 'Enter' && !e.shiftKey) {
      onUpdateInputClick();
    }

}


  function render() {
    return <div>
      <Textarea
        className="LY_NoteModule_Update_Input"
        value={context.state.updatedMessage}
        onChange={(e) => context.setUpdatedMessage(e.currentTarget.value)}
        autosize
        onKeyDown={onNoteKeyDown}

        classNames={{
          input: "LY_NoteModule_Update_Input_Container",
          wrapper: "LY_NoteModule_Update_Input_Wrapper",
        }}
        rightSection={
          <Icons.IconSend2
            className="LY_NoteModule_Update_Input_SendIcon"
            aria-label="Clear input"
            onClick={onUpdateInputClick}
          />
        }
      />
      <div style={{fontSize:10, paddingLeft:25}}>Press Shift+Enter for New Line</div>

  </div>;
  }

  return render();
};
