import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import './LY_ColorSelector.css';


import { EditorBubbleItem, EditorInstance, useEditor } from "novel";
import { Button, Popover } from "@mantine/core";


export interface BubbleColorMenuItem {
  name: string;
  color: string;
}

const TEXT_COLORS: BubbleColorMenuItem[] = [
  {
    name: "Default",
    color: "var(--novel-black)",
  },
  {
    name: "Purple",
    color: "#9333EA",
  },
  {
    name: "Red",
    color: "#E00000",
  },
  {
    name: "Yellow",
    color: "#EAB308",
  },
  {
    name: "Blue",
    color: "#2563EB",
  },
  {
    name: "Green",
    color: "#008A00",
  },
  {
    name: "Orange",
    color: "#FFA500",
  },
  {
    name: "Pink",
    color: "#BA4081",
  },
  {
    name: "Gray",
    color: "#A8A29E",
  },
];

const HIGHLIGHT_COLORS: BubbleColorMenuItem[] = [
  {
    name: "Default",
    color: "var(--novel-black)",
  },
  {
    name: "Purple",
    color: "#9333EA",
  },
  {
    name: "Red",
    color: "#E00000",
  },
  {
    name: "Yellow",
    color: "#EAB308",
  },
  {
    name: "Blue",
    color: "#2563EB",
  },
  {
    name: "Green",
    color: "#008A00",
  },
  {
    name: "Orange",
    color: "#FFA500",
  },
  {
    name: "Pink",
    color: "#BA4081",
  },
  {
    name: "Gray",
    color: "#A8A29E",
  },
];

interface ColorSelectorProps extends LY.LY_SlashEditorBaseProps {
  editor: EditorInstance | null;

  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const LY_ColorSelector = ({ open, onOpenChange, editor, ...props }: ColorSelectorProps) => {
/*   const { editor } = useEditor();

 */

  const activeColorItem = TEXT_COLORS.find(({ color }) => editor?.isActive("textStyle", { color }));
  const activeHighlightItem = HIGHLIGHT_COLORS.find(({ color }) => editor?.isActive("highlight", { color }));

/*   
  function render() {

    if (!editor) return null;

  } */

  function render() {

    if (!editor) return null;

    return <Popover
      opened={open}
      onClose={() => onOpenChange(false)}
      position="bottom-start"
      withArrow
       arrowSize={10}
      shadow="md"
      withinPortal={true}
      arrowPosition='side'
     closeOnClickOutside={true}
 
     zIndex={9999}
     
    >
      <Popover.Target>

    <LY.LY_ButtonPinkHover
          isActive={open}
          title={props.isTextButton ? undefined : 'Colors'}
          onClick={() => onOpenChange(!open)}
        >
          {props.isTextButton ?
            <div className="LY_SlashEditor_ButtonText" >
              <Icons.IconPalette size={18} color='#757B87' />
              <span>Colors</span>
            </div>
            :
            <Icons.IconPalette size={20} />}

        </LY.LY_ButtonPinkHover>  

{/* 
        <Button size="sm" variant="subtle" onClick={() => onOpenChange(!open)}>
          <span
            className="LY_ColorSelector_ButtonText"
            style={{
              color: activeColorItem?.color,
              backgroundColor: activeHighlightItem?.color,
            }}
          >
            A
          </span>
          <Icons.IconChevronDown size={15} color="#099CFF" /> 
        </Button>*/}
      </Popover.Target>
      <Popover.Dropdown style={{ maxHeight: 300, overflowY: 'auto', padding: '10px', width: 200 }}>
        <div className="LY_ColorSelector_Dropdown">
          <div className="LY_ColorSelector_DropdownHeader">Color</div>
          {TEXT_COLORS.map(({ name, color }) => (
            <EditorBubbleItem
              key={name}
              onSelect={() => {
                editor.commands.unsetColor();
                if (name !== "Default") {
                  editor.chain().focus().setColor(color || "").run();
                }
                onOpenChange(false);
              }}
              className="LY_ColorSelector_DropdownItem"
            >
              <div className="LY_ColorSelector_DropdownItemContent">
                <div className="LY_ColorSelector_DropdownColorBox" style={{ color }}>
                  A
                </div>
                <span>{name}</span>
              </div>
            </EditorBubbleItem>
          ))}
        </div>
        <div>
          <div className="LY_ColorSelector_DropdownHeader">Background</div>
          {HIGHLIGHT_COLORS.map(({ name, color }) => (
            <EditorBubbleItem
              key={name}
              onSelect={() => {
                editor.commands.unsetHighlight();
                if (name !== "Default") {
                  editor.chain().focus().setHighlight({ color }).run();
                }
                onOpenChange(false);
              }}
              className="LY_ColorSelector_DropdownItem"
            >
              <div className="LY_ColorSelector_DropdownItemContent">
                <div className="LY_ColorSelector_DropdownColorBox" style={{ backgroundColor: color }}>
                  A
                </div>
                <span>{name}</span>
              </div>
              {editor.isActive("highlight", { color }) && <Icons.IconCheck height={4} width={4} />}
            </EditorBubbleItem>
          ))}
        </div>
      </Popover.Dropdown>
    </Popover>
  }

  return render()
};
