import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

import './Pricing.css';

//import styles from './Pricing.module.css';
//styles.LY_Pricing_Limited

interface BillingPlansProps {

}

class Pricing extends React.Component<App.GuestUserState, {}> {


  constructor(props: any) {
    super(props);
    
  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('Register componentDidMount');

    if(App.AppBase.isUserAuthenticated){
      App.AuthManager.logout();
      this.forceUpdate();
    }
    const vm = this.props.vm;
    var sm = this.props.registerSm;

    
    //sm.offerId = LIB.UrlHelper.getQueryString("offerId");
    sm.coupon = LIB.UrlHelper.getQueryString("coupon");
    //sm.planName = LIB.UrlHelper.getQueryString("plan");
    sm.planId = LIB.UrlHelper.getQueryString("planId");


    if(!this.props.hasDataLoaded)
       App.AppStore.dispatch(App.ActionTypes.GUESTUSER_GET_PAGE_DATA,sm);
 
    console.log('Register componentDidMount sm',sm);


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    //console.log('Pricing componentDidUpdate');
  }

 

  public render() {

    console.log("Pricing Render", this.props);
    
    var state = this.props;
    var vm = state.vm;
    var offer = vm.offer;

    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={true}
      loading={state.isServiceCallPending}
      //loading={true}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      //hasNoPageDesign={true}
      mainPanelsClassName="LY_Pricing_MainPanels"
      mainPageContainerClassName='LY_Pricing_MainPageContainer'
      mainPanelsContentClassName='LY_Pricing_MainPanelsContent'
     // isChildPage={true}

    >
        <div style={{textAlign:'center'}}>
          <h1 className="LY_Pricing_Title"> 

          Loocey CRM with DLA/Dibbs Module (BETA)
          
          </h1>
          <h2 className="LY_Pricing_SubTitle">
          Super-Charge your DLA/Dibbs Supplier Business with the BEST TOOL 

          </h2>

          <h2 className="LY_Pricing_SubTitle">
          10x the Number Of Bids you place and <span >WIN More Contracts</span>
          </h2>

{/* 
          <p className="LY_Pricing_Limited">
            
          <div >**** Limited Participants, By Invite Only ****</div>
          <div >Please DO NOT Share this Link with anyone</div>

          </p> */}


        </div>
        <App.BillingPricingTable 

        billingPlans={state.billingPlans}
        pricingGridClassName="LY_Pricing_PricingGrid"
        registerSm={this.props.registerSm}
        offer={offer}
        />



    </App.MainSplitPage>
      ;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.guestUserData })
)(Pricing as any);

