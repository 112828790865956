import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';

import * as React from "react";

import { IconLayoutKanban, IconTable } from '@tabler/icons-react';

 
import { LY_ListViewTabsNewItemContextMenu } from "../LY_ListViewTabsNewItemContextMenu/LY_ListViewTabsNewItemContextMenu";
import { LY_ListViewTabsCreateNewViewModal } from "../LY_ListViewTabsCreateNewViewModal/LY_ListViewTabsCreateNewViewModal";
import { LY_ListViewTabsUpdateSettingsModal } from "../LY_ListViewTabsUpdateSettingsModal/LY_ListViewTabsUpdateSettingsModal";

import { LY_ListViewTabsDeleteModal } from "../LY_ListViewTabsDeleteModal/LY_ListViewTabsDeleteModal";
import { LY_ListViewTabsUpdateSettingsContextMenu } from "../LY_ListViewTabsUpdateSettingsContextMenu/LY_ListViewTabsUpdateSettingsContextMenu";
import { LY_ListViewTabsSettingsModal } from "../LY_ListViewTabsSettingsModal/LY_ListViewTabsSettingsModal";
import { rem } from "@mantine/core";
 
export const LY_ListViewTabsModuleContainer: React.FC<LY.LY_ListViewTabsModuleProps> = (props) => {

    const context = LY.useListViewTabsContext();
    const state = context.state

    function render() {

      //  console.log('LY_ListViewTabsModuleContainer render tabs', state.tabs);


        var pinnedTabs = context.getPinnedTabs();

       // console.log('LY_ListViewTabsModuleContainer render pinnedTabs', pinnedTabs);

        const iconStyle = { width: rem(16), height: rem(16) };
        
       // console.log('LY_ListViewTabsModuleContainer render selectedTab', state.selectedTab);


        return (
            <>

                <LY.LY_Tabs
                    name={props.name}
                    idProperty='listViewId'
                    tabs={pinnedTabs}
                    selectedItem={state.selectedTab}
                    isDragable
                    icons={
                        [{ icon: <Icons.IconDatabase style={iconStyle} /> , type: 'table' },
                        { icon: <IconLayoutKanban style={iconStyle} />, type: 'kanban' }
                        ] as { type: string, icon: React.ReactNode }[]
                    }
                    newTabContextMenu={<LY_ListViewTabsNewItemContextMenu />}
                    
                    tabUpdateContextMenu={(item: Api.ListViewSm) => <LY_ListViewTabsUpdateSettingsContextMenu item={item} {...props} />}

                    onTabChange={(tab: any) => {
                       // state.selectedTabId = tab.listViewId;
                        state.selectedTab = tab;
                        
                        console.log('LY_ListViewTabsModuleContainer onTabChange tab', tab);
                        context.forceUpdate();


                        if(props.onTabChange)
                            props.onTabChange(tab);
                    }}

                    contentPanels={props.contentPanels}
                    onOrderChanged={context.onOrderChanged}
                   
                />

                <LY_ListViewTabsCreateNewViewModal {...props} />
                <LY_ListViewTabsUpdateSettingsModal {...props} />
                <LY_ListViewTabsDeleteModal {...props} />
                <LY_ListViewTabsSettingsModal {...props} />
                
            </>
        );
    }

    
    return render();
};
 