import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { useRef } from 'react';
import { useParams, useLocation } from 'react-router';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Api from 'app-api';
import * as Icons from '@tabler/icons-react';
import { MDXProvider } from '@mdx-js/react'
import MDX from '@mdx-js/runtime';


import './DetailPage.css';
import { Badge, Tooltip, CloseButton, Modal, Tabs, rem } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { LY_NotesModule } from '_LY_Components/_Modules/LY_NotesModule/LY_NotesModule';

interface DetailPageProps {
  //state: App.ListState; //this needs to be managed internally
  //model: LY.DataItemModel;
  //onChange?: Function;
  forceReadOnly?: boolean;
  isDLABiddingQuotes?: boolean;
}

export const DetailPage: React.FC<DetailPageProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();
  const currentUser = App.AppBase.currentUser;

  const currentWorkspace = App.AppBase.currentWorkspace;
  var workspaceVm = App.AppBase.getCurrentWorkspaceVm();

  var initialState = new App.DetailState();

  const [ state, setState ] = React.useState(initialState); // Add this line
  const biddingQuotesTplId = "08e4ce1c-8d8f-4acf-a772-977168e256fb";

  var vm = state.vm;

  var params = useParams();
  var location = useLocation();
  const [ viewId, setViewId ] = React.useState(-1);

  React.useEffect(() => {
    console.log('DetailPage useEffect');

    var sm = state.sm;

    workspaceVm = App.AppBase.getCurrentWorkspaceVm();

    LIB.ObjectHelper.setData(params, sm);
    let workspaceId = Number(params.w);
    if (workspaceId && !isNaN(workspaceId))
      sm.workspaceId = workspaceId;

    

    getData(sm);

  }, [ params.id ]);



  function getEmailTemplate(){

    var item =  state.data;

    console.log('getEmailTemplate item', item);

    var content = `
    
    Hi, 
    <br />
    Hope you're doing well.
    <br />
    can you please provide me pricing and availability for the following:
    <br />
    NSN: <b>${item?.nsn}</b>

    Part Number:

    Quantity: <b>${item?.quantity}</b>

    Description:

    Manufacturer:

    Manufacturer Cage Code:

    Alternate Part Number:

    <br />
    <b>My Account Number:</b> 

    <br />

    Please include lead time, weight, dimensions and default packaging. 
    
    <br />

    This is a time sensitive requirement, I'd really appreciate your prompt response.

    
    `;

    return content;


  }


  async function getData(sm: Api.DetailGetDataSm) {

    console.log('DetailPage getData sm', sm);

    if (!sm || !sm.rowId || !sm.listId || !sm.workspaceId)
      return;

    sm.includeRelationships = true;
    sm.includeColumns = true;

    var dataService: App.DetailDataService | App.LinkedDetailDataService = new App.DetailDataService(sm.workspaceId);
 
    state.hasDataLoaded = false;

    //listService.getRecords(sm,
    dataService.getDetailsById(sm,
      //onSuccess
      (result: App.DetailState) => {
        // console.log('DetailPage onSuccess result:', result);

        vm.apiErrorMessage = '';
        LIB.ObjectHelper.setData(result, state, 'vm');
        vm.isActionInProgress = false;
        //var first = result.records[0];
        var data = result.data;
        state.data = data;
         
        var list = state.list;
        if(list?.listId==biddingQuotesTplId || list?.baseTemplateId==biddingQuotesTplId)
        {
          vm.isDLABiddingQuotesList = true;
        }
  

        
        console.log('DetailPage onSuccess data:', data);
        /*  if (data) {
           fkModel.row = data;
           setFKModel(fkModel);
         }
  */
 
        state.hasDataLoaded = true;
        setState({ ...state });

        //forceUpdate();

      },
      //onError
      (error: any) => {

        console.error('DetailPage onError:', error);

        vm.apiErrorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        App.AppBase.showError(vm.apiErrorMessage);
        forceUpdate();
        //setServerError(errorMessage);
      });


    vm.isActionInProgress = true;

    forceUpdate();

  }


  function getChildLists() {

    var relationshipLists = state.relationshipLists;
    var item = state.data;
    var list = state.list;

    if (relationshipLists == undefined || relationshipLists.length == 0 || !state.hasDataLoaded)
      return null;

    //var workspaceId: number | undefined = state.view?.workspaceId;
    var workspaceId = state.sm.workspaceId;

    var currentWorkspace = App.AppBase.currentWorkspace;
    var linkedWorkspaceId: number | undefined = undefined;


    console.log('getChildLists relationshipLists', relationshipLists);
    var view = state.view;

    /*     if (view.isLinkedView) {
          linkedWorkspaceId = state.view?.workspaceId;
          workspaceId = currentWorkspace?.workspaceId;
        } */

    console.log('getChildLists linkedWorkspaceId', linkedWorkspaceId);
    console.log('getChildLists workspaceId', workspaceId);
    console.log('getChildLists item?.name', item?.name);

    const iconStyle = { width: rem(12), height: rem(12) };


    var tabList: any[] = [];

    relationshipLists.map((item, index) => {
      var tab = <Tabs.Tab 
            key={'tab' + item.listId} value={'tab' + item.listId} 
            leftSection={<Icons.IconForms style={iconStyle} />}>
        {item.viewName}
      </Tabs.Tab>;
      tabList.push(tab);  
    })

    tabList.push(
      <Tabs.Tab value="notes" leftSection={<Icons.IconMessage style={iconStyle} />}>
        Notes

      </Tabs.Tab>
    );
    
    if(vm.isDLABiddingQuotesList ){

     

     /*  tabList.push(
        <Tabs.Tab value="nsn_history" leftSection={<Icons.IconMessageCircle style={iconStyle} />}>
          NSN History
        </Tabs.Tab>
      ); */
  /*     tabList.push(
        <Tabs.Tab value="documents" leftSection={<Icons.IconMessageCircle style={iconStyle} />}>
          Documents
        </Tabs.Tab>
      );
 */
      tabList.push(
        <Tabs.Tab value="email_emplates" leftSection={<Icons.IconMessageCircle style={iconStyle} />}>
          Email Templates
        </Tabs.Tab>
      );
 

      tabList.push(
        <Tabs.Tab value="emails" leftSection={<Icons.IconMessage style={iconStyle} />}>
          Emails
        </Tabs.Tab>
      );
 
    /*   tabList.push(
        <Tabs.Tab value="sms" leftSection={<Icons.IconMessageCircle style={iconStyle} />}>
          SMS
        </Tabs.Tab>
      ); */

      
    }

    return <Tabs key={'childLists'} 
            defaultValue={'tab' + relationshipLists[ 0 ].listId} 
            className='LY_DetailPageRelationshipTabs'>

      <Tabs.List>

      {tabList}
 
      </Tabs.List>




      {
        relationshipLists.map((relInfo, index) => {

          if (!relInfo.viewName)
            return null;

                      // console.log('getChildLists relationshipLists filter', list?.filter);

           var relColName = relInfo?.relatedColumn?.name;

           //@ts-ignore
           var relColValue = item[relInfo?.relatedColumn?.fkListColumnName];           
/*           console.log('getChildLists relationshipLists relColName',relColName);
          console.log('getChildLists relationshipLists relColValue',relColValue);
          console.log('getChildLists relationshipLists item',item); */

          return <Tabs.Panel key={'tabPanel' + relInfo.listId} value={'tab' + relInfo.listId} className='ListViewRelationshipTabPanel'>
            {/*       {item.viewName} */}
            {/*  {item.viewName}  */}
            {/*    {state.sm.listId}   */}

            {/* {workspaceId} */}
            <LY.ListTableView
              {...relInfo}
              forceFilter={true}
              workspaceId={workspaceId}
              linkedWorkspaceId={linkedWorkspaceId}
              hideFooterWhenNoPaging={true}
              //showFilters={true}
              disableNameClick={true}
              presetRelColumnName={relColName}
              presetRelColumnValue={relColValue}
            />


          </Tabs.Panel>

        })
      }

    
      <Tabs.Panel value="emails" className='DetailContainerTabPanel'>
      Coming Soon... 
      </Tabs.Panel>

      <Tabs.Panel value="documents" className='DetailContainerTabPanel'>
        Coming Soon... 
      </Tabs.Panel>
  
      <Tabs.Panel value="notes" className='DetailContainerTabPanel'>
      
      
      <LY_NotesModule
                workspaceId={currentWorkspace.workspaceId}
                currentUser={currentUser}
                recordId={item?.id}
              />

      </Tabs.Panel>
  
      <Tabs.Panel value="email_emplates" className='DetailContainerTabPanel'>
        {/* Coming Soon... */} 


        <MDXProvider >
        <MDX>{getEmailTemplate()}</MDX>

      </MDXProvider>

      

      </Tabs.Panel>
  

      <Tabs.Panel value="nsn_history" className='DetailContainerTabPanel'>
        Coming Soon... 
      </Tabs.Panel>
  

    </Tabs>


  }

  function getBreadcrumbs() {

    var workspaceId = state.view?.workspaceId;
    var propsVm = state.vm;
    let vm = state.vm;
    var item = state.data;
    var list = state.list;


    if (!item?.name)
      return null;

    const items = [
      { title: currentWorkspace?.name, href: '/' },
      { title: list?.name, href: `${list.workspaceId}/list/${workspaceVm?.selectedItem?.defaultViewId}` },
    ];

    if(!App.AppBase.isMobile)
      items.push(  { title: item?.name, href: '' });
    
    var links =  items.map((item, index) => {
      if (!item.href)
        return item.title;
      return <MT.Anchor href={item.href} key={index}
        onClick={(e) => {
          e.preventDefault();
          //  console.log('Breadcrumb click', item);
          App.RouteManager.routeToPath(item.href);

        }}
      >
        {item.title}
      </MT.Anchor>
    });

    return <MT.Breadcrumbs style={{ marginTop: 10 }}>
      {links}
    </MT.Breadcrumbs>

  }

  function getEditModeSwitch() {
    var item = state?.data;

    if (!item)
    return null;

    return <LY.LY_Switch
      name='isEditMode'
      label='Edit Mode'
      labelPlacement='left'
      checked={vm.isEditMode}
      onChange={(m, e) => {
        console.log('getEditModeSwitch onChange', m);
        vm.isEditMode = e?.currentTarget?.checked;
        forceUpdate();
      }}
    />

  }


  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var workspaceId = state.view?.workspaceId;
    var propsVm = state.vm;
    let vm = state.vm;

    var item = state.data;
    var title = item?.name || params.name;

    if (state.list?.detailPageTitle)
      title = state.list.detailPageTitle + ' - ' + title;

    var model = new LY.DataItemModel();
    model.row = item;
    model.listId = state.list?.listId;
    model.rowId = item?.id;

    var isMobile = App.AppBase.isMobile;

    // console.log('DetailPage render vm.isDetailPopupModalOpen', vm.isDetailPopupModalOpen);
    //  console.log('DetailPage render vm.isRelActionInProgress', vm.isRelActionInProgress);

  /*   if (!item)
      return null;
 */
    //Api.ListGetDataSm 

    // console.log('DetailPage render state', state);
    // console.log('DetailPage render fkModel', fkModel);

    var relationshipLists = state.relationshipLists;

    return <App.MainSplitPage
      // pageTitle={<span>{item?.name}</span>}
      state={vm}
      hasDataLoaded={true}//state.hasPageDataLoaded}
      //loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
      // errorMessage={state.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      showLeftMenu={true}
      isFullHeightPage={false}
      
      mainPanelsContentClassName='LY_DetailPage_MainSplitPagePanelContent LY_Scrollbar_Panel'
      mainPanelsClassName='LY_Scrollbar_Panel'

    //showRightPanel={vm.isDetailScreenOpen}
    >

      <div className='LY_DetailPageTopForm'>

 
        {getBreadcrumbs()}

 
        <h1 style={{ display: 'flex', alignItems: 'center', gap: 20, 
        flexDirection:isMobile?'column':'row' }}
        >
          {title} {getEditModeSwitch()}</h1>

        <hr style={{marginBottom:10, marginTop:5}} />
       {/*  <div style={{ display: 'flex', justifyContent: 'end', padding: 10 }}>

          {getEditModeSwitch()}

        </div> */}

        <App.DefaultListTableForm
          state={state}
          model={model}
          //onClose={() => onOpenChange(false)}
          formContainerClassName={'DetailPageModalFormContainer'}
          formContainerItemClassName={'DetailPageModalFormContainerItem'}
          excludeFKMultiple={true}
           excludeButtons={!vm.isEditMode}
          readOnly={!vm.isEditMode}
          onClose={() => {vm.isEditMode=false; forceUpdate();}}
        />


      </div>

      {getChildLists()}




    </App.MainSplitPage>

 
  }



  return render();
};
