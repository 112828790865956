import "./LY_ListViewTabsModule.css";
import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';

import * as React from "react";

 

import { LY_ListViewTabsContextProvider } from "./Context/LY_ListViewTabsContext";
import { LY_ListFilterModuleContext } from "../LY_ListFilterModule/Context/LY_ListFilterModuleContext";
import { LY_ListViewTabsModuleContainer } from "./Components/LY_ListViewTabsModuleContainer/LY_ListViewTabsModuleContainer";


export const LY_ListViewTabsModule: React.FC<LY.LY_ListViewTabsModuleProps> = (props) => {
    return (
        <LY_ListViewTabsContextProvider {...props}>
            <LY_ListViewTabsModuleContainer {...props} />
        </LY_ListViewTabsContextProvider>
    );

};
 