import "./LY_StatusDropDown_Options.css"

import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { buttonStyles, sizes } from "../../Constants/constants";

import { Badge } from "@mantine/core";
import { LY_DropDown_Footer } from '../LY_StatusDropDown_Footer/LY_StatusDropDown_Footer';

export const LY_StatusDropDown_Options: React.FC<LY.LY_StatusDropDownProps> = (props) => {



    const context = LY.useStatusDropDownContext();
    const state = context.state;
    const isWide = state.statuses?.length > 5;



    return <div>
        <div
            className='LY_StatusDropdown_List_Renderer LY_ScrollbarDropDown'
            style={{
                minWidth: isWide ? '420px' : state.model.column?.columnWidth || '200px',
               // flexDirection: isWide ? 'row' : 'column',
               gridTemplateColumns: isWide ? '1fr 1fr' : '1fr',
               display: 'grid',
            }}
        >
            <LIB.Loading show={state.isActionInProgress} isModal />
            {state?.statuses?.map((item, index) => {

                const variant = state?.model?.column?.styleVariant
                const badgeColor = item?.text_color || props?.model?.column?.textColor || '#ffffff';
                const badgeBackgroundColor = variant === 'transparent' ? '' : item?.color || props?.model?.column?.valueBackgroundColor;
                const badgeFontSize = state?.model?.column?.fontSize || '14px';
                const badgeRadius = state?.model?.column?.styleRadius || 0;

                return <div key={index}>
                    <Badge
                        onClick={(e: any) => {
                            context.changeSelectedValue(item);

                            if (props.onChange)
                                props.onChange(state.model, item);

                            context.setPopoverOpened(false);
                        }}
                        key={item.id}
                        style={{
                            border: variant === 'transparent' ? '' : '1px solid!important',
                            borderColor: variant === 'transparent' ? '' : badgeBackgroundColor,
                            height: '36px',
                            fontSize: badgeFontSize,
                            minWidth: 180,
                            color: badgeColor,
                            backgroundColor: variant === 'outline' ? '' : badgeBackgroundColor,
                            cursor: 'pointer',
                            userSelect: 'none'
                        }}
                        radius={badgeRadius}
                        variant={variant || 'filled'}
                        className='LY_StatusDropDownDisplayBadge'
                    >
                        {item?.name}
                    </Badge>
                </div>
            })}
        </div>

         <LY_DropDown_Footer {...props} />

    </div>
}