import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as React from 'react';
import * as LY from '_LY_Components';


export class LYDetailManager {


 // public props: LY.ListTableViewProps
  public state: App.DetailState;
  public forceUpdate: Function;
  public workspaceVm: App.WorkspaceVm;


  constructor(state: App.DetailState,  forceUpdate: Function, workspaceVm:App.WorkspaceVm) {

   // this.props = props;
    this.state = state;
    this.forceUpdate = forceUpdate;
    this.workspaceVm = workspaceVm;


  }



  private onServiceError(error: any) {
    var vm = this.state.vm;

    //vm.apiErrorMessage = LIB.ErrorHelper.getErrorMessage(error);
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

    App.AppBase.showError(errorMessage);

    vm.isActionInProgress = false;
    this.state.newItem.loading = false;

    this.forceUpdate();
  }


    
  public getInputSelectorRenderer(col?: Api.ListColumn, readOnly:boolean=false, hideLabel:boolean=true) {

    var state = this.state;
    var vm = state.vm;
    var item = state?.data;

    if(!col || !item)
      return null;

    var value = item[ col.name ];  

    var model =  new LY.DataItemModel();

    model.rowId = item['id'];
    model.row = item;
    model.column = col;
    model.value = value;
    model.list = state.list;
    model.listId = state.list.listId;
 

    var linkedWorkspaceId = state?.linkedWorkspaceId;

    if(col.fkIsLinkedWorkspace && col.fkWorkspaceId)
      linkedWorkspaceId = col.fkWorkspaceId;

      var workspace = state.workspace;

      console.log('getInputSelectorRenderer workspace:', workspace);  
      console.log('getInputSelectorRenderer dropDownData:', state?.dropDownData);  

    var result = (

      <LY.LA_InputSelector
        key={`formItem_${col.name}_${item?.rowId}`}
        model={model}
        linkedWorkspaceId={linkedWorkspaceId}
        workspaceId={workspace?.workspaceId}
        column={col}
        lookupTypes={workspace?.lookupTypes}
        users={this.workspaceVm?.users}
        dropDownData={state?.dropDownData}
        forceReadOnly={readOnly}
        name={col.name}
        label={col.displayName}
        value={value}
        hideLabel={hideLabel}
       // placeholder={'Select ' + col.displayName}
        inputContainerClassName='LYDetail_InputSelectorInput'

        labelPlacement='top'

        withStar={(!col.isNull && !col.defaultValue) || col.isRequired}
         error={vm.errors.get(col.name)}

        onChange={(model, e) => {
              console.log('DefaultListTableForm onChange', model);
              console.log('DefaultListTableForm e', e);

           //   onInputChange(col,e, model);

              this.onSingleValueUpdate(model, (data: any) => {
                console.log('LYDetailManager onSingleValueUpdate data:', data);
                //vm.apiErrorMessage = '';
                //App.AppBase.showSaveSuccess();
                //this.forceUpdate();
              }
              );
          }
        }

        maxDisplayLength={30}

        isForList={false}
      />
      /*  <div key={index}>
       {col.displayName}
     </div> */
    );


    return result;

  }


  public async onSingleValueUpdate(model?: LY.DataItemModel, onSuccess?: Function) {

    // console.log('LYDetailManager sm:', sm);
    //  console.log('LYDetailManager e:', e);

    console.log('LYDetailManager model:', model);


    //console.log('LYDetailManager e:', e);


    if (model == undefined)
      return;

    //Update value in the dataset
    if (model.row && model.column)
      model.row[ model.column.name ] = model.value;

    //console.log('LY_NameColumnInput getViewMode modelT3: ',model);
    //console.log('LY_NameColumnInput getViewMode modelT3  value: ',model?.value);

    var update = App.RecordManager.getUpdateValue(model);

    if (update.sortOrder == undefined)
      update.sortOrder = 1;
    else
      update.sortOrder++;

    var sm = new Api.ListUpdateDataSm();
    sm.updates = [ update ];
    console.log('LYDetailManager sm:', sm);


    var vm = this.state.vm;
    var state = this.state;
    vm.isActionInProgress = false;
    var recordManager = new App.RecordManager();

    var workspaceId = state.workspaceId || state.workspace?.workspaceId;
    // var service = new App.ListDataService(this.props.workspaceId);
    var service = new App.ListDataService(workspaceId);
    service.updateValues(sm,
      //onSuccess
      (data: any) => {
        console.log('LYDetailManager onSuccess data:', data);
        vm.apiErrorMessage = '';

        App.AppBase.showSaveSuccess();
        if (onSuccess)
          onSuccess(data);
        //  this.forceUpdate();

      },
      //onError
      this.onServiceError.bind(this),

    );



  }






}