import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class ReportsService extends App.BaseAppService {

    constructor(workspaceId: number | undefined) {
        super();
        this.workspaceId = workspaceId;
    }

 

    public async runReport(sm: Api.RunReportSm, onSuccess: Function, onError: Function | undefined = undefined) {
        console.log("ReportsService.runReport sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Reports/runReport`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ReportsService.runReport onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });
    }
 
}