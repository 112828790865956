import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { CSSProperties } from "react";

import './WorkspaceDropdown.css';


import { WorkspaceDropdownContextMenu } from './WorkspaceDropdownContextMenu/WorkspaceDropdownContextMenu';


interface WorkspaceDropdownProps {
    state: App.GlobalState;


}

export const WorkspaceDropdown: React.FC<WorkspaceDropdownProps> = (props) => {



    var selectedTab: string | undefined;
    var params = useParams();
    var location = useLocation();
    var locationState = location.state;


    /*   React.useEffect(() => {
          console.log("WorkspaceDropdown useEffect update");
  
      }); */


    var state = props.state;
    var globalVm = state.globalUIState;
   // var vm = state.workspaceVm;
    const [ vm, setVm ] = React.useState<App.WorkspaceVm>(state.workspaceVm);

    var selectedItem = App.AppBase.location?.pathname;
    var currentUser = App.AppBase.currentUser;

    var selectedWorkspace = vm?.selectedWorkspace



    function onSelectedItemsChange(name: string, selectedValue: number) {
        console.log('WorkspaceDropdown onSelectedItemsChange name', name);
        console.log('WorkspaceDropdown onSelectedItemsChange selectedValue', selectedValue);

        var sm = new Api.Workspace();
        sm.workspaceId = selectedValue;
        var changedWorkspace = state.workspaceVm?.workspaces?.find(x => x.workspaceId == selectedValue);
        if(changedWorkspace)
            vm.selectedWorkspace = changedWorkspace;

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_CHANGE_REQUEST, sm);

    };

    function forceUpdate() {
        setVm({ ...vm });   
    }
    
    function onWorkspaceChange(wp: Api.Workspace) {
        console.log('WorkspaceDropdown onWorkspaceChange wp', wp);
 
        var sm = new Api.Workspace();
        sm.workspaceId = wp.workspaceId;
        var changedWorkspace = state.workspaceVm?.workspaces?.find(x => x.workspaceId == wp.workspaceId);
        if(changedWorkspace)
            vm.selectedWorkspace = changedWorkspace;
      
        // App.RouteManager.redirectToList(vm.selectedWorkspace.ite)
   /*      console.log('WorkspaceDropdown onWorkspaceChange changedWorkspace', changedWorkspace);
        forceUpdate(); */

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_CHANGE_REQUEST, sm);

    };

    function render() {

        if (!selectedWorkspace)
            return null

        return <div  >

            {/*    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>

                <LY.LY_StatusSelect

                    label="Workspace"
                    name="workspace"
                    data={vm.workspaces}
                    labelProperty="name"
                    valueProperty='workspaceId'

                    value={vm.selectedWorkspace || ''}
                    //variant="standard"
                    // textFieldStyle={textFieldStyle}
                    // textFieldStyle={{   width: '100%' }}
                    //  textFieldClassName='WorkspaceMenuTextField'
                    tag='CRM'
                />

                <MT.Button
                    color="primary"
                    variant="contained"
                    style={{ padding: 2, minWidth: 10 }}
                //onClick={this.onSaveClick.bind(this)}
                >   <Icons.AddOutlined />          </MT.Button>

            </div> */}

            <div 
            className='WorkspaceDropdownContainer'
            style={{ display: 'flex', gap: '5px', flexDirection: 'row', alignItems: 'center' }}>

 

            <LY.LY_WorkspaceDropDown 
                 // label="Workspace"
                  name="workspacesDropDown"
            data={vm.workspaces}
            selectedWorkspace={vm.selectedWorkspace }
            onWorkspaceChange={onWorkspaceChange}
            onNewWorkspaceClick={()=>{

                vm.isNewWorkspaceScreenOpen = true;
                forceUpdate();

            }}
            /> 
            <WorkspaceDropdownContextMenu 
             state={props.state}
             vm={vm}
              />

     {/*            <WorkspaceDropdownContextMenu state={props.state} /> */}

            </div>




        </div>;

    }


    return render();
} 