import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';
import nunjucks from "nunjucks";

import { createContext, useContext, useEffect } from "react";

import { LY_BulkEmailSenderModuleServiceManager } from '../Managers/LY_BulkEmailSenderModuleServiceManager';
import { LY_BulkEmailSenderModuleState } from './LY_BulkEmailSenderModuleState';


import { useDisclosure } from '@mantine/hooks';

export interface LY_BulkEmailSenderModuleContextProps {
  state: LY_BulkEmailSenderModuleState;
  forceUpdate: () => void;
  onCancelOrCloseReset: () => void;
  resetState: () => void;
  services: LY_BulkEmailSenderModuleServiceManager; 
  changeSelectedViewEmailToItem: (item:any) => void;
  setReportResult: (result: Api.ReportState) => void;
}

const useBulkEmailSenderModuleState = (props: LY.LY_BulkEmailSenderModuleProps): LY_BulkEmailSenderModuleContextProps => {


  const initialState = new LY_BulkEmailSenderModuleState();

  const [state, setState] = React.useState<LY_BulkEmailSenderModuleState>(initialState);
  const [propsState, setPropsState] = React.useState<LY_BulkEmailSenderModuleState>(new LY_BulkEmailSenderModuleState());


  const contextObjBase: LY.LY_BulkEmailSenderModuleContextProps = {
    state,
    forceUpdate,
    onCancelOrCloseReset,
    resetState,
    changeSelectedViewEmailToItem,
    setReportResult,
    services: {} as LY_BulkEmailSenderModuleServiceManager
  };

  const services = new LY_BulkEmailSenderModuleServiceManager(props, state, contextObjBase);

  const contextObj: LY.LY_BulkEmailSenderModuleContextProps = {...contextObjBase, services: services};


  React.useEffect(() => {

    console.log('LY_BulkEmailSenderModuleContext useEffect props', props);
    if(state.isContainerModalOpen)
      return;

    console.log('LY_BulkEmailSenderModuleContext useEffect isContainerModalOpen', props.isContainerModalOpen);


    state.workspaceId = props.workspaceId;
    state.workspaceId = props.workspaceId;
    state.linkedWorkspaceId = props.linkedWorkspaceId;
    state.isContainerModalOpen = props.isContainerModalOpen || false;
    state.listState = props.listState;
    state.record = props.record;
    state.emailToItems = props.emailToItems;
    state.selectedEmailToItems = [...props.emailToItems];

   if(state.selectedEmailToItems.length > 0)
        changeSelectedViewEmailToItem(state.selectedEmailToItems[0]);

    forceUpdate();

    var stateCopy = clone(state);
    setPropsState(stateCopy);

    let env = nunjucks.configure({ autoescape: true });

    if(props.report )
      services.runReport(props.report);


  }, [ props ]);

  
  function clone(stateIn: LY_BulkEmailSenderModuleState): LY_BulkEmailSenderModuleState {

    var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_BulkEmailSenderModuleState;
    stateCopy.errors = new Map<string, any>();
    return stateCopy;
  }


  function filterReport(){

    if(props.reportFilter)
      state.reportFiltered =  props.reportFilter(state.reportState, state.selectedItem);
    else 
      state.reportFiltered = state.reportState;
 
    forceUpdate();
  }

  function setReportResult(result: Api.ReportState) {


    state.reportState = result;
    state.reportData = result?.records;
    filterReport();

    changeSelectedViewEmailToItem(state.selectedItem);

  }


  function setToEmailsFromReport(){

    if(!props.toEmailPropertyNames || !state.reportData || state.reportData.length<1)
      return;

    state.selectedItemReportFirstRow = state.reportData[0];
    if(!state.selectedItemReportFirstRow)
      return;


    var firstRow = state.selectedItemReportFirstRow;
    state.selectedItemToEmails = props.toEmailPropertyNames.map((propName) => {return firstRow[propName]?.toString()?.trim()});
    state.selectedItemToEmails = state.selectedItemToEmails?.filter(x=>x!=null && x.length>0);



  }

  function changeSelectedViewEmailToItem(item:any){ 

    state.selectedItem = item;
     
    if(props.reportFilter){
      var newReportState = props.reportFilter(state.reportState, item);
      state.reportData = newReportState?.records;
      setToEmailsFromReport();
      item.body = getEmailTemplate(item);

      if(state.selectedItemReportFirstRow)
          state.selectedItem = LIB.ObjectHelper.addMissingData(state.selectedItemReportFirstRow,state.selectedItem);
 
      forceUpdate();
    }


    if(item && props.record){
      item.record = props.record;
      //item.html_email_content = getEmailTemplate(item);
      //item.body = getEmailTemplate(item);
    }

    if(props.itemsNameProperty)
      state.selectedItemName = item[props.itemsNameProperty];
    
      item.body = getEmailTemplate(item);

    forceUpdate();
  }

  function forceUpdate() {
    setState({ ...state })
  }
  
  function resetState() {
    var stateCopy = clone(propsState);
    setState({ ...state })
  }


  function onCancelOrCloseReset() {

    state.isActionInProgress = false;
    state.isContainerModalOpen = false;

    state.errors.clear();
    forceUpdate();

    
    if (props.onClose)
        props.onClose();
  }

  function getEmailTemplate(item:any) {

 
    console.log('LY_BulkEmailSenderModuleContext getEmailTemplate item', item);

    var content = `Hi, 
<p></p>
<p>Hope you're doing well.</p>
<p></p>
<p>Can you please provide me pricing and availability for the following Federal Government Contract:</p>
<p></p>

{% if account_number %}
<p><b>Account/Customer Number:</b> ${(item?.account_number || '')}</p>
<p></p>
{% endif %}

<p><b>NSN: </b>${item?.record?.nsn}  </p>
<p><b>Part Number:</b> ${item?.part_number}</p>
<p><b>Quantity:</b> ${item?.quantity}  </p>
<p><b>Description:</b> ${(item?.record?.description || '')} </p>



{% if is_approved_source %}
<p><b>MFG:</b>  {{ mfg }} </p>
<p><b>MFG Cage:</b> {{ mfg_cage }} </p>
<p><b>Request ID:</b> ${(item?.record?.quote_id || '')} - please include this Request ID in your quotes. </p>
{% else %}
<p><b>Request ID:</b> ${(item?.record?.quote_id || '')} - please include this Request ID in your quotes. </p>

<p></p>
<p><b>MANUFACTURERS/APPROVED SOURCES:</b></p>
{% for m in reportData %}
<p><b>MFG:</b>  {{ m.mfg }} <b>MFG Cage:</b> {{ m.mfg_cage }} <b>PN:</b> {{ m.mfg_part_number }} </p>
{% endfor %}


{% endif %}

<p></p>
{% if account_number %}
<p><b>Account/Customer Number:</b> ${(item?.account_number || '')}</p>
{% endif %}

<p></p>
<p>Please include lead time, weight, dimensions, and default packaging.</p>
<p>This is a time sensitive requirement; I'd really appreciate your prompt response.</p>
<p></p>
<p>This is for the Department of Defense;  your prompt collaboration will be appreciated!</p>
<p></p>
<p>Please give us your best discounts/pricing. These bids are very competitive.</p>
<p></p>
<p>We need an official RFQ Quote, if this is not the correct email, please forward us the proper contact for these requests.</p>
    
`;

    content = content?.replace(/<p><\/p>/g, '<p>&nbsp;</p>');

    console.log('LY_BulkEmailSenderModuleContext getEmailTemplate content', content);

    var tplData:any = {...item, ...state.selectedItemReportFirstRow};
    //tplData.selectedItemReportFirstRow = state.selectedItemReportFirstRow;
    tplData.record = state.record;
    tplData.reportData = state.reportData;


    console.log('LY_BulkEmailSenderModuleContext getEmailTemplate tplData',tplData);

    content = nunjucks.renderString(content, tplData);

    console.log('LY_BulkEmailSenderModuleContext getEmailTemplate after nunj', content);


    return content;


  }


  return contextObj;
};

const LY_BulkEmailSenderModuleContext = createContext<LY.LY_BulkEmailSenderModuleContextProps | undefined>(undefined);

export const useBulkEmailSenderModuleContext = () => {


  const context = useContext(LY_BulkEmailSenderModuleContext)


  if (!context) {
    throw new Error(
      "LY_BulkEmailSenderModuleContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};


const LY_BulkEmailSenderModuleContextProvider: React.FC<LY.LY_BulkEmailSenderModuleProps & { children: React.ReactNode }> = (props) => {


  const contextValue = useBulkEmailSenderModuleState(props);

  function render() {


    return (
      <LY_BulkEmailSenderModuleContext.Provider value={contextValue}>
        {props.children}
      </LY_BulkEmailSenderModuleContext.Provider>
    );
  }

  return render();
};
export { LY_BulkEmailSenderModuleContextProvider, LY_BulkEmailSenderModuleContext };
