import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from '_LIB';
import * as LY from "_LY_Components";
import * as MT from '@mantine/core';
import * as React from "react";


export const LY_ListViewTabsUpdateSettingsModal: React.FC<LY.LY_ListViewTabsModuleProps> = (props) => {

    const context = LY.useListViewTabsContext();
    const { state } = context;

    var item = state.updateItem;
    var errors = state.errors;

    function onInputChange(e: React.ChangeEvent<any>) {

        var key = LIB.FormHelper.getChangeTargetName(e.target);
        var value = LIB.FormHelper.getChangeTargetValue(e.target);

        onValueChange(key, value);

    }

    function onValueChange(key: string, value: any) {
        //console.log('LY_ListViewTabsUpdateSettingsModal onValueChange key:', key);
        //console.log('LY_ListViewTabsUpdateSettingsModal onValueChange value:', value);

        /*         if (key == 'name') {
                    value = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
                } */

        //@ts-ignore
        item[ key ] = value;


        validate(key);


        context.forceUpdate();


    }


    function validateAll(): boolean {
        return validate('all');
    }

    function validate(key: string): boolean {

        var vAll = (key === 'all'); //validate all

        errors.clear();

        if (vAll || key == 'name') {
            if (LIB.Common.isNullOrEmpty(item?.name) || !item?.name)
                errors.set('name', `Name is required`);
            else if (item?.name?.length > 50)
                errors.set('name', `Name must be less than 50 characters`);
        }

        var isValid = (errors.size < 1);
        return isValid;

    }

    function onSaveClick(e: React.MouseEvent) {

        //e.preventDefault();
        state.isActionInProgress = false;

        var isValid = validateAll();
        if (!isValid) {
            console.log('LY_ListViewTabsUpdateSettingsModal onSubmit isValid:', isValid, errors);
            context.forceUpdate();
            return false;
        }

        context.updateView();


    }


    function render() {

        //   console.log('LY_ListViewTabsUpdateSettingsModal render item', item);


        return <LY.LY_Modal
            opened={state.isTabSettingsOpen}
            onClose={() => {
                state.isTabSettingsOpen = false;
                context.forceUpdate();
            }}

            title={`Update View: ${item?.name}`}

        >

            <LY.LY_Input
                name="name"
                label="Name"
                value={item?.name || ''}
                isRequired
                error={errors.get('name')}
                // helpText="This name will be used in APIs or Integrations"
                // helpTextPlacement='bottom'
                onChange={(m, e) => {
                    onInputChange(e!);
                }}
            />

            <LY.LY_CheckBox
                name="isPinned"
                //styles={{ root: { marginTop: 10 } }}
                checked={item?.isPinned}
                label="Pinned"
                error={state.errors.get('isPinned')}
                onChange={(m, e) => {
                    onInputChange(e!);
                }}

            />
            <div className="LY_ListFiltersModule_Save_Modal_Buttons">

                <LY.LY_ButtonCancel
                    onClick={() => {
                        context.reset();
                    }}
                >
                    Cancel
                </LY.LY_ButtonCancel>

                <LY.LY_ButtonDelete
                    onClick={() => {
                        state.isDeleteModalOpen = true;
                        context.forceUpdate();
                    }}

                >
                    Delete
                </LY.LY_ButtonDelete>


                <LY.LY_Button
                    loading={state.isActionInProgress}
                    loaderProps={{ type: 'dots' }}
                    onClick={onSaveClick}
                >
                    Save
                </LY.LY_Button>

            </div>
        </LY.LY_Modal>
    }
    return render();
}