import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class ListSettingsService extends App.BaseAppService {

    constructor(workspaceId: number | undefined) {
        super();
        this.workspaceId = workspaceId;
    }

    public async getListSettingsState(listId: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
      
      
        if (!listId) {
            console.log('ListSettingsService.getListSettingsState listId is required', listId);
            return;
        }

        console.log("ListSettingsService.getListSettingsState listId: ", listId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListSettings/getListSettingsState?listId=${listId}`;

        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListSettingsService.getListWithSettings onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'GET'
        });
    }


    public async getOrCreateListSettings(listId: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        if (!listId) {
            console.log('ListSettingsService.getOrCreateListSettings listId is required', listId);
            return;
        }

        console.log("ListSettingsService.getOrCreateListSettings listId: ", listId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListSettings/getOrCreateListSettings?listId=${listId}`;

        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListSettingsService.getOrCreateListSettings onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'GET'
        });
    }

    public async getListWithSettings(listId: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        if (!listId) {
            console.log('ListSettingsService.getListWithSettings listId is required', listId);
            return;
        }

        console.log("ListSettingsService.getListWithSettings listId: ", listId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListSettings/getListWithSettings?listId=${listId}`;

        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListSettingsService.getListWithSettings onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'GET'
        });
    }

    public async updateListSettings(listId: string, listSetting: Api.ListSetting, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        if (!listId) {
            console.log('ListSettingsService.updateListSettings listId is required', listId);
            return;
        }

        console.log("ListSettingsService.updateListSettings listId: ", listId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListSettings/updateListSettings?listId=${listId}`;

       // const apiUrl = `http://localhost:4019/ListSettings/updateListSettings?listId=${listId}`;


        await this.executeApi({
            apiUrl: apiUrl,
            data: listSetting,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("ListSettingsService.updateListSettings onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });
    }

}