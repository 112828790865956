import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';

import { createContext, useContext, useEffect } from "react";

import { LY_ListViewTabsManager } from '../Manager/LY_ListViewTabsManager';
import { LY_ListViewTabsState } from '../State/LY_ListViewTabsModuleState';


import React from "react";
import { useDisclosure } from '@mantine/hooks';

export interface LY_ListViewTabsContextProps {
    state: LY_ListViewTabsState;

    forceUpdate: () => void;
    setTabs: (tabs: Api.ListViewSm[]) => void;
    onOrderChanged: (tabs: Api.ListViewSm[]) => void;
    createNewView: (tabType?:string) => void;
    onDeleteTab: (id: number) => void;
    updateView: () => void;
    updateViews: () => void;
    reset:() => void;
    getPinnedTabs(): Api.ListViewSm[];
}

const useListViewTabsState = (props: LY.LY_ListViewTabsModuleProps): LY_ListViewTabsContextProps => {

 
    const initialState = new LY_ListViewTabsState();

    const [state, setState] = React.useState<LY_ListViewTabsState>(initialState);
     // Create a ref for the context
 

    const contextObj: LY_ListViewTabsContextProps = {
        state,
        forceUpdate,
        setTabs,
        onOrderChanged,
        createNewView,
        onDeleteTab,
        updateView,
        updateViews,
        reset,
        getPinnedTabs
      };

 
      
    const manager = new LY_ListViewTabsManager(props,state, contextObj);

 
    React.useEffect(() => {
       
        console.log('LY_ListViewTabsContext useEffect props', props);
       // console.log('LY_ListViewTabsContext useEffect data', props.data);

        var doForceRefresh = props.selectedViewId !== state.selectedTab?.listViewId;

        state.listId = props.listId;
        state.workspaceId = props.workspaceId;
      //  forceUpdate();


/*         if (!props.data) {
            manager.getViews()
        }
        else  */
         state.tabs = props.data || [];
   
         //state.pinnedTabs = state.tabs?.filter(t => t.isPinned) || [];

        //this.state.isActionInProgress = false;

        state.selectedTab = state.tabs?.find(t => t.listViewId === props.selectedViewId);


        if(!state.selectedTab && state.tabs?.length > 0)
            state.selectedTab = state.tabs[0];
 
    //  console.log('LY_ListViewTabsContext useEffect  props.selectedViewId', props.selectedViewId);

     //  console.log('LY_ListViewTabsContext useEffect selectedTab', state.selectedTab);

       if(doForceRefresh)
            forceUpdate();
 

      }, [props.listId, props.name,props.selectedViewId,props.workspaceId]);

 


     function getPinnedTabs(): Api.ListViewSm[]{
        return state.tabs.filter(x=>x.isPinned);
    }

    function forceUpdate() {
        setState({ ...state })
    }

    function setTabs(tabs: Api.ListViewSm[]) {
        state.tabs = tabs;
        forceUpdate();
    }

    function onOrderChanged(newTabs: Api.ListViewSm[]) {
        state.tabs = newTabs;
        forceUpdate();
        manager.updateViewSortOrders(newTabs);

        if(props.onTabOrderChanged)
            props.onTabOrderChanged(newTabs);
    }

    function onDeleteTab() {

        if(state.selectedSettingsItem?.listViewId)
             manager.deleteView(state.selectedSettingsItem)
    }

    function updateViews() {
        manager.updateViews(state.tabs)
    }

  
    function createNewView(tabType?:string) {

        if(!tabType)
            tabType = 'table';

        state.tabVariant = tabType;
        state.isNew = true;
 
        if(!state.newItem)
            return;

        var sm = state.newItem;
        sm.listViewId = 0;
        sm.listId = props.listId;
        sm.iconName = tabType;  

        manager.addNewView(sm);


        forceUpdate();
    }


    function updateView() {
 
        var sm = state.updateItem;
        if(!sm)
            return;
        sm.listId = props.listId;

        manager.updateView(sm);

    }

    function reset() {

        state.isActionInProgress = false;
        state.isNew = false;
        state.isTabSettingsOpen = false;
        state.isNewTabModalOpen = false;
       // state.selectedSettingsItem = new Api.ListViewSm();
        state.isAllTabsSettingsOpen = false;
        state.isDeleteModalOpen = false;
        state.errors.clear();
        forceUpdate();
    }

 
    return contextObj;
};

const LY_ListViewTabsContext = createContext<LY_ListViewTabsContextProps | undefined>(undefined);

export const useListViewTabsContext = () => {


    const context = useContext(LY_ListViewTabsContext)


    if (!context) {
        throw new Error(
            "useLY_StatusDropDownContext must be used within a ListTableViewProvider"
        );
    }
    return context;
};


const LY_ListViewTabsContextProvider: React.FC<LY.LY_ListViewTabsModuleProps & { children: React.ReactNode }> = (props) => {


    const contextValue = useListViewTabsState(props);

    function render() {


        return (
            <LY_ListViewTabsContext.Provider value={contextValue}>
                {props.children}
            </LY_ListViewTabsContext.Provider>
        );
    }

    return render();
};
export { LY_ListViewTabsContextProvider, LY_ListViewTabsContext };
