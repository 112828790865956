
import * as Api from 'app-api';
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';
import * as React from 'react';
import * as App from 'AppReferences';

import { Badge, Tooltip } from '@mantine/core';


import "./LY_StaticDropDown_View.css"

interface LY_StaticDropDown_ViewProps extends LY.LY_StaticDropDownProps { }

export const LY_StaticDropDown_View = React.forwardRef<HTMLDivElement, LY_StaticDropDown_ViewProps>((
    props, ref
) => {

    const context = LY.useStaticDropDownContext();

    const state = context.state;
    const column = state?.model?.column;
    //const styleIsFullWidth = state?.model?.column?.styleIsFullWidth || !state.allowMultipleSelection;

    const variant = state?.model?.column?.styleVariant || "filled";
    const badgeFontSize = state?.model?.column?.fontSize || '12px';
    const badgeRadius = state?.model?.column?.styleRadius || 10;

    let showItems = state.selectedItems
    let isFullWidth = context.isFullWidthStyle();
    var isShowingMoreCounter = false;

    if (state.allowMultipleSelection) {

        if (isFullWidth) {
            showItems = state?.selectedItems?.slice(0, 1)
        } else if (state?.selectedItems && state?.selectedItems?.length > 3 && !isFullWidth) {
            showItems = state?.selectedItems?.slice(0, 3);
            isShowingMoreCounter = true;
        }
    }


 



    function getCloseIcon(selectedItem: any, badgeColor: string) {
        if (state.readOnly) return null
       /*  return <MT.CloseButton
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                context.changeSelectedValue(selectedItem);

            }}
            
            style={{padding:0, margin:0}}
            icon={<Icons.IconX 
                width={12} height={12}  color={badgeColor} />}
            
        /> */

        return <Icons.IconX
            className='LY_StaticDropDown_View_Badge_CloseIcon'
            width={12}
            height={12}
            key={'closeDeleteIcon'+props.model?.rowId+selectedItem?.id} 
            style={{color:badgeColor}}
           // color={badgeColor}
            onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                context.changeSelectedValue(selectedItem);

            }}
        />  
    }



    function countRenderer() {


        if (state?.selectedItems && state?.selectedItems?.length > 3 &&
            state.allowMultipleSelection && !context.isFullWidthStyle()) {

            return <Badge
                radius={badgeRadius}
                style={{
                    backgroundColor: "#f1f3f5",
                    color: "#000",
                    fontWeight: 500,
                    width: '50px',
                    fontSize: badgeFontSize,
                    cursor: 'pointer',
                    userSelect: 'none',
                    display: 'flex',
                }}
                variant={variant}
            >
                +{state.selectedItems?.length - 3}
            </Badge>
        }
        return null
    }

    function renderItem(selectedItem: any): React.ReactNode {

        const styleIsFullWidth = context.isFullWidthStyle(selectedItem);
        const badgeColor = selectedItem?.text_color || '#000';

        let icon = null;
        if (state.allowMultipleSelection) {
            icon = getCloseIcon(selectedItem, badgeColor)
        }

      //  var multipleClass = 'LY_StaticDropDownContainerMultiple ';
        
        if (styleIsFullWidth) {

            icon = <Icons.IconCaretDownFilled size={16} className="LY_StaticDropDownDisplayArrow" />;
           // var multipleClass = '';
        }
 

        let badgeBackgroundColor = "transparent";

        badgeBackgroundColor = selectedItem?.color || props?.model?.column?.valueBackgroundColor || "transparent";
        if (variant === 'outline') badgeBackgroundColor = "transparent";

        const border = variant === 'transparent' ? '' : `1px solid ${selectedItem?.color || '#f7e4fd'}!important`;


        var tooltipText = selectedItem?.help_text || column?.helpText || '';
        if(!tooltipText && showItems && showItems.length>1)
            tooltipText = selectedItem?.name;


        return (
            <Tooltip
                withArrow
                arrowSize={10}
                label={tooltipText}
                key={'tooltip'+props.model?.rowId+selectedItem?.id} 
                hidden={!tooltipText} >
                <Badge
                    fullWidth={styleIsFullWidth}
                    key={'badge'+props.model?.rowId+selectedItem?.id} 

                    radius={badgeRadius}
                    className='LY_StaticDropDownDisplayBadgeView'
                    classNames={{
                                label: 'LY_StaticDropDown_View_Badge_Label',
                                root: (!styleIsFullWidth && !props.allowMultiSelect)?'LY_StaticDropDownDisplayBadgeViewTags':''
                            }}
                    
                    styles={{
                        root: {
                            justifyContent: (styleIsFullWidth) ? "space-between" : 'flex-center',
                        
                        }
                    }}
                    style={{
                        width: !styleIsFullWidth ? 'fit-content' : '100%',
                        border,
                        color: badgeColor,
                        backgroundColor: badgeBackgroundColor,
                        cursor: 'pointer',
                        userSelect: 'none',
                        fontWeight: (!selectedItem?.color) ? 'unset' : undefined,
                        fontSize: (styleIsFullWidth) ? 'unset' : '12px',
                        padding: (styleIsFullWidth) ? '5px' : 'inherit',
                        display: 'flex',
                    }}
                    size={badgeFontSize}
                    variant={variant}
                    rightSection={icon}
                     
                >
                    {selectedItem?.name}
                </Badge>
            </Tooltip>
        );
    }

    function render() {

   /*      if (state.model?.column?.name == 'multi_1' && state.model?.rowId == '9ef5ec2a-17a3-402c-a5ac-d367db248c0e') {
            console.log('LY_StaticDropDown_View showItems:', showItems);
            console.log('LY_StaticDropDown_View isFullWidth:', isFullWidth);

        }
 */

  

        return <div
            key={'staticSelected' + state.model?.column?.name + state.model?.rowId}
            onClick={() => context.setPopoverOpened(true)}
            ref={ref}
            className={props.inputContainerClassName}
            style={props.style}
        // className='LY_StaticDropDownDisplay'
        >
            {showItems?.map(x => {
                { return renderItem(x) }
            })}
            {countRenderer()}
        </div >
    }

    return render();
});
