import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons2 from '@mui/icons-material/';


import { AdministrationCompaniesTable } from './AdministrationCompaniesTable';
import { AdministrationCompaniesAddEditModal } from './AdministrationCompaniesAddEditModal';



class AdministrationCompanies extends React.Component<App.AdministrationState, {}> {

  public adminCompaniesService: App.AdminCompaniesService;

  constructor(props: any) {
    super(props);
    this.adminCompaniesService = new App.AdminCompaniesService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationCompanies componentDidMount');

    if (!this.props.hasCompanyDataLoaded) {
      console.log('AdministrationCompanies getAdministrationCompaniesData');

      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_REQUEST, null);

     
    }


  }
 

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationCompanies componentDidUpdate');

  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }


  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;

    vm.setSelectedCompany();

    var item = vm.selectedCompany;

    vm.isCompaniesAddOpen = true;

    console.log("set ownerId to curentuser");
     
    this.forceUpdate();

  }

  
  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');
 
    var state = this.props;
    var vm = state.vm;
  
    var itemId = vm.selectedCompany?.companyId;

    console.log('onDeleteConfirmClick itemId',itemId);

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_COMPANIES_DELETE_REQUEST,itemId);
  
    vm.isActionInProgress=true;

    this.forceUpdate();
 
  }

  public onSearchClick() {
    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.companySearchFilter;
    searchFilter.isSearch=true;
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_REQUEST, searchFilter);

  }

  public render() {

   // console.log('AdministrationCompanies render');

    var state = this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var searchFilter = state.companySearchFilter;


    // console.log('AdministrationCompanies hasCompanyDataLoaded', state.hasCompanyDataLoaded);
    // console.log('AdministrationCompanies isServiceCallPending',this.myAccountService.isServiceCallPending);
    // console.log('AdministrationCompanies vm.isActionInProgress',vm.isActionInProgress);


    var createBtn: any = null;

    if (currentUser.hasRole(App.UserRoleTypeEnum.TeamMember)) {
      createBtn = <MT.Button
                  id="btnCreate"
                  variant="contained"
                  style={{ marginBottom: 10 }}
                  color="primary"

                  onClick={() => this.onCreateClick()}
                >Create</MT.Button>
    }


    return <App.MainSplitPage
      pageTitle="Administration > Companies"
      state={vm}
      hasDataLoaded={state.hasCompanyDataLoaded}
      loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      errorMessage={state.apiErrorMessage}
      isChildPage={true}
     // hasNoPageDesign={true}
    //  mainPageContainerClassName='LY_MainContainerContentFullPageWidth'
     // mainPanelsContentClassName='LY_MainContainerContentFullPageWidth'
    >
      <div style={{   width: '85vw' }} >
      {<LIB.MSearchBar
          filter={searchFilter}
          onSearchClick={() => this.onSearchClick()}
          isSearchInProgress={state.isServiceCallPending}
        />}

        <div style={{ textAlign: 'left', width: '85vw' }} >

          {createBtn}

        </div>

        <AdministrationCompaniesTable state={state} onChange={() => this.forceUpdate()} />



        <AdministrationCompaniesAddEditModal state={state} onChange={() => this.forceUpdate()} />


      </div>



      <LY.ConfirmModal 
    visible={vm.isCompaniesDeleteOpen}
    onCancelClick={ ()=>{vm.isCompaniesDeleteOpen=false;this.forceUpdate();}}
    onConfirmClick={()=>this.onDeleteConfirmClick()}
    title={`Confirm Delete: (${vm.selectedCompany?.companyId}) ${vm.selectedCompany?.companyName}`}
    content={`Are you sure you want to delete "${vm.selectedCompany?.companyName}" company?`}
    confirmButtonLabel="Delete" 
    disabled={vm.isActionInProgress}
    />
 

    </App.MainSplitPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationCompanies as any);

