import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

export class LY_DLA_LabelMakerModuleState extends LIB.BaseVmModel {
 
  public row:any;
  public model:LY.DLAContractLabelModel = new LY.DLAContractLabelModel();
  public workspaceId?:number | undefined;
  public linkedWorkspaceId?:number | undefined;

  public isContainerModalOpen:boolean = false;

  public selectedMainContainerTab:string|null = "label_maker";


  constructor() {
    super();
  }
}
