import "./LY_StaticSelect_Styles.css"

import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { Badge, Box, Select, Slider } from '@mantine/core';
import { buttonStyles, fontSizeValues, radiusValues } from "../../Constants/constants";

import React from 'react';
import { StringHelper } from "_LIB";

export const LY_StaticDropDown_Styles: React.FC = () => {

    const context = LY.useStaticDropDownContext();
    const state = context.state;



    function styleVariantRenderer() {

        return <Select
            classNames={{
                input: 'LY_StaticSelect_Styles_select_input',
            }}
            comboboxProps={{ withinPortal: false }}
            label="Style"
            value={state?.model?.column?.styleVariant ? StringHelper.capitalizeFirstLetter(state?.model?.column?.styleVariant) : 'Default'}
            data={buttonStyles}
            onChange={(value) => {
                if (state?.model?.column?.styleVariant && value) {
                    state.model.column.styleVariant = value.toLowerCase();
                    context.forceUpdate();
                }
            }}
            checkIconPosition="right"
        />
    }



    function styleRadiusRenderer() {


        var column = state?.model?.column;

        return <div>
            <div>Radius</div>
            <Slider
                step={1}
                value={column?.styleRadius ? parseInt(column?.styleRadius) : 0}
                onChange={(size) => {
                    if(column?.styleRadius)
                    column.styleRadius = size.toString();
                    context.forceUpdate();
                }}
                min={0}
                max={10}
                marks={radiusValues}
            />
        </div>
    }
    function fontSizeRenderer() {
        return <div>
            <div>Font Size</div>
            <Slider
                step={1}
                value={state?.model?.column?.fontSize ? parseInt(state.model.column.fontSize) : 12}
                onChange={(size) => {

                    if(state.model?.column)
                        state.model.column.fontSize = size.toString();

                    context.forceUpdate();
                }}
                min={8}
                max={14}
                marks={fontSizeValues}
            />
        </div>
    }



    function buttonRenderer() {
        return <div className="LY_StaticSelect_Styles_Button">
            <Badge
                fullWidth={state?.model?.column?.styleIsFullWidth}
                variant={state?.model?.column?.styleVariant || "default"}
                size={state?.model?.column?.styleSize || 'lg'}
                fz={state?.model?.column?.fontSize || '12px'}
                radius={state?.model?.column?.styleRadius || 0}
                color={state?.previewItem?.text_color || ""}
                bg={state?.previewItem?.color || ""}
            >
                {state?.previewItem?.name || "Button"}
            </Badge>
        </div>
    }
    function checkboxRenderer() {
        return <div className="LY_StaticSelect_Styles_Checkbox">
            <MT.Checkbox
                label={"Full Width"}
                onChange={(e) => {

                    if(state.model?.column)
                        state.model.column.styleIsFullWidth = e.currentTarget.checked;
                    
                    context.forceUpdate();
                }}
                checked={typeof state?.model?.column?.styleIsFullWidth === 'boolean' ? state?.model?.column?.styleIsFullWidth : !state.allowMultipleSelection}
            
           />
                
         
        </div>
    }



    function render() {
        return (
            <Box className="LY_StaticSelect_Styles_Wrapper">
                {styleVariantRenderer()}
                {styleRadiusRenderer()}
                {fontSizeRenderer()}
                {checkboxRenderer()}
                {buttonRenderer()}
            </Box>
        );
    }


    return render();

}

export default LY_StaticDropDown_Styles;
