
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { ComboboxOptionProps } from '@mantine/core';


export class DLAContractLabelSize   {

    public name:string;
    public width:string;
    public height:string;
    public perPageCount:number = 1;

    constructor(name:string='', width:string='', height:string='', perPageCount:number = 1) {
        this.name = name;
        this.width = width;
        this.height = height;
        this.perPageCount = perPageCount;
         
    }



    
    

}