import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_BulkEmailSenderModule_ItemList.css";


interface LY_BulkEmailSenderModule_ItemListProps extends LY.LY_BulkEmailSenderModuleProps {

}

export const LY_BulkEmailSenderModule_ItemList: React.FC<LY_BulkEmailSenderModule_ItemListProps> = (props) => {

  const context = LY.useBulkEmailSenderModuleContext();
  const { state, services } = context;
  var item = state.record;




  function getEmailToItem(item: any, index: number) {

    var nameProp = props.itemsNameProperty || 'name';

    var activeClass = '';
    if (item?.id == state.selectedItem?.id)
      activeClass = 'LY_BulkEmailSenderModule_ItemList_Item_active';

    return <div
    key={'div_item_' + item[ nameProp ] + index}
      onClick={() => {
    
        context.changeSelectedViewEmailToItem(item);
         
      }
      }
    >
      <LY.LY_CheckBox
        name='email_item'
        value={item[ nameProp ]}
        inputContainerClassName={'LY_BulkEmailSenderModule_ItemList_Item ' + activeClass}
        labelClassName='LY_OverflowDots LY_BulkEmailSenderModule_ItemList_Item_label'
        key={'item_' + item[ nameProp ] + index}
        title={item[ nameProp ]}
        labelPlacement='right'
        label={item[ nameProp ]}
        checked={state.emailToItems?.includes(item)}

      />
    </div>
  }

  function render() {

    console.log('LY_BulkEmailSenderModule_ItemList state.selectedItems', state.emailToItems);

    return <div className='LY_Scrollbar LY_BulkEmailSenderModule_ItemList_Container'>

      <div className='LY_BulkEmailSenderModule_ItemList_Item_header'>Email To List:</div>
      {state.emailToItems?.map((item, index) => {

        return getEmailToItem(item, index);

      })
      }

    </div>



  }

  return render();

};
