import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class LinkedListViewService extends App.BaseAppService {



    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
    }


    public getListViews(sm: Api.ListViewGetDataSm, onSuccess: Function, onError: Function | undefined = undefined) {

        this.linkedWorkspaceId = sm.linkedWorkspaceId;
 

        const apiUrl = `${App.AppBase.appApiBaseUrl}/LinkedList/getListViews?listId=${sm.listId}`;

        this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess.bind(this),
            onError: onError?.bind(this),
            method: 'GET'
        }); 

    }


    public async createView(sm: Api.ListViewSm, onSuccess: Function, onError: Function | undefined = undefined) {

 
        console.log("LinkedListViewService.createView sm: ", sm);
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/LinkedListView/createView`;
 
            await this.executeApi({
                apiUrl: apiUrl,
                data: sm,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("ListViewService.createView onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'POST'
            });
        


    }


}
