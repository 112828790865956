
import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

 import { connect } from 'react-redux';
 
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


import {
  Table, TableBody, TableCell, Link, TableContainer, Menu, Paper,
  MenuItem, TableRow, TableHead, Button, Icon, Tooltip, CircularProgress
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";

/* import {
  DataGrid, GridColDef, GridValueGetterParams,
  gridPaginatedVisibleSortedGridRowEntriesSelector
} from '@mui/x-data-grid'; */
import * as XGrid from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import * as Icons from '@tabler/icons-react';

import {
  DataGridPremium, GridColDef, GridValueGetterParams,
  GRID_CHECKBOX_SELECTION_COL_DEF, GridPaginationModel, GridPagination, GridAggregationFunction
} from '@mui/x-data-grid-premium';

import { GridColumnMenuProps, GridColumnMenu, GridColumnMenuContainer, GridColumnMenuItemProps } from '@mui/x-data-grid-premium';

import * as Api from 'app-api';

import '../ListTableView.css';

import { ListTableViewTableNewItem } from './ListTableViewTableNewItem';
import { AddColumnContextMenu } from '../../Columns/AddColumnContextMenu/AddColumnContextMenu';
import { number } from 'prop-types';

import { ListTableViewTableCellRenderer } from '../components/ListTableViewTableCellRenderer';
import { NativeSelect, rem, useCombobox } from '@mantine/core';


interface ListTableViewTableProps {
  state: App.ListState;
  //onForceUpdate?: Function;
  onForceUpdate?: () => void;
  forceUpdateState?: (state?:App.ListState|undefined) => void;

  listTableManager: LY.ListTableManager;
  hideFooterWhenNoPaging?:boolean;
  disableNameClick?: boolean;
  refreshData:()=>void;
  onSelectedItemsChange?: (selectedItems:any[]) => void;

  presetRelColumnName?: string;
  presetRelColumnValue?: string;

  tableHeight?: string;
  minTableHeight?: string;
  //onTableCellChange: (sm:Api.ListUpdateDataSm, e: React.ChangeEventHandler | undefined) => void;
 // onNewItemSave: (sm:Api.ListInsertDataSm, e?: any) => void;

 leftPinnedColumns?: string[];

 onResetCache?:()=>void;
 getRelationshipItemClick?: (model: LY.DataItemModel | undefined, event: React.MouseEvent<HTMLElement> | undefined) => void;

}

//export class ListTableViewTable extends React.PureComponent<ListTableViewTableProps> {
export const ListTableViewTable: React.FC<ListTableViewTableProps> = (props) => {


  const [ rowCountState, setRowCountState ] = React.useState(0);
  const [ paginationModel, setPaginationModel ] = React.useState({ pageSize: 100, page: 0 } as GridPaginationModel);
  //const [ paginationMode, setPaginationMode ] = React.useState('client');

  const [ serverError, setServerError ] = React.useState('');
  const forceUpdate = LIB.useForceUpdate();
  var paginationMode: any = "client";

  const workspace = App.AppBase.currentWorkspace;
 
  // Create a new Subject
const subject = new Subject();


  React.useEffect(() => {

    var rowCount = props.state.recordsCount;
    var vm = props.state.vm;

/*     console.log('ListTableViewTable useEffect rowCount', rowCount);
    console.log('ListTableViewTable useEffect viewID', vm.viewId);
    console.log('ListTableViewTable useEffect forceReadOnly', props.state.forceReadOnly); */
 
    if (rowCount !== undefined) {

      //console.log('ListTableViewTable useEffect setRowCountState', rowCount);

      setRowCountState(rowCount);
      // paginationModel.page = 0;
      // setPaginationModel(paginationModel);
    }
    //else //prevRowCountState

    //console.log('ListTableViewTable useEffect rowCountState', rowCountState);


  }, [ props.state.recordsCount, props.state.records ]);



  function onFilterChange(filter: Api.QueryFilter | undefined = undefined) {

    console.log('ListTableViewTable onFilterChange filter', filter);

    var state = props.state;
 
    var sm = state.listFilterSm;

    sm.filter = filter;
    sm.viewId = state.vm.viewId;
 
    console.log('ListTableViewTable onFilterChange sm', sm);


    props.listTableManager.getListData(sm);

    
  }


  function updateColumnSettings(columnName: string, newWidth: number | undefined = undefined, newColumnIndex: number | undefined = undefined) {

    var vm = props.state.vm;
    var readOnly = props.state.forceReadOnly;

    if (readOnly)
      return;

      var sm = props.state.view?.columns?.find(x=>x.name==columnName);

      if(!sm)
        return;  

        if(newWidth)
          sm.columnWidth = Math.round(newWidth)?.toString();
        
    sm.columnOrder = newColumnIndex;
 
    console.log('updateColumnSettings sm:', sm);
    // console.log('updateColumnSettings sm workspaceId:', props.state.list.workspaceId);

    var service = new App.ListColumnService(sm.workspaceId);

    service.updateColumn(sm,
      //onSuccess
      (result: any) => {
        console.log('updateColumnSettings onSuccess:', result);

        if(sm)
          sm.columnOrder = result.columnOrder;
        App.AppBase.showMessage('Column settings updated!');
       // sm.
        //todo update column 
       // if(props.onForceUpdate)
        //  props.onForceUpdate();
      },
      //onError
      (error: any) => {

        console.error('updateColumnSettings onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
        App.AppBase.showError(errorMessage);

        //vm.isActionInProgress = false;
        //setServerError(errorMessage);
      });




  }

  function onSelectionModelChange(model: any[], details: XGrid.GridCallbackDetails) {

     console.log('onSelectionModelChange model:', model)
     console.log('onSelectionModelChange details:', details)

    var vm = props.state.vm;
    var state = props.state;

    var rows = state.records;

    state.selectedItems = rows.filter(x => model.includes(x.id)); 

   
    console.log('onSelectionModelChange selectedItems:',  state.selectedItems)
 //   forceUpdate();

    if(props.onSelectedItemsChange)
      props.onSelectedItemsChange(state.selectedItems);
  }

  function setSelected(id: any) {
    var state = props.state;
    var vm = state.vm;

  }


    // Subscribe to the Subject with debounceTime and distinctUntilChanged
  subject.pipe(
    debounceTime(2000),
    distinctUntilChanged((prev:any, curr:any) => prev.sm?.column?.name === curr?.sm?.column?.name)
  ).subscribe({
    next: (data:any) => {
      // Make API call here
      props.listTableManager.onTableCellChange(data.sm, data.e);
    },
  }); 

  function onTableCellChange(model: LY.DataItemModel | undefined, e: React.ChangeEventHandler | undefined) {

    console.log('onTableCellChange model:', model);
    //console.log('onTableCellChange e:', e);


    if (model == undefined)
      return;

    //Update value in the dataset
    if (model.row && model.column)
      model.row[ model.column.name ] = model.value;

    //console.log('LY_NameColumnInput getViewMode modelT3: ',model);
    //console.log('LY_NameColumnInput getViewMode modelT3  value: ',model?.value);

    var update = App.RecordManager.getUpdateValue(model);
 

    if (update.sortOrder == undefined)
      update.sortOrder = 1;
    else update.sortOrder++;
 
    var sm = new Api.ListUpdateDataSm();
    sm.updates = [ update ];
    console.log('onTableCellChange sm:', sm);

   props.listTableManager.onTableCellChange(sm, e);
    //subject.next({ sm, e });

    if(props.onResetCache)
        props.onResetCache();
  }

  function onNewItemSave(model: LY.DataItemModel, e?: any) {

    console.log('onNewItemSave model:', model);
    console.log('onNewItemSave e:', e);
    var vm = props.state.vm;
    var newItem = props.state.newItem;

    if (model == undefined)
      return;

    var item = App.RecordManager.getInsertItem(model);

    var sm = new Api.ListInsertDataSm();
    sm.items = [ item ];
    console.log('onNewItemSave sm:', sm);

    props.listTableManager.onNewItemSave(sm,e);
   //todo props.onNewItemSave(sm, e);

   if(props.onResetCache)
      props.onResetCache();

  }

  function getColumnRenderCell(params: XGrid.GridRenderEditCellParams, row: any, col: Api.ListColumn, value: any, rowId: any, viewId?: number, isEditRenderer: boolean = false) {

    if (params.aggregation ) {
/*       console.log("params.aggregation row: ", params.row);
      console.log("params.aggregation rowId: ", rowId);
      console.log("params.aggregation col: ", col); */
 
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      
      var formattedValue = params.formattedValue;
      if(col?.listColumnTypeId == App.ListColumnTypeEnum.Money || col?.clientCalculation?.includes('$')){
        formattedValue =  formatter.format(formattedValue);
      }
      
      return <div className='LY_ListTableFooterSum'>{formattedValue}</div>
     
    }

    if(rowId===undefined){
      return null;
    }

    var state = props.state;

    var model = new LY.DataItemModel(state.workspaceId, rowId, viewId);
    model.column = col;
    model.row = row;
    model.value = value;
    model.originalValue = value;
    model.listId = state.listId;
    model.list = state.list;
    model.isEditMode = isEditRenderer;
    model.workspaceId = workspace?.workspaceId!;
 
    //console.log("getColumnRenderCell state: ", model);
 

    return <ListTableViewTableCellRenderer
      state={state}
      model={model}
      forceReadOnly={state.forceReadOnly}
      disableNameClick={props.disableNameClick}
      // row={row}
      // col={col} value={value} rowId={rowId} 
      // viewId={viewId} 
      forceUpdateState={props.forceUpdateState}
      onChange={onTableCellChange}
      getRelationshipItemClick={props.getRelationshipItemClick}
       />;
    


  }



  function getColumn(col: Api.ListColumn) {

    var vm = props.state.vm;
    var readOnly = props.state.forceReadOnly;

    var isEditable = true;

    if (col.hasAutoGenerate)
      isEditable = false;


    var columnWidth: any = 200;

    if (!LIB.Common.isNullOrEmpty(col.columnWidth))
      columnWidth = col.columnWidth;


    var type = col?.showSum?'number':'text'
    var column: GridColDef = {
      field: col.name,
      type: type,
      headerName: col.displayName,
      width: columnWidth, //col.width,
      sortable: true,
      aggregable: col?.showSum?true:false,
      editable: false,//isEditable,// editable,
      disableReorder: readOnly,

      disableColumnMenu: false,
      resizable: (!readOnly),
      cellClassName: 'LY_ListColumnCell',
      headerClassName: 'LY_ListHeaderCell',
      
      renderCell: (params: XGrid.GridRenderEditCellParams) => {
        var item = params.row;
        var value = item[ col.name ];

        // console.log('getTableColumns renderCell', value);

        let cellRenderer = getColumnRenderCell(params, item, col, value, item.id, vm.viewId, false);
        return cellRenderer;
 
      },
      

    }


    if (col.name == 'name') {
      // column.headerClassName = 'sticky-header';
      column.cellClassName = 'LY_PinnedColumnCell';
    }

    return column;

  }

  function getTableColumns() {

    var vm = props.state.vm; 

    var editable = true;
    var readOnly = false;

    var actionsColumn: GridColDef = {
      field: 'actions',
      headerName: '+',
      width: 50,
      maxWidth: 50,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      pinnable: false,
      resizable: false,
      cellClassName: 'addColumnCell',
      headerClassName: 'addColumnCell',
      renderHeader: (params: XGrid.GridColumnHeaderParams) => {

        return <AddColumnContextMenu 
        state={props.state}
        onForceUpdate={props.onForceUpdate}
         />
      }
      ,
      renderCell: (params: XGrid.GridRenderCellParams) => {
        return null;
      }
    }

    var columns: GridColDef[] = [];

    var viewColumns = props.state?.view?.columns || [];
    var viewSelectColumns = props.state?.view?.filter?.selectColumns;

    for(var col of viewColumns){

     /*  if(col.name=='name' && !LIB.Common.isNullOrEmpty(props.presetNameColumnValue) && col.name==props.presetNameColumnValue)
        continue; */
      if(!LIB.Common.isNullOrEmpty(props.presetRelColumnName) && col.name==props.presetRelColumnName)
        continue;
      else if(viewSelectColumns && !viewSelectColumns.includes(col.name))
        continue;

        var column = getColumn(col);

        if (!col?.isSystemHidden)// || col.name == 'id')
          columns.push(column);
    }


    if (!props.state.forceReadOnly)
      columns.push(actionsColumn);

    //console.log('getTableColumns columns', columns);

    return columns;
  }


  function onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    console.log('onRowClick');
    console.log(rowData);
    console.log(rowMeta);
    var vm = props.state.vm;

  }

  function onSortChange(model: XGrid.GridSortModel, details: XGrid.GridCallbackDetails) {

    //console.log('onSortChange model:', model);
    //console.log('onSortChange paginationMode:', paginationMode);


    var vm = props.state.vm;

    var item = model[ 0 ];

    if (LIB.Common.isNullOrEmpty(item))
      return;

    if (paginationMode == 'client')
      return;

 
    var filter = props.state.view.filter!;
     
    //filter.orderByColumn = item.field;
    //filter.orderByDirection = item.sort?.toString();

    var sortOrder = new Api.SortOrderBy();
    sortOrder.columnName = item.field;
    sortOrder.direction = item.sort?.toString();

    filter.orderByColumns = [ sortOrder];
    filter.pageNum = 1;
    paginationModel.page = 0;
    setPaginationModel(paginationModel);

    console.log('onSortChange filter:', filter);

    onFilterChange(filter);

  }

  function onExportClick() {
    console.log('onExportClick');
    var state = props.state;

    var rows = state.records;

    var includeCols = state.view?.columns?.filter(x => !x.isSystemHidden && (!x.isSystemColumn || x.name == 'name'))
      .map(x => x.name).join(',');

    LIB.DownloadHelper.downloadCSVTable(rows, state.view.name, includeCols);

  }


  function getLocalStyles() {
    //console.log('getLocalStyles isDebug:',App.AppBase.isLocalDev());
    //console.log('getLocalStyles envName:',App.AppBase.envName);

    if (!App.AppBase.isLocalDev())
      return null;

    return (
      <>
        <style>
          {`
            .MuiDataGrid-main > :last-child {
              display: none !important;
            }
          `}
        </style>
      </>
    );
  }

  function changePage(pageNum: number, pageSize: number = 100) {

    let filter = props.state.view.filter!;

    console.log('changePage pageNum', pageNum);
    console.log('changePage pageSize', pageSize);
    console.log('changePage paginationMode', paginationMode);
    console.log('changePage filter.pageNum', filter.pageNum);


    if (filter.pageNum != pageNum && paginationMode == "server") {

      setPaginationModel({
        ...paginationModel,
        page: pageNum - 1,
      });

      filter.pageNum = pageNum;
      filter.limit = pageSize;
      onFilterChange(filter);

    }
    console.log('changePage: model', paginationModel);

  }

  function ColumnSettingsMenuItem(props: GridColumnMenuItemProps) {
    const { myCustomHandler, myCustomValue } = props;

    return (
      <MenuItem onClick={props.onClick}>
        <div>Settings</div>

      </MenuItem>
    );
  }
  function onColumnSettingsClick(currentColumn: string) {

    var state = props.state;
    var columns = state.view.columns;

    var sm =  columns?.find(x=>x.name==currentColumn);

    if(sm==undefined)
      return;

   // sm.workspaceId = props.state.workspaceId;
   // sm.listId = props.state.list.listId!;
  
    let vm = state.vm;
    state.selectedColumn =  sm;
    vm.isColumnSettingsScreenOpen=true;
  
    console.log('onColumnSettingsClick onForceUpdate:', sm);
    if(props.onForceUpdate!=undefined)
        props.onForceUpdate();

  }


  function AddColumnToRightItem(props: GridColumnMenuItemProps) {

    return (
      <MenuItem onClick={props.onClick}>
        <div>Add Column To Right</div>
      </MenuItem>
    );
  }
  function onAddColumnToRightClick(currentColumn: string) {

    var state = props.state;
    var columns = state.view.columns;
    var sm = new Api.ListColumn();

    sm.listColumnTypeId = App.ListColumnTypeEnum.Other;
    sm.workspaceId = props.state.workspaceId;
    sm.listId = props.state.list.listId!;
    sm.afterColumnOrder = columns?.find(x=>x.name==currentColumn)?.columnOrder;


    let vm = state.vm;
    state.newColumn =  sm;
    vm.isAddColumnScreenOpen=true;
   // forceUpdate();
 
    console.log('onAddColumnToRightClick onForceUpdate:', sm);
    if(props.onForceUpdate!=undefined)
        props.onForceUpdate();

  }


  function addColumnSettingsCustomOptions(gridProps: GridColumnMenuProps, slots: any) {
    var state = props.state;
    var readOnly = state.forceReadOnly;


    slots.columnMenuAddColumnToRightItem = (slotProps: GridColumnMenuItemProps) => {

      return <AddColumnToRightItem
        {...slotProps}
        onClick={(e) => {
          gridProps.hideMenu(e);
          onAddColumnToRightClick(slotProps.colDef.field);
        }}
      />
    };

    slots.columnMenuSettingsItem = (slotProps: GridColumnMenuItemProps) => {

      return <ColumnSettingsMenuItem
      {...slotProps}
      onClick={(e) => {
        gridProps.hideMenu(e);
        onColumnSettingsClick(slotProps.colDef.field);
      }}
    />

    /*   return <CustomUserItem
        {...slotProps}
        onClick={(e) => {
          gridProps.hideMenu(e);
          //alert('Settings click');
          console.log('CustomUserItem: Settings click', slotProps);
        }}
      /> */
    }
    return slots;
  }


  function getCustomColumnSettingsMenu(gridProps: GridColumnMenuProps) {
    var state = props.state;
    var readOnly = state.forceReadOnly;


    var slots: any = {
      // Hide `columnMenuColumnsItem`
      columnMenuColumnsItem: null,
      columnMenuFilterItem: null,
      columnMenuGroupingItem: null,
      columnMenuPinningItem: null,
      columnMenuAggregationItem: null,


    }
    if (!readOnly) {
      slots = addColumnSettingsCustomOptions(gridProps, slots);
    }


    return (
      <GridColumnMenu
        {...gridProps}
        slots={slots}
        slotProps={{
          columnMenuAddColumnToRightItem: {
            displayOrder: 14,
          },
          columnMenuSettingsItem: {
            displayOrder: 15
          },

        }}
      />
    );
  }

  function getCustomPagination() {

   // console.log('ListTable getCustomPagination:', rowCountState);


    let filter = props.state.view.filter!;
    
    const totalPages = Math.ceil(rowCountState / 100);


    return () =>
      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        Page:
        <NativeSelect variant="unstyled" style={{ cursor: 'pointer', minWidth: rem(45) }}
          rightSection={<Icons.IconChevronDown style={{ width: rem(18), height: rem(18), marginLeft: 15 }} />}
          value={paginationModel.page}
          onChange={(e: any) => {
            console.log('Page Change value:', e);
            let pageNum = Number(e.currentTarget.value);
            console.log('Page Change pageNum :', pageNum);

            changePage(pageNum + 1, filter.limit);

          }}


        >
          {[ ...Array(totalPages) ].map((_, index) => (
            <option key={index} value={index}>
              {index + 1}
            </option>
          ))}
        </NativeSelect>
        <GridPagination />
      </div>
      ;
  }



  function getAggregationModel(){

    var columns = props.state?.view?.columns || [];

    if(!props.state?.recordsCount || props.state?.recordsCount==0)
      return {};

    var sumAggregation = columns.filter(x => x.showSum)?.reduce((acc: { [key: string]: string }, col: Api.ListColumn) => {
      acc[col.name] = 'showSum';
      return acc;
    }, {});

    return sumAggregation;
  }

  function render() {
    console.log('ListTableViewTable render');
    var state = props.state;
    var vm = state.vm;
    var newItem = state.newItem;
    var readOnly = state.forceReadOnly;

    //console.log('ListTableViewTable render state', state);
    //console.log('ListTableViewTable rowCountState', rowCountState);
    // console.log('ListTableViewTable paginationMode', paginationMode);



    if (!state?.hasDataLoaded){
      return (
        <div style={{marginTop:20}}>
          {/* <MT.Skeleton height={50} circle mb="xl" /> */}
          <MT.Skeleton height={8} width="70%" radius="xl" />
          <MT.Skeleton height={8} mt={10} width="70%" radius="xl" />
          <MT.Skeleton height={8} mt={10} width="50%" radius="xl" />
        </div>
      );
    /*   return <LIB.Loading /> */
    }
      

    //console.log('ListTableViewTable render hasDataLoaded');

    //return <LIB.Loading />;

    var filter = props.state.view.filter!;
    var rows = state.records;
    var rowCount = state.recordsCount;
    var view = state.view;

    //const apiRef = XGrid.useGridApiRef();


    var selectedId = '';
    /*     if (vm?.selectedPage?.pageId)
          selectedId = vm.selectedPage.pageId; */
 
    if (state.recordsCount > 100)
      paginationMode = "server";

    if(!view.pageSize )
    view.pageSize  = 100;
 
    var filter = props.state.view.filter!;
    const totalPages = Math.ceil(rowCountState / view.pageSize );

    var hasPagination = (rowCountState > view.pageSize);

    var pageSizeOptions = [ 10, 50, 100 ];

    if(view.pageSize>100)
      pageSizeOptions.push(view.pageSize);
 
    var hideFooter = props.hideFooterWhenNoPaging;

    if(hasPagination)
        hideFooter = false;

   // console.log('ListTableViewTable render rowCountState',rowCountState);
   // console.log('ListTableViewTable render rowCount',rowCount);

   var tableHeight = "auto";
   if(props.tableHeight)
     tableHeight = props.tableHeight;

    var leftPinnedColumns = [GRID_CHECKBOX_SELECTION_COL_DEF.field];
    if(props.leftPinnedColumns && props.leftPinnedColumns.length>0)
    leftPinnedColumns = [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...props.leftPinnedColumns];

    var columns = props.state?.view?.columns || [];



    //console.log('ListTableViewTable render sumAggregation',sumAggregation);

    return <div  >

      {getLocalStyles()}

      <Box sx={{ width: '100%', height: 'auto' }}>

        <div className='LY_ListTableContainer'
         // style={{ height: 'auto', width: '100%' }}
          style={(hideFooter) ? {borderBottom:0} : undefined}
        >


          <LY.LY_DataGrid_MUI

            getRowId={(row) => row.id}
            rows={rows!}
            columns={getTableColumns()}
            tableHeight={tableHeight}
            rowCount={rowCount}
            //autoPageSize={true}
            pageSizeOptions={pageSizeOptions}
           //  autoHeight={true}
            autoPageSize={false}
            hideFooter={hideFooter}
            rowHeight={35}

            columnHeaderHeight={45}
            paginationMode={paginationMode} // Use server-side pagination for large datasets

            
            checkboxSelection={!state.hideCheckboxSelection}
            onSortModelChange={onSortChange}
            pagination={hasPagination}

            paginationModel={paginationModel}
 
            
            onRowSelectionModelChange={onSelectionModelChange}
            onColumnWidthChange={(params: any) => {
              //console.log('onColumnWidthChange:', params)
              updateColumnSettings(params.colDef?.field, params.width);
            }}
            onColumnOrderChange={(params: any) => {

              console.log('onColumnOrderChange:', params)
              updateColumnSettings(params.column?.field, undefined, params.targetIndex);
            }}
            
            onPaginationModelChange={(model, details) => {
              console.log('Paging: model', model);
              console.log('Paging: details', details);
              console.log('Paging: model.page', model.page);

              let pageNum = model.page + 1;

              changePage(pageNum);

            }}

            slots={{
              columnMenu: getCustomColumnSettingsMenu,
              pagination: getCustomPagination()
            }}

            aggregationFunctions={{
 
              showSum: showSumAggregation,
            }}

            initialState={{
              pinnedColumns: {
                left:leftPinnedColumns
              },
              aggregation: {
                model: getAggregationModel(),
              },
            }} 
          />
       </div>

    <div className='LY_ListTableFooter' >
     {!readOnly  && (
            <ListTableViewTableNewItem 
            state={state} 
            listTableManager={props.listTableManager}
            className={(hideFooter) ? 'addNewItemContainerNoFooter' : ''}
            presetRelColumnName={props.presetRelColumnName}
            presetRelColumnValue={props.presetRelColumnValue}
             />
       )}
 </div>  
 
      </Box>

    </div>



  }

  const showSumAggregation: GridAggregationFunction<string, string | null> =
  {
    apply: (params) => {

      //console.log('showSumAggregation params:', params);
      if (params.values.length === 0) {
        return null;
      }
      
      var sum = params.values?.reduce((acc, value) => {
      //  console.log('showSumAggregation sum:', acc, value);

       // const numericValue = Number(value?.trim());
        var numVal = value?.toString()?.trim()?.replace(/,/g, '');
          if(!numVal)
            return acc;

        let numericValue = parseFloat(numVal);
       // console.log('showSumAggregation sum numericValue:', numericValue);
 
        var result =  isNaN(numericValue) ? acc : acc + numericValue;

       // console.log('showSumAggregation sum numericValue:', numericValue);

        return result;
      }, 0) || undefined;


      var result =  sum?.toString();

       //console.log('showSumAggregation result:', result);
      return result;
     
    },
    label: 'showSum',
   //valueFormatter: (value) =>{return value?.toString()},
 
   // columnTypes: ['number'],
  };

  return render();

};

