import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { EditorInstance } from "novel";
import { SelectorItem } from './Interfaces/LY_SlashEditor_SelectorItem';

export const actionButtons: SelectorItem[] = [
    {
        name: "Bold",
        isActive: (editor: EditorInstance) => (editor ? editor.isActive("bold") : false),
        command: (editor: EditorInstance) => (editor ? editor.chain().focus().toggleBold().run() : false),
        icon: Icons.IconBold,
        tooltip: "Bold",
    },
    {
        name: "Italic",
        isActive: (editor: EditorInstance) => (editor ? editor.isActive("italic") : false),
        command: (editor: EditorInstance) => (editor ? editor.chain().focus().toggleItalic().run() : false),
        icon: Icons.IconItalic,
        tooltip: "Italic",
    },
    {
        name: "Underline",
        isActive: (editor: EditorInstance) => (editor ? editor.isActive("underline") : false),
        command: (editor: EditorInstance) => (editor ? editor.chain().focus().toggleUnderline().run() : false),
        icon: Icons.IconUnderline,
        tooltip: "Underline",
    },
    {
        name: "Code",
        isActive: (editor: EditorInstance) => (editor ? editor.isActive("code") : false),
        command: (editor: EditorInstance) => (editor ? editor.chain().focus().toggleCode().run() : false),
        icon: Icons.IconCode,
        tooltip: "Code",
    },
    {
        name: "To-do List",
        icon: Icons.IconCheckbox,
        command: (editor: EditorInstance) => editor ? editor.chain().focus().clearNodes().toggleTaskList().run() : false,
        isActive: (editor: EditorInstance) => editor ? editor.isActive("taskItem") : false,
    },
    {
        name: "Bullet List",
        icon: Icons.IconList,
        command: (editor: EditorInstance) => editor ? editor.chain().focus().clearNodes().toggleBulletList().run() : false,
        isActive: (editor: EditorInstance) => editor ? editor.isActive("bulletList") : false,
    },
    {
        name: "Numbered List",
        icon: Icons.IconListNumbers,
        command: (editor: EditorInstance) => editor ? editor.chain().focus().clearNodes().toggleOrderedList().run() : false,
        isActive: (editor: EditorInstance) => editor ? editor.isActive("orderedList") : false,
    }
]

export const actionButtonsTextList: SelectorItem[] = [
    {
        name: "Text",
        icon: Icons.IconTextCaption,
        command: (editor) => editor ? editor.chain().focus().clearNodes().run() : false,
        isActive: (editor) =>
            editor ? editor.isActive("paragraph") && !editor.isActive("bulletList") && !editor.isActive("orderedList") : false,
    },
    {
        name: "Heading 1",
        icon: Icons.IconHeading,
        command: (editor) => editor ? editor.chain().focus().clearNodes().toggleHeading({ level: 1 }).run() : false,
        isActive: (editor) => editor ? editor.isActive("heading", { level: 1 }) : false,
    },
    {
        name: "Heading 2",
        icon: Icons.IconHeading,
        command: (editor) => editor ? editor.chain().focus().clearNodes().toggleHeading({ level: 2 }).run() : false,
        isActive: (editor) => editor ? editor.isActive("heading", { level: 2 }) : false,
    },
    {
        name: "Heading 3",
        icon: Icons.IconHeading,
        command: (editor) => editor ? editor.chain().focus().clearNodes().toggleHeading({ level: 3 }).run() : false,
        isActive: (editor) => editor ? editor.isActive("heading", { level: 3 }) : false,
    },
]

export const actionButtonsAdditional: SelectorItem[] = [
    {
        name: "Strike",
        isActive: (editor) => (editor ? editor.isActive("strike") : false),
        command: (editor) => (editor ? editor.chain().focus().clearNodes().toggleStrike().run() : false),
        icon: Icons.IconStrikethrough,
        tooltip: "Strike",
    },
    {
        name: "Quote",
        icon: Icons.IconQuotes,
        command: (editor) => editor ? editor.chain().focus().clearNodes().toggleBlockquote().run() : false,
        isActive: (editor) => editor ? editor.isActive("blockquote") : false,
    }
]